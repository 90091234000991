import React, { ReactElement } from "react";

type Props = {
  size: number;
  axis?: "vertical" | "horizontal";
  style?: React.CSSProperties;
};

const Spacer = ({
  size,
  axis = "vertical",
  style = {},
}: Props): ReactElement => {
  const width = axis === "vertical" ? 1 : size;
  const height = axis === "horizontal" ? 1 : size;
  return (
    <span
      id={"spacer"}
      style={{
        display: "block",
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
    />
  );
};
export default Spacer;
