import React, { FC } from "react";
import { StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

const BetaTag: FC = () => {
  return (
    <AutoLayout
      styleDeclaration={styles.betaPill}
      alignmentHorizontal="center"
      alignmentVertical="center"
    >
      BETA
    </AutoLayout>
  );
};

export default BetaTag;

const styles = StyleSheet.create({
  betaPill: {
    color: deprecatedTones.white,
    backgroundColor: deprecatedTones.purple6,
    borderRadius: 4,
    height: 16,
    width: 34,
    fontSize: 10,
    fontWeight: 500,
  },
});
