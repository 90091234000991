import { useEffect, useCallback } from "react";

type Key =
  | "Enter"
  | "ArrowDown"
  | "ArrowUp"
  | "ArrowRight"
  | "ArrowLeft"
  | "Escape"
  | "Control"
  | "Meta"
  | "Backspace"
  | "z";

export type KeyStrokeConfig = Partial<
  Record<Key, (event: KeyboardEvent) => void>
>;

export const useKeystrokes = (handlers?: KeyStrokeConfig): void => {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      if (!handlers) {
        return;
      }
      const handler = handlers[event.key as Key];
      if (handler !== undefined) {
        handler(event);
      }
    },
    [handlers],
  );

  useEffect(() => {
    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [handler]);
};
