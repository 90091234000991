import React from "react";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";

export type Toast = {
  id: string;
  iconType: DeprecatedIconType;
  message: string;
  success?: boolean;
  lifetimeMS?: number;
  expirationDisabled?: boolean;
};

export type ErrorToast = {
  iconType: DeprecatedIconType;
  message: string;
  success: boolean;
};

export type AddToast = Omit<Toast, "id">;
export type ToastErrorInfo = Partial<ErrorToast> & Record<string, unknown>;

export interface ToastContextState {
  toasts: Toast[];
  addToast: (toast: AddToast) => string;
  addToasts: (toasts: AddToast[]) => string[];
  addErrorToast: (errorInfo: ToastErrorInfo) => string;
  removeToast: (id: string) => void;
}

const ToastContext = React.createContext<ToastContextState>({
  toasts: [],
  addToast: () => "",
  addToasts: () => [],
  addErrorToast: () => "",
  removeToast: () => {},
});

export default ToastContext;
