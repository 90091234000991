import {
  DeprecatedColor,
  DeprecateTone,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import { CSSProperties, FC, useEffect, useState } from "react";

type Props = {
  size?: number;
  color?: DeprecateTone | DeprecatedColor;
  visibilityDelayMs?: number;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
  centered?: boolean;
};

const Spinner: FC<Props> = ({
  size = 40,
  color = deprecatedColors.onPrimaryContainer,
  visibilityDelayMs = 0,
  styleDeclaration,
  style,
  centered = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, visibilityDelayMs);
    return () => clearTimeout(timer);
  }, [visibilityDelayMs]);
  if (!isVisible) {
    return null;
  }

  const spinner = (
    <div
      style={{ width: `${size}px`, height: `${size}px`, ...style }}
      className={css(styleDeclaration)}
    >
      <svg className={css(styles.spinner)} viewBox="0 0 50 50">
        <circle
          stroke={color}
          className={css(styles.path)}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </div>
  );
  if (centered) {
    return (
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        alignmentHorizontal="center"
        alignmentVertical="center"
      >
        {spinner}
      </AutoLayout>
    );
  }
  return spinner;
};

const rotateKeyframes = {
  "100%": {
    transform: "rotate(360deg)",
  },
};

const dashKeyframes = {
  "0%": {
    strokeDasharray: "1, 150",
    strokeDashoffset: 0,
  },
  "50%": {
    strokeDasharray: "90, 150",
    strokeDashoffset: -35,
  },
  "100%": {
    strokeDasharray: "90, 150",
    strokeDashoffset: -124,
  },
};

const styles = StyleSheet.create({
  spinner: {
    animationName: rotateKeyframes,
    animationDuration: "2s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    zIndex: 2,
  },
  path: {
    strokeLinecap: "round",
    animationName: dashKeyframes,
    animationDuration: "1.5s",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
    zIndex: 2,
  },
});

export default Spinner;
