import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrandColorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BrandColorQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, brandColor?: string | null } | null } } | null };


export const BrandColorDocument = gql`
    query BrandColor {
  Me {
    user {
      id
      org {
        id
        brandColor
      }
    }
  }
}
    `;

/**
 * __useBrandColorQuery__
 *
 * To run a query within a React component, call `useBrandColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandColorQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandColorQuery(baseOptions?: Apollo.QueryHookOptions<BrandColorQuery, BrandColorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandColorQuery, BrandColorQueryVariables>(BrandColorDocument, options);
      }
export function useBrandColorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandColorQuery, BrandColorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandColorQuery, BrandColorQueryVariables>(BrandColorDocument, options);
        }
export type BrandColorQueryHookResult = ReturnType<typeof useBrandColorQuery>;
export type BrandColorLazyQueryHookResult = ReturnType<typeof useBrandColorLazyQuery>;
export type BrandColorQueryResult = Apollo.QueryResult<BrandColorQuery, BrandColorQueryVariables>;