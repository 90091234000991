import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type AiOrgContextFragment = { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean };

export type BrandDetailsFragment = { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null };

export const AiOrgContextFragmentDoc = gql`
    fragment AIOrgContext on AIOrgContext {
  id
  foundingStory
  missionStatement
  businessType
  sells
  isApproved
}
    `;
export const BrandDetailsFragmentDoc = gql`
    fragment BrandDetails on BrandDetails {
  id
  logoUrls {
    id
    original
    medium
    thumb
  }
  aiBrandContext {
    ...AIOrgContext
  }
  brandColor
}
    ${AiOrgContextFragmentDoc}`;