import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { BrandDetailsFragmentDoc, AiOrgContextFragmentDoc } from '../../hooks/useBranding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeFragment = { __typename?: 'Me', isStaff: boolean, user: { __typename?: 'Employee', id: number, email?: string | null, phoneNumber?: string | null, name: string, firstName: string, userType: Types.UserType, language: Language, org?: { __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, isDemoOrg: boolean, languagesInUse: Array<Language>, isComplianceOnly?: boolean | null, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, clockInConfirmationSettings: { __typename?: 'ClockInConfirmationSettings', id: number, lessons: boolean, files: boolean, messages: boolean } } | null, brands: Array<{ __typename?: 'LocationGroup', id: string, name: string, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null }> } };

export type MeOrgFragment = { __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, isDemoOrg: boolean, languagesInUse: Array<Language>, isComplianceOnly?: boolean | null, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, clockInConfirmationSettings: { __typename?: 'ClockInConfirmationSettings', id: number, lessons: boolean, files: boolean, messages: boolean } };

export type InitQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InitQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', isStaff: boolean, user: { __typename?: 'Employee', id: number, email?: string | null, phoneNumber?: string | null, name: string, firstName: string, userType: Types.UserType, language: Language, org?: { __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, isDemoOrg: boolean, languagesInUse: Array<Language>, isComplianceOnly?: boolean | null, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null, clockInConfirmationSettings: { __typename?: 'ClockInConfirmationSettings', id: number, lessons: boolean, files: boolean, messages: boolean } } | null, brands: Array<{ __typename?: 'LocationGroup', id: string, name: string, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null }> } } | null };

export type TrackEventMutationVariables = Types.Exact<{
  label: Types.Scalars['String'];
  payload?: Types.InputMaybe<Types.Scalars['JSON']>;
}>;


export type TrackEventMutation = { __typename?: 'Mutation', trackEvent?: boolean | null };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'BaseMutationResult', success: boolean } };

export const MeOrgFragmentDoc = gql`
    fragment MeOrg on Org {
  id
  name
  deactivatedAt
  isDemoOrg
  languagesInUse
  isComplianceOnly
  brandDetails {
    ...BrandDetails
  }
  clockInConfirmationSettings {
    id
    lessons
    files
    messages
  }
}
    ${BrandDetailsFragmentDoc}`;
export const MeFragmentDoc = gql`
    fragment Me on Me {
  user {
    id
    email
    phoneNumber
    name
    firstName
    userType
    language
    org {
      ...MeOrg
    }
    brands {
      id
      name
      brandDetails {
        ...BrandDetails
      }
    }
  }
  isStaff
}
    ${MeOrgFragmentDoc}
${BrandDetailsFragmentDoc}`;
export const InitDocument = gql`
    query Init {
  Me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useInitQuery__
 *
 * To run a query within a React component, call `useInitQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitQuery(baseOptions?: Apollo.QueryHookOptions<InitQuery, InitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitQuery, InitQueryVariables>(InitDocument, options);
      }
export function useInitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitQuery, InitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitQuery, InitQueryVariables>(InitDocument, options);
        }
export type InitQueryHookResult = ReturnType<typeof useInitQuery>;
export type InitLazyQueryHookResult = ReturnType<typeof useInitLazyQuery>;
export type InitQueryResult = Apollo.QueryResult<InitQuery, InitQueryVariables>;
export const TrackEventDocument = gql`
    mutation TrackEvent($label: String!, $payload: JSON) {
  trackEvent(app: "dashboard", label: $label, payload: $payload)
}
    `;
export type TrackEventMutationFn = Apollo.MutationFunction<TrackEventMutation, TrackEventMutationVariables>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      label: // value for 'label'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTrackEventMutation(baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(TrackEventDocument, options);
      }
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>;
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<TrackEventMutation, TrackEventMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;