export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AssignmentStatus: AssignmentStatus;
  AttemptStatus: AttemptStatus;
  Date: string;
  DateTime: string;
  InvoiceStatus: InvoiceStatus;
  JSON: { [key: string]: any };
  Language: Language;
  Time: string;
  USState: USState;
  UUID: string;
  Upload: File;
};

export type AiCourseInput = {
  brandDetailsId?: InputMaybe<Scalars['UUID']>;
  interactivity: Scalars['Int'];
  text?: InputMaybe<Scalars['String']>;
  textList?: InputMaybe<Array<Scalars['String']>>;
  toneFormality: Scalars['Int'];
  toneSeriousness: Scalars['Int'];
};

export type AiCourseRequest = {
  __typename?: 'AICourseRequest';
  course: Course;
  id: Scalars['UUID'];
};

export type AiCourseRequestMedia = {
  __typename?: 'AICourseRequestMedia';
  aiCourseRequest: AiCourseRequest;
  id: Scalars['UUID'];
  media: Media;
};

export type AiEditPromptRequest = {
  __typename?: 'AIEditPromptRequest';
  aiResponseOptions?: Maybe<Array<AiResponseOption>>;
  generationCompletedAt?: Maybe<Scalars['DateTime']>;
  generationErroredAt?: Maybe<Scalars['DateTime']>;
  generationStartedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  mediaSuggestions?: Maybe<Array<MediaSuggestion>>;
  prompt: Prompt;
  requestType: EditPromptWithAiType;
  response?: Maybe<Scalars['String']>;
};

export type AiGenerateCourseFromOutlineRequest = {
  __typename?: 'AIGenerateCourseFromOutlineRequest';
  aiRequest: AiRequest;
  course?: Maybe<Course>;
  courseOutline: CourseOutline;
  generationCompletedAt?: Maybe<Scalars['DateTime']>;
  generationErroredAt?: Maybe<Scalars['DateTime']>;
  generationStartedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  response?: Maybe<Scalars['String']>;
};

export type AiGenerateModuleRequest = {
  __typename?: 'AIGenerateModuleRequest';
  generationCompletedAt?: Maybe<Scalars['DateTime']>;
  generationErroredAt?: Maybe<Scalars['DateTime']>;
  generationStartedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
};

export type AiGenerationFeedback = {
  __typename?: 'AIGenerationFeedback';
  id?: Maybe<Scalars['UUID']>;
  libraryItemId?: Maybe<Scalars['UUID']>;
  selection?: Maybe<FeedbackSelection>;
  text?: Maybe<Scalars['String']>;
};

export type AiImageRequest = {
  __typename?: 'AIImageRequest';
  attemptNumber: Scalars['Int'];
  id: Scalars['UUID'];
  prompt: Prompt;
};

export type AiLessonGeneration = {
  __typename?: 'AILessonGeneration';
  id: Scalars['UUID'];
  messagePromptsAttempted: Scalars['Boolean'];
  messagePromptsSuccess: Scalars['Boolean'];
  questionPromptsAttempted: Scalars['Boolean'];
  questionPromptsSuccess: Scalars['Boolean'];
  titleAttempted: Scalars['Boolean'];
};

export type AiModuleInput = {
  brandDetailsId?: InputMaybe<Scalars['UUID']>;
  mediaUrls?: InputMaybe<Array<Scalars['String']>>;
  textList: Array<Scalars['String']>;
  trainingResourceUuids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type AiNewPromptRequest = {
  __typename?: 'AINewPromptRequest';
  aiCourseRequest?: Maybe<AiCourseRequest>;
  attemptNumber: Scalars['Int'];
  id: Scalars['UUID'];
  index: Scalars['Float'];
  lesson?: Maybe<Lesson>;
  prompt: Prompt;
  text: Scalars['String'];
};

export type AiOrgContext = {
  __typename?: 'AIOrgContext';
  businessType: Scalars['String'];
  foundingStory: Scalars['String'];
  id: Scalars['UUID'];
  isApproved: Scalars['Boolean'];
  missionStatement: Scalars['String'];
  sells: Scalars['String'];
};

export type AiOrgContextMutationResult = MutationResult & {
  __typename?: 'AIOrgContextMutationResult';
  aiOrgContext?: Maybe<AiOrgContext>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type AiPrompt = {
  __typename?: 'AIPrompt';
  correctFreeResponseAnswer?: Maybe<AiTranslationSet>;
  correctTfAnswer?: Maybe<Scalars['Boolean']>;
  index: Scalars['Int'];
  responseOptions: Array<AiQuestionResponseOption>;
  selectAllThatApply: Scalars['Boolean'];
  text: AiTranslationSet;
  type: PromptType;
};

export type AiQuestionResponseOption = {
  __typename?: 'AIQuestionResponseOption';
  answer: AiTranslationSet;
  index: Scalars['Int'];
  isCorrect: Scalars['Boolean'];
};

export type AiRequest = {
  __typename?: 'AIRequest';
  accepted: Scalars['Boolean'];
  aiCourseRequest?: Maybe<AiCourseRequest>;
  aiEditPromptRequest?: Maybe<AiEditPromptRequest>;
  aiGenerateCourseFromOutlineRequest?: Maybe<AiGenerateCourseFromOutlineRequest>;
  aiGenerateModuleRequest?: Maybe<AiGenerateModuleRequest>;
  aiImageRequest?: Maybe<AiImageRequest>;
  aiNewPromptRequest?: Maybe<AiNewPromptRequest>;
  id: Scalars['UUID'];
  rejected: Scalars['Boolean'];
  requestText?: Maybe<Scalars['String']>;
  requestedBy: Employee;
};

export type AiRequestMutationResult = MutationResult & {
  __typename?: 'AIRequestMutationResult';
  aiRequest?: Maybe<AiRequest>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type AiResponseOption = {
  __typename?: 'AIResponseOption';
  aiEditPromptRequest: AiEditPromptRequest;
  answer: TranslationSet;
  id: Scalars['UUID'];
  index: Scalars['Float'];
  isCorrect: Scalars['Boolean'];
};

export type AiTranslationSet = {
  __typename?: 'AITranslationSet';
  en: Scalars['String'];
};

export type AccessContentMutationResult = MutationResult & {
  __typename?: 'AccessContentMutationResult';
  courseInstance?: Maybe<CourseInstance>;
  error?: Maybe<Error>;
  pathInstance?: Maybe<PathInstance>;
  success: Scalars['Boolean'];
  trainingResourceInstance?: Maybe<TrainingResourceInstance>;
};

export type AccessContentViaQrCodeInput = {
  libraryItemId: Scalars['UUID'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type AccessSource = {
  __typename?: 'AccessSource';
  accessType: AccessType;
  assignedByStr: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  parentId?: Maybe<Scalars['UUID']>;
  sourceType: AccessSourceType;
};

export enum AccessSourceType {
  Automation = 'AUTOMATION',
  AutoReassignAllertrainPathWhenExamPart_2Failed = 'AUTO_REASSIGN_ALLERTRAIN_PATH_WHEN_EXAM_PART_2_FAILED',
  AutoReassignPathOnCourseFailure = 'AUTO_REASSIGN_PATH_ON_COURSE_FAILURE',
  Backfill = 'BACKFILL',
  CoursePromptMedia = 'COURSE_PROMPT_MEDIA',
  Manual = 'MANUAL',
  OnCompletionAssignmentRule = 'ON_COMPLETION_ASSIGNMENT_RULE',
  PathContentMembershipInstance = 'PATH_CONTENT_MEMBERSHIP_INSTANCE',
  QrCode = 'QR_CODE'
}

export enum AccessType {
  Assignment = 'ASSIGNMENT',
  LibraryAccess = 'LIBRARY_ACCESS'
}

export type AccessTypeListInput = {
  value: Array<AccessType>;
};

export enum ActionItemPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type ActionPermissionSet = {
  __typename?: 'ActionPermissionSet';
  assignContent?: Maybe<Scalars['Boolean']>;
  changeOrgSettings?: Maybe<Scalars['Boolean']>;
  createContent?: Maybe<Scalars['Boolean']>;
  deactivateTrainees?: Maybe<Scalars['Boolean']>;
  editTrainees?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  inviteOthers?: Maybe<Scalars['Boolean']>;
  manageBilling?: Maybe<Scalars['Boolean']>;
  manageLibrary?: Maybe<Scalars['Boolean']>;
  manageOrgIntegrations?: Maybe<Scalars['Boolean']>;
  managePremiumContent?: Maybe<Scalars['Boolean']>;
  markAsComplete?: Maybe<Scalars['Boolean']>;
  promoteTrainees?: Maybe<Scalars['Boolean']>;
  sendMessages?: Maybe<Scalars['Boolean']>;
  unassignContent?: Maybe<Scalars['Boolean']>;
  updateAutomations?: Maybe<Scalars['Boolean']>;
  verifySkills?: Maybe<Scalars['Boolean']>;
  viewReporting?: Maybe<Scalars['Boolean']>;
};

export type ActionPermissionSetInput = {
  assignContent?: InputMaybe<OptionalBooleanValue>;
  changeOrgSettings?: InputMaybe<OptionalBooleanValue>;
  createContent?: InputMaybe<OptionalBooleanValue>;
  deactivateTrainees?: InputMaybe<OptionalBooleanValue>;
  editTrainees?: InputMaybe<OptionalBooleanValue>;
  inviteOthers?: InputMaybe<OptionalBooleanValue>;
  manageBilling?: InputMaybe<OptionalBooleanValue>;
  manageLibrary?: InputMaybe<OptionalBooleanValue>;
  manageOrgIntegrations?: InputMaybe<OptionalBooleanValue>;
  managePremiumContent?: InputMaybe<OptionalBooleanValue>;
  markAsComplete?: InputMaybe<OptionalBooleanValue>;
  promoteTrainees?: InputMaybe<OptionalBooleanValue>;
  sendMessages?: InputMaybe<OptionalBooleanValue>;
  unassignContent?: InputMaybe<OptionalBooleanValue>;
  updateAutomations?: InputMaybe<OptionalBooleanValue>;
  verifySkills?: InputMaybe<OptionalBooleanValue>;
  viewReporting?: InputMaybe<OptionalBooleanValue>;
};

export type ActiveClient = {
  __typename?: 'ActiveClient';
  clientId: Scalars['UUID'];
  courseVersionId?: Maybe<Scalars['Int']>;
  pathContentMembershipId?: Maybe<Scalars['UUID']>;
  pathVersionId?: Maybe<Scalars['UUID']>;
  promptUuid?: Maybe<Scalars['UUID']>;
  skillVersionId?: Maybe<Scalars['UUID']>;
  user: Employee;
  verificationStepId?: Maybe<Scalars['UUID']>;
};

export type ActiveClientMutationResult = MutationResult & {
  __typename?: 'ActiveClientMutationResult';
  activeClient?: Maybe<ActiveClient>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type ActiveClientsInput = {
  courseVersionId?: InputMaybe<Scalars['Int']>;
  pathVersionId?: InputMaybe<Scalars['UUID']>;
  skillVersionId?: InputMaybe<Scalars['UUID']>;
};

export type AddContentToLibraryInput = {
  catalogCourseIds: Array<Scalars['Int']>;
  catalogPathIds: Array<Scalars['UUID']>;
};

export enum AddCoursePosition {
  Last = 'LAST',
  Next = 'NEXT',
  Now = 'NOW'
}

export type AddLessonActionInput = {
  firstPromptUuid?: InputMaybe<Scalars['UUID']>;
  lesson?: InputMaybe<LessonObjectInput>;
  lessonUuid?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type AddMcOptionActionInput = {
  promptUuid: Scalars['UUID'];
};

export type AddPathContentMembershipActionInput = {
  aiRequestUuid?: InputMaybe<Scalars['UUID']>;
  courseId?: InputMaybe<Scalars['Int']>;
  fractionPosition: Scalars['Float'];
  pathContentMembershipUuid: Scalars['UUID'];
  sectionBreak?: InputMaybe<SectionBreakInput>;
  skillId?: InputMaybe<Scalars['Int']>;
  trainingResourceId?: InputMaybe<Scalars['UUID']>;
};

export type AddPromptActionInput = {
  lessonUuid: Scalars['UUID'];
  position: Scalars['Int'];
  prompt: PromptObjectInput;
  promptUuid?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<PromptType>;
};

export type AddResponseOptionActionInput = {
  position?: InputMaybe<Scalars['Int']>;
  promptUuid: Scalars['UUID'];
  responseOption?: InputMaybe<ResponseOptionObjectInput>;
  responseOptionId?: InputMaybe<Scalars['UUID']>;
};

export type AddStepActionInput = {
  position: Scalars['Int'];
  step: VerificationStepObjectInput;
};

export type AddUsersToLocationsInput = {
  courses?: InputMaybe<Array<AssignCourseInput>>;
  locationIds: Array<Scalars['Int']>;
  userIds: Array<Scalars['Int']>;
};

export type AddUsersToRolesInput = {
  courses?: InputMaybe<Array<AssignCourseInput>>;
  roleIds: Array<Scalars['Int']>;
  userIds: Array<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['UUID'];
  lat?: Maybe<Scalars['Float']>;
  lineOne: Scalars['String'];
  lineTwo?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['Float']>;
  postalCode: Scalars['String'];
  usState?: Maybe<Scalars['USState']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  lat?: InputMaybe<Scalars['Float']>;
  lineOne: Scalars['String'];
  lineTwo?: InputMaybe<Scalars['String']>;
  lng?: InputMaybe<Scalars['Float']>;
  postalCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
};

export type AdminEmployeesInput = {
  locationIds?: InputMaybe<IntArrayInput>;
  search?: InputMaybe<StringValue>;
};

export type AdminLibraryFiltersInput = {
  excludeDrafts?: InputMaybe<OptionalBooleanValue>;
  ids?: InputMaybe<UuidArrayInput>;
  isArchived?: InputMaybe<OptionalBooleanValue>;
  isPublished?: InputMaybe<OptionalBooleanValue>;
  itemType?: InputMaybe<LibraryItemTypeInput>;
  itemTypes?: InputMaybe<LibraryItemTypeListInput>;
  lastEditedByUserIds?: InputMaybe<IntArrayInput>;
  tagIds?: InputMaybe<UuidArrayInput>;
};

export type AdminLibraryInput = {
  filters?: InputMaybe<AdminLibraryFiltersInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type AdoptionDay = {
  __typename?: 'AdoptionDay';
  count: Scalars['Int'];
  day: Scalars['Date'];
  expectedUsers?: Maybe<Scalars['Int']>;
};

export type ApplyBuilderActionsMutationResult = MutationResult & {
  __typename?: 'ApplyBuilderActionsMutationResult';
  appliedBuilderActions?: Maybe<Array<BuilderAction>>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type AssignCourseInput = {
  courseId: Scalars['Int'];
  dueDate?: InputMaybe<Scalars['Date']>;
};

export type AssignLibraryItemsInput = {
  libraryItemIds: Array<Scalars['UUID']>;
  userSelection: UserSelectionInput;
};

export enum AssignedUserType {
  All = 'ALL',
  NonSupervisors = 'NON_SUPERVISORS',
  Supervisors = 'SUPERVISORS'
}

export type Assignment = {
  __typename?: 'Assignment';
  accessSources: Array<AccessSource>;
  assignedByList?: Maybe<Array<Scalars['String']>>;
  attemptsCount: Scalars['Int'];
  /** @deprecated Field no longer supported */
  course?: Maybe<Course>;
  /** @deprecated Field no longer supported */
  courseId?: Maybe<Scalars['Int']>;
  courseInstances: Array<CourseInstance>;
  createdAt: Scalars['DateTime'];
  currentCourseInstance?: Maybe<CourseInstance>;
  currentInstanceAccuracy?: Maybe<Scalars['Float']>;
  currentInstanceCompletedAt?: Maybe<Scalars['DateTime']>;
  currentInstanceFirstAssignedAt?: Maybe<Scalars['DateTime']>;
  currentInstanceStartedAt?: Maybe<Scalars['DateTime']>;
  currentPathInstance?: Maybe<PathInstance>;
  currentResourceInstance?: Maybe<TrainingResourceInstance>;
  currentSkillInstance?: Maybe<SkillInstance>;
  firstAssignedAt?: Maybe<Scalars['DateTime']>;
  firstAssignedByStr?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isCurrentInstanceAssignedThroughModule: Scalars['Boolean'];
  isOrHasBeenAssigned: Scalars['Boolean'];
  /** @deprecated Use currentInstanceFirstAssignedAt instead */
  lastAssignedAt?: Maybe<Scalars['DateTime']>;
  libraryItem: LibraryItem;
  /** @deprecated Field no longer supported */
  path?: Maybe<Path>;
  /** @deprecated Field no longer supported */
  pathId?: Maybe<Scalars['Int']>;
  pathInstances: Array<PathInstance>;
  /** @deprecated Field no longer supported */
  resource?: Maybe<TrainingResource>;
  /** @deprecated Field no longer supported */
  resourceId?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  skill?: Maybe<Skill>;
  /** @deprecated Field no longer supported */
  skillId?: Maybe<Scalars['Int']>;
  status: Scalars['AssignmentStatus'];
  user: Employee;
  userId: Scalars['Int'];
};

export type AssignmentCompletionDay = {
  __typename?: 'AssignmentCompletionDay';
  axisLabel: Scalars['String'];
  completedAssignments: Scalars['Int'];
  day: Scalars['Date'];
  libraryItemId?: Maybe<Scalars['UUID']>;
  tooltipLabel: Scalars['String'];
  totalAssignments: Scalars['Int'];
};

export type AssignmentCompletionInput = {
  libraryItemId?: InputMaybe<Scalars['UUID']>;
  since?: InputMaybe<DateTimeValue>;
};

export enum AssignmentContentType {
  Course = 'COURSE',
  Path = 'PATH',
  Resource = 'RESOURCE',
  Skill = 'SKILL'
}

export type AssignmentContentTypeListInput = {
  value: Array<AssignmentContentType>;
};

export type AssignmentDiff = {
  __typename?: 'AssignmentDiff';
  added: Array<Employee>;
  removed: Array<Employee>;
};

export type AssignmentInput = {
  userSelection: UserSelectionInput;
};

export type AssignmentStatusArrayInput = {
  value: Array<Scalars['AssignmentStatus']>;
};

export type AssignmentStatusCount = {
  __typename?: 'AssignmentStatusCount';
  count: Scalars['Int'];
  status: Scalars['AssignmentStatus'];
};

export type Assignments = PaginatedList & {
  __typename?: 'Assignments';
  objects: Array<Assignment>;
  totalCount: Scalars['Int'];
};

export type AssignmentsFiltersInput = {
  accessTypes?: InputMaybe<AccessTypeListInput>;
  archivedOnly?: InputMaybe<BooleanValue>;
  complianceTypes?: InputMaybe<Array<ComplianceType>>;
  contentTypes?: InputMaybe<AssignmentContentTypeListInput>;
  courseId?: InputMaybe<IntValue>;
  courseIds?: InputMaybe<IntArrayInput>;
  currentCourseInstanceFilters?: InputMaybe<CurrentCourseInstanceFiltersInput>;
  currentPathInstanceFilters?: InputMaybe<CurrentPathInstanceFiltersInput>;
  currentSkillInstanceFilters?: InputMaybe<CurrentSkillInstanceFiltersInput>;
  excludeAccessTypes?: InputMaybe<AccessTypeListInput>;
  excludeDrafts?: InputMaybe<BooleanValue>;
  excludeUserIds?: InputMaybe<IntArrayInput>;
  includeArchived?: InputMaybe<BooleanValue>;
  isTopLevel?: InputMaybe<BooleanValue>;
  libraryItemIds?: InputMaybe<UuidArrayInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  pathId?: InputMaybe<IntValue>;
  pathIds?: InputMaybe<IntArrayInput>;
  peopleFilters?: InputMaybe<GetPeopleInput>;
  readyForTraining?: InputMaybe<BooleanValue>;
  resourceIds?: InputMaybe<StringArrayInput>;
  roleGroupIds?: InputMaybe<UuidArrayInput>;
  roleIds?: InputMaybe<IntArrayInput>;
  shpAssignedUserTypes?: InputMaybe<Array<AssignedUserType>>;
  shpUsStates?: InputMaybe<Array<InputMaybe<Scalars['USState']>>>;
  skillIds?: InputMaybe<IntArrayInput>;
  statuses?: InputMaybe<AssignmentStatusArrayInput>;
  tagIds?: InputMaybe<UuidArrayInput>;
  useDirectReports?: InputMaybe<BooleanValue>;
  userId?: InputMaybe<IntValue>;
  userIds?: InputMaybe<IntArrayInput>;
};

export type AssignmentsInput = {
  filters: AssignmentsFiltersInput;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type AssignmentsMutationResult = MutationResult & {
  __typename?: 'AssignmentsMutationResult';
  assignments?: Maybe<Array<Assignment>>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type Automation = {
  __typename?: 'Automation';
  autoReassign: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  endDatetime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  libraryItem: LibraryItem;
  newUsersOnly: Scalars['Boolean'];
  startDatetime?: Maybe<Scalars['DateTime']>;
  type: AutomationType;
  userSelection: UserSelection;
};

export type AutomationDiff = {
  __typename?: 'AutomationDiff';
  added: Array<Employee>;
  removed?: Maybe<Array<Employee>>;
};

export type AutomationInput = {
  autoReassign?: InputMaybe<BooleanValue>;
  enabled?: InputMaybe<BooleanValue>;
  endDatetime?: InputMaybe<OptionalDateTimeValue>;
  newUsersOnly?: InputMaybe<BooleanValue>;
  startDatetime?: InputMaybe<DateTimeValue>;
  userSelection?: InputMaybe<UserSelectionInput>;
};

export type AutomationMutationResult = MutationResult & {
  __typename?: 'AutomationMutationResult';
  automation?: Maybe<Automation>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export enum AutomationOverrideReason {
  ManuallyUnassigned = 'MANUALLY_UNASSIGNED',
  NewUsersOnly = 'NEW_USERS_ONLY'
}

export enum AutomationType {
  Assignment = 'ASSIGNMENT',
  LibraryAccess = 'LIBRARY_ACCESS'
}

export type AutomationTypeInput = {
  value: AutomationType;
};

export type Automations = PaginatedList & {
  __typename?: 'Automations';
  objects: Array<Automation>;
  totalCount: Scalars['Int'];
};

export type AutomationsInput = {
  filters: AutomationsV2FiltersInput;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type AutomationsV2FiltersInput = {
  type?: InputMaybe<AutomationTypeInput>;
  userSelection?: InputMaybe<UserSelectionFiltersInput>;
};

export type AvailableOrgsInput = {
  search?: InputMaybe<StringValue>;
};

export type AverageTimeToComplete = {
  __typename?: 'AverageTimeToComplete';
  courseId: Scalars['Int'];
  days?: Maybe<Scalars['Float']>;
  seconds?: Maybe<Scalars['Float']>;
};

export type AverageTimetoCompleteInput = {
  courseIds: Array<Scalars['Int']>;
};

export type AvgTrainingFeedbackInput = {
  since?: InputMaybe<DateTimeValue>;
};

export type AvgTrainingFeedbackWeek = {
  __typename?: 'AvgTrainingFeedbackWeek';
  average: Scalars['Float'];
  label: Scalars['String'];
  week?: Maybe<Scalars['Date']>;
};

export enum BackgroundGradient {
  Gradient_1 = 'GRADIENT_1',
  Gradient_2 = 'GRADIENT_2',
  Gradient_3 = 'GRADIENT_3',
  Gradient_4 = 'GRADIENT_4',
  Gradient_5 = 'GRADIENT_5',
  Gradient_6 = 'GRADIENT_6',
  Gradient_7 = 'GRADIENT_7',
  Gradient_8 = 'GRADIENT_8',
  Gradient_9 = 'GRADIENT_9',
  Gradient_10 = 'GRADIENT_10'
}

export type BaseMutationResult = MutationResult & {
  __typename?: 'BaseMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  additionalBillingEmails?: Maybe<Array<Scalars['String']>>;
  assignedCreditsBalance: Scalars['Int'];
  autoCollectInvoices: Scalars['Boolean'];
  billingEmail?: Maybe<Scalars['String']>;
  billingPortalUrl?: Maybe<Scalars['String']>;
  creditBalance: Scalars['Int'];
  id: Scalars['UUID'];
  invoices: Array<Invoice>;
  locationGroup?: Maybe<LocationGroup>;
  paymentMethod?: Maybe<PaymentMethod>;
  subscriptions: Array<BillingSubscription>;
};

export type BillingAccountInput = {
  address?: InputMaybe<AddressInput>;
  billingContact?: InputMaybe<UserInput>;
  email: Scalars['String'];
  legalName: Scalars['String'];
  locationLicenses?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<SubscriptionInput>;
  userLicenses?: InputMaybe<Scalars['Int']>;
};

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  id: Scalars['UUID'];
  plan: Plan;
};

export type BlastNotificationSchedule = {
  __typename?: 'BlastNotificationSchedule';
  reminderTime?: Maybe<Scalars['Time']>;
  sendUntil?: Maybe<Scalars['DateTime']>;
};

export type BlastNotificationScheduleInput = {
  sendUntil?: InputMaybe<Scalars['DateTime']>;
};

export type BlastNotifications = {
  __typename?: 'BlastNotifications';
  sentAt: Scalars['DateTime'];
};

export type BlastRecipient = {
  __typename?: 'BlastRecipient';
  /** @deprecated Field no longer supported */
  employee: Employee;
  id: Scalars['Int'];
  sentToPhone?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  trainee: Employee;
  user: Employee;
};

export type BlastRecipientGroup = {
  __typename?: 'BlastRecipientGroup';
  id: Scalars['Int'];
  location?: Maybe<Location>;
  role?: Maybe<Role>;
};

export type BlastSentRecord = {
  __typename?: 'BlastSentRecord';
  blastNotifications: Array<BlastNotifications>;
  createdAt: Scalars['DateTime'];
  firstReadAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  latestNotification?: Maybe<Scalars['DateTime']>;
  messageBlast: MessageBlast;
  messageReaction?: Maybe<MessageReaction>;
  recipient: Employee;
};

export type BlastSentRecordInput = {
  id: Scalars['Int'];
  messageReaction: MessageReaction;
};

export type BlastSentRecords = PaginatedList & {
  __typename?: 'BlastSentRecords';
  objects: Array<BlastSentRecord>;
  totalCount: Scalars['Int'];
};

export type BooleanValue = {
  value: Scalars['Boolean'];
};

export type BrandDetails = {
  __typename?: 'BrandDetails';
  aiBrandContext?: Maybe<AiOrgContext>;
  brandColor?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  logoUrls?: Maybe<ImageUrls>;
};

export type BrandDetailsInput = {
  brandColor?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
};

export type BuilderAction = {
  __typename?: 'BuilderAction';
  addedPathContentMembership?: Maybe<PathContentMembership>;
  clientId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  payload?: Maybe<Scalars['JSON']>;
  user: Employee;
  uuid: Scalars['UUID'];
};

export type BuilderActionInput = {
  clientId: Scalars['UUID'];
  courseBuilderAction?: InputMaybe<CourseBuilderActionInput>;
  pathBuilderAction?: InputMaybe<PathBuilderActionInput>;
  skillBuilderAction?: InputMaybe<SkillBuilderActionInput>;
  uuid: Scalars['UUID'];
};

export type BulkCreateTrainingResourceInput = {
  description?: InputMaybe<TranslationSetInput>;
  mediaUrls: TranslationSetInput;
  name: TranslationSetInput;
};

export type BulkUpdateLibraryItemAutomationsInput = {
  automationInput: AutomationInput;
  automationType: AutomationType;
  libraryItemIds: Array<Scalars['UUID']>;
};

export type CatalogCategory = {
  __typename?: 'CatalogCategory';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isOnCategoryNav: Scalars['Boolean'];
  memberships: Array<CatalogCourseMembership>;
  name: Scalars['String'];
  onHomepage: Scalars['Boolean'];
};

export type CatalogCourse = {
  __typename?: 'CatalogCourse';
  course: Course;
  description: Scalars['String'];
  developedBy?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  minutesToAuthor?: Maybe<Scalars['Int']>;
  type: CatalogCourseType;
};

export type CatalogCourseMembership = {
  __typename?: 'CatalogCourseMembership';
  catalogCourse?: Maybe<CatalogCourse>;
  catalogPath?: Maybe<CatalogPath>;
  catalogSharp?: Maybe<SharpCatalogExperience>;
  id: Scalars['Int'];
  index: Scalars['Int'];
};

export enum CatalogCourseType {
  Premium = 'PREMIUM',
  Ready = 'READY',
  Template = 'TEMPLATE'
}

export type CatalogMutationResult = MutationResult & {
  __typename?: 'CatalogMutationResult';
  course?: Maybe<Course>;
  courses?: Maybe<Array<Course>>;
  error?: Maybe<Error>;
  orgPremiumConfigs?: Maybe<Array<OrgPremiumContentConfig>>;
  paths?: Maybe<Array<Path>>;
  success: Scalars['Boolean'];
};

export type CatalogPath = {
  __typename?: 'CatalogPath';
  description: Scalars['String'];
  developedBy?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  path: Path;
  premiumConfig?: Maybe<PremiumContentConfig>;
  type: CatalogCourseType;
};

export type CertificateEndorser = {
  __typename?: 'CertificateEndorser';
  name: Scalars['String'];
  title: Scalars['String'];
};

export enum ClockInConfirmationAction {
  Files = 'FILES',
  Lessons = 'LESSONS',
  Messages = 'MESSAGES'
}

export type ClockInConfirmationSettings = {
  __typename?: 'ClockInConfirmationSettings';
  files: Scalars['Boolean'];
  id: Scalars['Int'];
  lessons: Scalars['Boolean'];
  messages: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  createdAt: Scalars['DateTime'];
  createdBy: Employee;
  id: Scalars['UUID'];
  inProductNotifications: Array<InProductNotification>;
  mentions: Array<CommentMention>;
  text: Scalars['String'];
  thread: CommentThread;
};

export type CommentInput = {
  mentionedUserIds: Array<Scalars['Int']>;
  text: Scalars['String'];
};

export type CommentMention = {
  __typename?: 'CommentMention';
  id: Scalars['UUID'];
  mentionedUser: Employee;
};

export type CommentThread = {
  __typename?: 'CommentThread';
  comments: Array<Comment>;
  id: Scalars['UUID'];
  prompt: Prompt;
};

export type CommentThreadMutationResult = MutationResult & {
  __typename?: 'CommentThreadMutationResult';
  commentThread?: Maybe<CommentThread>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export enum CompletePathInOrder {
  AnyOrder = 'ANY_ORDER',
  SectionOrder = 'SECTION_ORDER',
  SectionOrderSkipVerifications = 'SECTION_ORDER_SKIP_VERIFICATIONS',
  StrictOrder = 'STRICT_ORDER',
  StrictOrderSkipVerifications = 'STRICT_ORDER_SKIP_VERIFICATIONS'
}

export type CompletionReportsInput = {
  allLocations?: InputMaybe<BooleanValue>;
  libraryItemIds?: InputMaybe<UuidArrayInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  roleIds?: InputMaybe<IntArrayInput>;
};

export type ComplianceAttempt = {
  __typename?: 'ComplianceAttempt';
  assignedUserType?: Maybe<AssignedUserType>;
  becameCompliantAt?: Maybe<Scalars['DateTime']>;
  compliantUntil?: Maybe<Scalars['DateTime']>;
  courseInstance?: Maybe<CourseInstance>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  importedFromExternalSource: Scalars['Boolean'];
  isCompliant: Scalars['Boolean'];
  pathInstance?: Maybe<PathInstance>;
  usState?: Maybe<Scalars['USState']>;
};

export type ComplianceAttemptImportInput = {
  assignedUserType?: InputMaybe<AssignedUserType>;
  becameCompliantAt: Scalars['DateTime'];
  complianceType: ComplianceType;
  usState?: InputMaybe<Scalars['USState']>;
  userId: Scalars['Int'];
};

export type ComplianceAttemptImportsInput = {
  complianceAttemptImports: Array<ComplianceAttemptImportInput>;
};

export type ComplianceRecord = {
  __typename?: 'ComplianceRecord';
  attempts: Array<ComplianceAttempt>;
  complianceType: ComplianceType;
  compliantUntil?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isCompliant: Scalars['Boolean'];
  user: Employee;
};

export type ComplianceRecords = PaginatedList & {
  __typename?: 'ComplianceRecords';
  objects: Array<ComplianceRecord>;
  totalCount: Scalars['Int'];
};

export type ComplianceRecordsInput = {
  assignedUserTypes?: InputMaybe<Array<AssignedUserType>>;
  complianceType?: InputMaybe<ComplianceTypeInput>;
  isCompliant?: InputMaybe<BooleanValue>;
  isOrHasBeenCompliant?: InputMaybe<BooleanValue>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  peopleFilters?: InputMaybe<GetPeopleInput>;
  roleIds?: InputMaybe<IntArrayInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
  usStates?: InputMaybe<Array<InputMaybe<Scalars['USState']>>>;
  userIds?: InputMaybe<IntArrayInput>;
};

export type ComplianceReport = {
  __typename?: 'ComplianceReport';
  allLocations: Scalars['Boolean'];
  completed: Scalars['Int'];
  complianceType: ComplianceType;
  id: Scalars['UUID'];
  locationGroupId?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type ComplianceReportsInput = {
  complianceType?: InputMaybe<ComplianceTypeInput>;
};

export enum ComplianceType {
  Allertrain = 'ALLERTRAIN',
  CaWorkplaceViolencePreventionNonsupervisor = 'CA_WORKPLACE_VIOLENCE_PREVENTION_NONSUPERVISOR',
  CaWorkplaceViolencePreventionSupervisor = 'CA_WORKPLACE_VIOLENCE_PREVENTION_SUPERVISOR',
  FoodHandler = 'FOOD_HANDLER',
  FoodManager = 'FOOD_MANAGER',
  HumanTraffickingAwarenessCa = 'HUMAN_TRAFFICKING_AWARENESS_CA',
  SexualHarassmentPrevention = 'SEXUAL_HARASSMENT_PREVENTION'
}

export type ComplianceTypeInput = {
  value: ComplianceType;
};

export type ContentInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
  trainingResourceId?: InputMaybe<Scalars['UUID']>;
};

export type ContentPermissions = {
  __typename?: 'ContentPermissions';
  hasCommentPermission: Scalars['Boolean'];
  hasEditAdminAndManagerSharingPermission: Scalars['Boolean'];
  hasEditContentPermission: Scalars['Boolean'];
  hasPublishPermission: Scalars['Boolean'];
  hasShareWithIndividualsPermission: Scalars['Boolean'];
};

export type ContentQrCode = {
  __typename?: 'ContentQRCode';
  id: Scalars['UUID'];
  imageUrl: Scalars['String'];
  includeLogo: Scalars['Boolean'];
  includeTitle: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

export type ContentSharingPermission = {
  __typename?: 'ContentSharingPermission';
  allAdmins: SharingPermission;
  allManagers: SharingPermission;
  id: Scalars['UUID'];
  individuals: Array<UserSharingPermission>;
};

export type ContentSharingPermissionMutationResult = MutationResult & {
  __typename?: 'ContentSharingPermissionMutationResult';
  contentSharingPermission?: Maybe<ContentSharingPermission>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type ContentTagMembership = {
  __typename?: 'ContentTagMembership';
  /** @deprecated Field no longer supported */
  course?: Maybe<Course>;
  /** @deprecated Field no longer supported */
  courseId?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  libraryItem: LibraryItem;
  libraryItemId: Scalars['UUID'];
  /** @deprecated Field no longer supported */
  path?: Maybe<Path>;
  /** @deprecated Field no longer supported */
  pathId?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  skill?: Maybe<Skill>;
  /** @deprecated Field no longer supported */
  skillId?: Maybe<Scalars['Int']>;
  tag: Tag;
  tagId: Scalars['UUID'];
  /** @deprecated Field no longer supported */
  trainingResource?: Maybe<TrainingResource>;
  /** @deprecated Field no longer supported */
  trainingResourceId?: Maybe<Scalars['UUID']>;
};

export type ContentTagMembershipInput = {
  content?: InputMaybe<ContentInput>;
  libraryItemId?: InputMaybe<Scalars['UUID']>;
  tagId: Scalars['UUID'];
};

export type ContentTagMembershipMutationResult = MutationResult & {
  __typename?: 'ContentTagMembershipMutationResult';
  contentTagMemberships?: Maybe<Array<ContentTagMembership>>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type CopyPathInput = {
  duplicateChildContent?: InputMaybe<Scalars['Boolean']>;
};

export type Course = {
  __typename?: 'Course';
  aiGenerateCourseFromOutlineRequest?: Maybe<AiGenerateCourseFromOutlineRequest>;
  assignableByManagers: Scalars['Boolean'];
  assignedCount: Scalars['Int'];
  autoReassignOnFailure: Scalars['Boolean'];
  averageAccuracy?: Maybe<Scalars['Float']>;
  averageFeedbackRating?: Maybe<Scalars['Float']>;
  averageTimeToCompleteDays?: Maybe<Scalars['Float']>;
  averageTimeToCompleteSeconds?: Maybe<Scalars['Float']>;
  courseVersions: Array<CourseVersion>;
  createdFrom?: Maybe<CatalogCourse>;
  draftVersion: CourseVersion;
  enableCertificate: Scalars['Boolean'];
  enablePromptRetries: Scalars['Boolean'];
  id: Scalars['Int'];
  isAIGenerated: Scalars['Boolean'];
  lastBuilderActionId?: Maybe<Scalars['Int']>;
  lastPublishedBy?: Maybe<Employee>;
  lessonCount: Scalars['Int'];
  libraryItem: LibraryItem;
  passingAccuracyThreshold?: Maybe<Scalars['Int']>;
  pathContentMemberships: Array<PathContentMembership>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<CourseVersion>;
  scormUpload?: Maybe<ScormUpload>;
  topic?: Maybe<Topic>;
};

export type CourseBuilderActionInput = {
  addLessonAction?: InputMaybe<AddLessonActionInput>;
  addMcOptionAction?: InputMaybe<AddMcOptionActionInput>;
  addPromptAction?: InputMaybe<AddPromptActionInput>;
  addResponseOptionAction?: InputMaybe<AddResponseOptionActionInput>;
  courseId: Scalars['Int'];
  moveLessonAction?: InputMaybe<MoveLessonActionInput>;
  movePromptAction?: InputMaybe<MovePromptActionInput>;
  moveResponseOptionAction?: InputMaybe<MoveResponseOptionActionInput>;
  removeLessonAction?: InputMaybe<RemoveLessonActionInput>;
  removeMcOptionAction?: InputMaybe<RemoveMcOptionActionInput>;
  removePromptAction?: InputMaybe<RemovePromptActionInput>;
  removeResponseOptionAction?: InputMaybe<RemoveResponseOptionActionInput>;
  removeTranslationSetOverrides?: InputMaybe<RemoveTranslationSetOverridesActionInput>;
  removeTranslationSetOverridesAction?: InputMaybe<RemoveTranslationSetOverridesActionInput>;
  setAllowGradedFreeResponseTyposAction?: InputMaybe<SetAllowGradedFreeResponseTyposActionInput>;
  setCourseCoverImageAction?: InputMaybe<SetCourseCoverImageActionInput>;
  setCourseDescriptionAction?: InputMaybe<SetCourseDescriptionActionInput>;
  setCourseNameAction?: InputMaybe<SetCourseNameActionInput>;
  setCourseSettingsAction?: InputMaybe<SetCourseSettingsActionInput>;
  setGradedFreeResponseCorrectAnswerAction?: InputMaybe<SetGradedFreeResponseCorrectAnswerActionInput>;
  setLessonNameAction?: InputMaybe<SetLessonNameActionInput>;
  setMcCorrectOptionAction?: InputMaybe<SetMcCorrectOptionActionInput>;
  setMcOptionAction?: InputMaybe<SetMcOptionActionInput>;
  setPromptAssignMediaAction?: InputMaybe<SetPromptAssignMediaActionInput>;
  setPromptMediaAction?: InputMaybe<SetPromptMediaActionInput>;
  setPromptRequireFullVideoPlaybackAction?: InputMaybe<SetPromptRequireFullVideoPlaybackActionInput>;
  setPromptSelectAllThatApplyAction?: InputMaybe<SetPromptSelectAllThatApplyActionInput>;
  setPromptTextAction?: InputMaybe<SetPromptTextActionInput>;
  setPromptTypeAction?: InputMaybe<SetPromptTypeActionInput>;
  setResponseOptionAnswerAction?: InputMaybe<SetResponseOptionAnswerActionInput>;
  setResponseOptionIsCorrectAction?: InputMaybe<SetResponseOptionIsCorrectActionInput>;
  setResponseOptionMediaAction?: InputMaybe<SetResponseOptionMediaActionInput>;
  setTrueFalseCorrectOptionAction?: InputMaybe<SetTrueFalseCorrectOptionActionInput>;
};

export type CourseFeedback = {
  __typename?: 'CourseFeedback';
  comment?: Maybe<Scalars['String']>;
  commentLanguage?: Maybe<Scalars['Language']>;
  commentTranslation?: Maybe<Scalars['String']>;
  courseInstance: CourseInstance;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rating: Scalars['Int'];
};

export type CourseFeedbackInput = {
  comment?: InputMaybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type CourseFeedbacks = PaginatedList & {
  __typename?: 'CourseFeedbacks';
  objects: Array<CourseFeedback>;
  totalCount: Scalars['Int'];
};

export type CourseFeedbacksFiltersInput = {
  courseIds?: InputMaybe<IntArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  ratings?: InputMaybe<IntArrayInput>;
};

export type CourseFeedbacksInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  courseIds?: InputMaybe<IntArrayInput>;
  filters?: InputMaybe<CourseFeedbacksFiltersInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  pathIds?: InputMaybe<IntArrayInput>;
  ratings?: InputMaybe<IntArrayInput>;
  roleGroupIds?: InputMaybe<UuidArrayInput>;
  roleIds?: InputMaybe<IntArrayInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type CourseInstance = {
  __typename?: 'CourseInstance';
  accuracy?: Maybe<Scalars['Float']>;
  assignedByList: Array<Scalars['String']>;
  assignment: Assignment;
  certificateUrl?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  course: Course;
  courseVersion?: Maybe<CourseVersion>;
  createdAt: Scalars['DateTime'];
  drillStatuses: Array<DrillStatus>;
  dueDate?: Maybe<Scalars['Date']>;
  failed?: Maybe<Scalars['Boolean']>;
  feedback?: Maybe<CourseFeedback>;
  firstAssignedAt?: Maybe<Scalars['DateTime']>;
  firstAssignedByStr?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  index?: Maybe<Scalars['Float']>;
  isAssignedThroughModule: Scalars['Boolean'];
  lastActivityAt?: Maybe<Scalars['DateTime']>;
  lessonInstances: Array<LessonInstance>;
  markedCompletedBy?: Maybe<Employee>;
  markedCompletedReason?: Maybe<Scalars['String']>;
  pathContentMembershipInstances: Array<PathContentMembershipInstance>;
  percentComplete: Scalars['Float'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['AttemptStatus'];
  track: Scalars['Int'];
  user: Employee;
};

export type CourseInstanceMutationResult = MutationResult & {
  __typename?: 'CourseInstanceMutationResult';
  courseInstance?: Maybe<CourseInstance>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type CourseInstancesMutationResult = MutationResult & {
  __typename?: 'CourseInstancesMutationResult';
  courseInstances?: Maybe<Array<CourseInstance>>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type CourseMutationResult = MutationResult & {
  __typename?: 'CourseMutationResult';
  course?: Maybe<Course>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type CourseOutline = {
  __typename?: 'CourseOutline';
  aiGenerateCourseFromOutlineRequests: Array<AiGenerateCourseFromOutlineRequest>;
  aiGenerateModuleRequest?: Maybe<AiGenerateModuleRequest>;
  body: Scalars['String'];
  id: Scalars['UUID'];
  interactivity: Scalars['Int'];
  title: Scalars['String'];
  toneFormality: Scalars['Int'];
  toneSeriousness: Scalars['Int'];
};

export type CourseProgressCourseInstanceInput = {
  accuracy?: InputMaybe<Scalars['Float']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  percentComplete: Scalars['Float'];
  startedAt: Scalars['DateTime'];
};

export type CourseProgressLessonInstanceInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  courseInstance: CourseProgressCourseInstanceInput;
  lessonInstanceId: Scalars['UUID'];
  percentComplete: Scalars['Float'];
  startedAt: Scalars['DateTime'];
};

export type CourseProgressPromptAttemptInput = {
  promptInstance: CourseProgressPromptInstanceInput;
  response: Scalars['String'];
  responseOptionInstances?: InputMaybe<Array<CourseProgressResponseOptionInstanceInput>>;
  submittedAt: Scalars['DateTime'];
  trueFalseResponse?: InputMaybe<Scalars['Boolean']>;
};

export type CourseProgressPromptInstanceInput = {
  answeredCorrectly?: InputMaybe<Scalars['Boolean']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  lessonInstance: CourseProgressLessonInstanceInput;
  numFailures: Scalars['Int'];
  promptId: Scalars['Int'];
  startedAt: Scalars['DateTime'];
};

export type CourseProgressResponseOptionInstanceInput = {
  id: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type CourseVersion = {
  __typename?: 'CourseVersion';
  archivedPublishState?: Maybe<PublishState>;
  authors: Array<Employee>;
  course: Course;
  createdAt: Scalars['DateTime'];
  grade?: Maybe<CourseVersionGrade>;
  id: Scalars['Int'];
  lessons: Array<Lesson>;
  publishState?: Maybe<PublishState>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type CourseVersionGrade = {
  __typename?: 'CourseVersionGrade';
  courseVersion: CourseVersion;
  feedbackMd: Scalars['String'];
  grade: Scalars['Float'];
  id: Scalars['UUID'];
  pathVersion: PathVersion;
  sections: Array<SectionGrade>;
  totalScore: Scalars['Int'];
};

export type CourseVersionMutationResult = MutationResult & {
  __typename?: 'CourseVersionMutationResult';
  courseVersion?: Maybe<CourseVersion>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type CoverImage = {
  __typename?: 'CoverImage';
  background?: Maybe<BackgroundGradient>;
  emojiIcon?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageUrls?: Maybe<ImageUrls>;
  /** @deprecated Field no longer supported */
  mediaUrl?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type CoverImageInput = {
  background?: InputMaybe<BackgroundGradient>;
  emojiIcon?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  mediaUrl?: InputMaybe<Scalars['String']>;
};

export type CreateAiFeedbackInput = {
  libraryItemId: Scalars['UUID'];
  selection: FeedbackSelection;
  text?: InputMaybe<Scalars['String']>;
};

export type CreateGroupInput = {
  address?: InputMaybe<AddressInput>;
  name: Scalars['String'];
  type: GroupType;
};

export type CreateLocationInput = {
  addressInput?: InputMaybe<AddressInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  name: Scalars['String'];
};

export type CreateRoleInput = {
  directReportRoleIds?: InputMaybe<IntArrayInput>;
  name: Scalars['String'];
  permissions?: InputMaybe<ActionPermissionSetInput>;
  requireClockInConfirmation?: InputMaybe<Scalars['Boolean']>;
  roleGroupIds?: InputMaybe<UuidArrayInput>;
  roleUserType?: InputMaybe<UserType>;
};

export type CreateTrainingResourceInput = {
  mediaInput: MediaInput;
};

export type CreateUserSharingPermissionsInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  permission: SharingPermission;
  skillId?: InputMaybe<Scalars['Int']>;
  userIds: Array<Scalars['Int']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  brand: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  last4: Scalars['String'];
};

export type CreditPurchaseOption = {
  __typename?: 'CreditPurchaseOption';
  baseCredits: Scalars['Int'];
  bonusCredits: Scalars['Int'];
  id: Scalars['UUID'];
  perUnitCostBasis: Scalars['Float'];
  price: Scalars['Float'];
  savings: Scalars['Float'];
  totalCredits: Scalars['Int'];
};

export type CurrentCourseInstanceFiltersInput = {
  versionOlderThan?: InputMaybe<DateTimeValue>;
};

export type CurrentPathInstanceFiltersInput = {
  versionOlderThan?: InputMaybe<DateTimeValue>;
};

export type CurrentSkillInstanceFiltersInput = {
  delegationFilters?: InputMaybe<DelegationFiltersInput>;
  verifiable?: InputMaybe<BooleanValue>;
  verifiedByUsers?: InputMaybe<IntArrayInput>;
};

export type CustomReport = {
  __typename?: 'CustomReport';
  createdBy?: Maybe<Employee>;
  emailSchedule?: Maybe<ScheduledReport>;
  fields: Array<CustomReportFieldMemberships>;
  filters?: Maybe<Array<ReportFilter>>;
  id: Scalars['UUID'];
  lastEditedBy?: Maybe<Employee>;
  org: Org;
  sortAscending?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<ReportField>;
  title: Scalars['String'];
  topic: ReportTopic;
};

export type CustomReportFieldMemberships = {
  __typename?: 'CustomReportFieldMemberships';
  field: ReportField;
  index: Scalars['Int'];
};

export type CustomerSuccessManager = {
  __typename?: 'CustomerSuccessManager';
  calendlyUrl: Scalars['String'];
  headshotUrl: Scalars['String'];
  name: Scalars['String'];
  salesforceId?: Maybe<Scalars['String']>;
};

export type DashboardFeedbackInput = {
  comment: Scalars['String'];
};

export type DateTimeInput = {
  value: Scalars['DateTime'];
};

export type DateTimeValue = {
  value: Scalars['DateTime'];
};

export enum DayOfTheWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DeactivationReason {
  DeactivatedOrg = 'DEACTIVATED_ORG',
  Hris = 'HRIS',
  Manual = 'MANUAL',
  SwitchedOrgs = 'SWITCHED_ORGS'
}

export type DelegationFiltersInput = {
  delegatedByUsers?: InputMaybe<IntArrayInput>;
  delegatedToUsers?: InputMaybe<IntArrayInput>;
  includeNotDelegated?: InputMaybe<BooleanValue>;
};

export type DeleteComplianceAttemptsInput = {
  complianceRecordIds: Array<Scalars['UUID']>;
};

export type DeleteOrgPremiumContentConfigsInput = {
  ids: Array<Scalars['Int']>;
};

export type DiffAssignmentRulesChangeInput = {
  assignmentId: Scalars['Int'];
  assignmentInput: AssignmentInput;
};

export type DiffAssignmentRulesMutationResult = MutationResult & {
  __typename?: 'DiffAssignmentRulesMutationResult';
  /** @deprecated Field no longer supported */
  diff?: Maybe<AssignmentDiff>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type DiffAutomationRulesChangeInput = {
  automationId: Scalars['Int'];
  automationInput: AutomationInput;
  countIsForReviewScreen?: InputMaybe<Scalars['Boolean']>;
};

export type DiffAutomationRulesMutationResult = MutationResult & {
  __typename?: 'DiffAutomationRulesMutationResult';
  diff?: Maybe<AutomationDiff>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type DrillStatus = {
  __typename?: 'DrillStatus';
  completedAt?: Maybe<Scalars['DateTime']>;
  courseInstance: CourseInstance;
  id: Scalars['UUID'];
  lesson: Lesson;
  placeInSequence: Scalars['Float'];
  startedAt?: Maybe<Scalars['DateTime']>;
  track: Scalars['Int'];
};

export type DrillStatusMutationResult = MutationResult & {
  __typename?: 'DrillStatusMutationResult';
  drillStatus?: Maybe<DrillStatus>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type DueDateInput = {
  dueDate?: InputMaybe<Scalars['Date']>;
  dueDateDays?: InputMaybe<Scalars['Int']>;
};

export type DueDateInputValue = {
  value: DueDateInput;
};

export type EslTheme = {
  __typename?: 'ESLTheme';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EditPromptWithAiInput = {
  autoAcceptChanges?: InputMaybe<Scalars['Boolean']>;
  customRequestText?: InputMaybe<Scalars['String']>;
  promptUuid: Scalars['UUID'];
  type: EditPromptWithAiType;
};

export enum EditPromptWithAiType {
  AddEmojis = 'ADD_EMOJIS',
  AddMedia = 'ADD_MEDIA',
  ExtendText = 'EXTEND_TEXT',
  NewPrompt = 'NEW_PROMPT',
  ReadingLevel = 'READING_LEVEL',
  ShortenText = 'SHORTEN_TEXT',
  SpellingAndGrammar = 'SPELLING_AND_GRAMMAR',
  WriteQuestion = 'WRITE_QUESTION'
}

export type EmailPreferences = {
  __typename?: 'EmailPreferences';
  emailsEnabled: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type Employee = {
  __typename?: 'Employee';
  blastSentRecords: Array<BlastSentRecord>;
  brands: Array<LocationGroup>;
  createdAt: Scalars['DateTime'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  deactivatedBy?: Maybe<Employee>;
  deactivatedReason?: Maybe<DeactivationReason>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  foodHandlerExams: Array<FoodHandlerExam>;
  hasConfirmedClockIn: Scalars['Boolean'];
  hasUnreadMessages: Scalars['Boolean'];
  hasUsedApp: Scalars['Boolean'];
  hrisEmployee?: Maybe<HrisEmployee>;
  id: Scalars['Int'];
  incompleteAssignmentsCount: Scalars['Int'];
  jobTitle?: Maybe<Scalars['String']>;
  language: Scalars['Language'];
  lastActivityAt?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  locations: Array<Location>;
  name: Scalars['String'];
  notificationPreferences?: Maybe<NotificationPreferences>;
  oneOffCheckInsToFacilitate: Array<OneOffCheckInDelegation>;
  oneOffDelegatedCheckIns: Array<OneOffCheckInDelegation>;
  ongoingCheckInsToFacilitate: Array<OngoingCheckInDelegation>;
  ongoingDelegatedCheckIns: Array<OngoingCheckInDelegation>;
  openedAt?: Maybe<Scalars['DateTime']>;
  org?: Maybe<Org>;
  pastDueAssignmentsCount: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  requiresClockInConfirmation: Scalars['Boolean'];
  roles: Array<Role>;
  unreadMessageCount: Scalars['Int'];
  userActionPermissionOverrides?: Maybe<UserActionPermissionOverrides>;
  userType: UserType;
};

export type EmployeeEngagement = {
  __typename?: 'EmployeeEngagement';
  activeEmployees: Scalars['Int'];
  totalEmployees: Scalars['Int'];
};

export type EmployeeMutationResult = MutationResult & {
  __typename?: 'EmployeeMutationResult';
  /** @deprecated Field no longer supported */
  employee?: Maybe<Employee>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<Employee>;
};

export type Employees = PaginatedList & {
  __typename?: 'Employees';
  objects: Array<Employee>;
  totalCount: Scalars['Int'];
};

export type EmployeesMutationResult = MutationResult & {
  __typename?: 'EmployeesMutationResult';
  employees?: Maybe<Array<Employee>>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type FeatureFlagInput = {
  enabled: BooleanValue;
  name: Scalars['String'];
};

export type FeedbackInput = {
  comment?: InputMaybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export enum FeedbackSelection {
  Ignored = 'IGNORED',
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export enum FieldType {
  Date = 'DATE',
  Datetime = 'DATETIME',
  Locations = 'LOCATIONS',
  Number = 'NUMBER',
  Roles = 'ROLES',
  String = 'STRING',
  StringList = 'STRING_LIST'
}

export type FileInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FileListUploadMutationResult = MutationResult & {
  __typename?: 'FileListUploadMutationResult';
  error?: Maybe<Error>;
  fileUrls?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type FileUploadMutationResult = MutationResult & {
  __typename?: 'FileUploadMutationResult';
  error?: Maybe<Error>;
  fileUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FoodHandlerExam = {
  __typename?: 'FoodHandlerExam';
  id: Scalars['UUID'];
  link: Scalars['String'];
  passed: Scalars['Boolean'];
  pathInstance: PathInstance;
  score?: Maybe<Scalars['Float']>;
};

export type GenerateBuilderActionsFromSuggestionMutationResult = MutationResult & {
  __typename?: 'GenerateBuilderActionsFromSuggestionMutationResult';
  builderActionInputs: Array<Scalars['JSON']>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type GenerateLinkTokenMutationResult = MutationResult & {
  __typename?: 'GenerateLinkTokenMutationResult';
  error?: Maybe<Error>;
  hrisIntegrationId?: Maybe<Scalars['UUID']>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type GenericMutationResult = MutationResult & {
  __typename?: 'GenericMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type GetAiRequestsInput = {
  courseVersionId?: InputMaybe<Scalars['Int']>;
  includeAcceptedAndRejected?: InputMaybe<Scalars['Boolean']>;
};

export type GetBillingAccountsInput = {
  ids?: InputMaybe<UuidArrayInput>;
};

export type GetBuilderActionsInput = {
  afterBuilderActionId?: InputMaybe<Scalars['Int']>;
  clientId: Scalars['UUID'];
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
  trainingResourceId?: InputMaybe<Scalars['UUID']>;
};

export type GetPeopleInput = {
  activeSince?: InputMaybe<DateTimeValue>;
  doingEsl?: InputMaybe<BooleanValue>;
  excludeConfirmedStillEmployedAfter?: InputMaybe<DateTimeValue>;
  hasLocation?: InputMaybe<BooleanValue>;
  hasRole?: InputMaybe<BooleanValue>;
  hasUsedApp?: InputMaybe<BooleanValue>;
  inactiveSince?: InputMaybe<DateTimeValue>;
  includeDeactivated?: InputMaybe<BooleanValue>;
  includeStaffForStaff?: InputMaybe<BooleanValue>;
  languages?: InputMaybe<LanguageArrayInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  roleGroupIds?: InputMaybe<UuidArrayInput>;
  roleIds?: InputMaybe<IntArrayInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
  userIds?: InputMaybe<IntArrayInput>;
  userTypes?: InputMaybe<UserTypeListInput>;
};

export type GetReportViewsInput = {
  reportViewType: ReportViewTypeValueInput;
};

export type GiveAccessToLibraryItemsInput = {
  libraryItemIds: Array<Scalars['UUID']>;
  userSelection: UserSelectionInput;
};

export type Glossary = {
  __typename?: 'Glossary';
  glossaryEntries: Array<GlossaryEntry>;
  id: Scalars['UUID'];
};

export type GlossaryEntry = {
  __typename?: 'GlossaryEntry';
  entry: Scalars['String'];
  id: Scalars['UUID'];
};

export type GlossarySet = {
  __typename?: 'GlossarySet';
  glossary: Glossary;
  id: Scalars['UUID'];
};

export type GlossarySetInput = {
  entries: Array<Scalars['String']>;
};

export type GradeActionItem = {
  __typename?: 'GradeActionItem';
  action: Scalars['String'];
  id: Scalars['UUID'];
  priority: ActionItemPriority;
  section: SectionGrade;
  suggestion: Scalars['String'];
};

export type GradeCourseVersionInput = {
  courseVersionId: Scalars['Int'];
  pathVersionId: Scalars['UUID'];
};

export type GradeCourseVersionMutationResult = MutationResult & {
  __typename?: 'GradeCourseVersionMutationResult';
  courseVersionGrade: CourseVersionGrade;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type GradedFreeResponseInput = {
  correctAnswer: Scalars['String'];
  question: Scalars['String'];
  response: Scalars['String'];
};

export enum GroupType {
  Location = 'LOCATION',
  Role = 'ROLE'
}

export type GuestFeedback = {
  __typename?: 'GuestFeedback';
  id: Scalars['UUID'];
  locationName: Scalars['String'];
  parts: Array<GuestFeedbackPart>;
  starRating: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type GuestFeedbackAiWorkflow = {
  __typename?: 'GuestFeedbackAiWorkflow';
  categories: Array<GuestFeedbackCategory>;
  completedAt: Scalars['DateTime'];
  id: Scalars['UUID'];
};

export type GuestFeedbackCategory = {
  __typename?: 'GuestFeedbackCategory';
  aiCourseOutline: Scalars['String'];
  aiTrainingSuggestion: Scalars['String'];
  guestFeedbackMemberships: Array<GuestFeedbackCategoryMembership>;
  id: Scalars['UUID'];
  isApprovedForDisplay: Scalars['Boolean'];
  name: Scalars['String'];
  topLevelCategoryName: Scalars['String'];
};

export type GuestFeedbackCategoryLibraryItemRelevance = {
  __typename?: 'GuestFeedbackCategoryLibraryItemRelevance';
  id: Scalars['UUID'];
  libraryItem: LibraryItem;
  relevanceScore: Scalars['Float'];
};

export type GuestFeedbackCategoryMembership = {
  __typename?: 'GuestFeedbackCategoryMembership';
  guestFeedback: GuestFeedback;
  id: Scalars['UUID'];
  matchScore: Scalars['Float'];
};

export type GuestFeedbackPart = {
  __typename?: 'GuestFeedbackPart';
  answer: Scalars['String'];
  id: Scalars['UUID'];
  question: Scalars['String'];
};

export type HrisActivationData = {
  __typename?: 'HrisActivationData';
  createdLocations: Array<HrisLocation>;
  createdTeams: Array<HrisTeam>;
  deactivatedEmployees: Array<HrisEmployee>;
  linkedEmployees: Array<HrisEmployee>;
  linkedLocations: Array<HrisLocation>;
  linkedTeams: Array<HrisTeam>;
};

export type HrisConfig = {
  __typename?: 'HrisConfig';
  activatedAt?: Maybe<Scalars['DateTime']>;
  dataVerifiedAt?: Maybe<Scalars['DateTime']>;
  finishedConnectingAt?: Maybe<Scalars['DateTime']>;
  finishedLoadingAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
};

export type HrisConfigMutationResult = MutationResult & {
  __typename?: 'HrisConfigMutationResult';
  error?: Maybe<Error>;
  hrisConfig?: Maybe<HrisConfig>;
  success: Scalars['Boolean'];
};

export type HrisCsvUpload = {
  __typename?: 'HrisCsvUpload';
  appliedAt?: Maybe<Scalars['DateTime']>;
  csvObjects: Array<Scalars['JSON']>;
  dataVerifiedAt?: Maybe<Scalars['DateTime']>;
  finishedCreatingObjectsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  idField?: Maybe<Scalars['String']>;
  locationField?: Maybe<Scalars['String']>;
  nameField?: Maybe<Scalars['String']>;
  phoneField?: Maybe<Scalars['String']>;
  selectedColumnsAt?: Maybe<Scalars['DateTime']>;
  startedCreatingObjectsAt?: Maybe<Scalars['DateTime']>;
  teamField?: Maybe<Scalars['String']>;
  terminationDateField?: Maybe<Scalars['String']>;
  uploadedBy: Employee;
};

export type HrisCsvUploadMutationResult = MutationResult & {
  __typename?: 'HrisCsvUploadMutationResult';
  error?: Maybe<Error>;
  hrisCsvUpload: HrisCsvUpload;
  success: Scalars['Boolean'];
};

export type HrisEmployee = {
  __typename?: 'HrisEmployee';
  createdAt: Scalars['DateTime'];
  csvUpload?: Maybe<HrisCsvUpload>;
  employmentStatus?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  locationMemberships: Array<HrisEmployeeLocationMembership>;
  phoneNumber?: Maybe<Scalars['String']>;
  remoteHrisId: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  teamMemberships: Array<HrisEmployeeTeamMembership>;
  user?: Maybe<Employee>;
};

export type HrisEmployeeLocationMembership = {
  __typename?: 'HrisEmployeeLocationMembership';
  id: Scalars['UUID'];
  location: HrisLocation;
};

export type HrisEmployeeMutationResult = MutationResult & {
  __typename?: 'HrisEmployeeMutationResult';
  error?: Maybe<Error>;
  hrisEmployee?: Maybe<HrisEmployee>;
  success: Scalars['Boolean'];
};

export type HrisEmployeeTeamMembership = {
  __typename?: 'HrisEmployeeTeamMembership';
  id: Scalars['UUID'];
  team: HrisTeam;
};

export type HrisEmployees = PaginatedList & {
  __typename?: 'HrisEmployees';
  objects: Array<HrisEmployee>;
  totalCount: Scalars['Int'];
};

export type HrisEmployeesFiltersInput = {
  inviteStatus?: InputMaybe<BooleanValue>;
  locationIds?: InputMaybe<IntArrayInput>;
  roleIds?: InputMaybe<IntArrayInput>;
};

export type HrisEmployeesInput = {
  filters?: InputMaybe<HrisEmployeesFiltersInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type HrisIntegration = {
  __typename?: 'HrisIntegration';
  createdAt: Scalars['DateTime'];
  hrisProvider?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  initialSyncCompletedAt?: Maybe<Scalars['DateTime']>;
  lastReceivedDataAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MergeLinkedAccountStatus>;
  statusDetail?: Maybe<Scalars['String']>;
};

export type HrisLocation = {
  __typename?: 'HrisLocation';
  createdAt: Scalars['DateTime'];
  group?: Maybe<Location>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type HrisLocations = PaginatedList & {
  __typename?: 'HrisLocations';
  objects: Array<HrisLocation>;
  totalCount: Scalars['Int'];
};

export type HrisLocationsInput = {
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type HrisTeam = {
  __typename?: 'HrisTeam';
  createdAt: Scalars['DateTime'];
  group?: Maybe<Role>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type HrisTeams = PaginatedList & {
  __typename?: 'HrisTeams';
  objects: Array<HrisTeam>;
  totalCount: Scalars['Int'];
};

export type HrisTeamsInput = {
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type ImageUrls = {
  __typename?: 'ImageUrls';
  id: Scalars['UUID'];
  medium?: Maybe<Scalars['String']>;
  original: Scalars['String'];
  thumb?: Maybe<Scalars['String']>;
  wide?: Maybe<Scalars['String']>;
};

export type InProductNotification = {
  __typename?: 'InProductNotification';
  comment?: Maybe<Comment>;
  course?: Maybe<Course>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  message?: Maybe<Scalars['String']>;
  path?: Maybe<Path>;
  readAt?: Maybe<Scalars['DateTime']>;
  type: NotificationType;
};

export type InProductNotifications = PaginatedList & {
  __typename?: 'InProductNotifications';
  objects: Array<InProductNotification>;
  totalCount: Scalars['Int'];
};

export type InProductNotificationsInput = {
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type InProductNotificationsMutationResult = MutationResult & {
  __typename?: 'InProductNotificationsMutationResult';
  error?: Maybe<Error>;
  inProductNotifications: Array<InProductNotification>;
  success: Scalars['Boolean'];
};

export type InitializeBillingInput = {
  address?: InputMaybe<AddressInput>;
  billingContact?: InputMaybe<UserInput>;
  email: Scalars['String'];
  legalName: Scalars['String'];
  locationLicenses?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<SubscriptionInput>;
  userLicenses?: InputMaybe<Scalars['Int']>;
};

export type IntArrayInput = {
  value: Array<Scalars['Int']>;
};

export type IntValue = {
  value: Scalars['Int'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDue: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNumber: Scalars['String'];
  invoicePdf?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['InvoiceStatus'];
  targetDate?: Maybe<Scalars['DateTime']>;
};

export type LanguageArrayInput = {
  value: Array<Scalars['Language']>;
};

export type LanguageInput = {
  value: Scalars['Language'];
};

export type LanguageValue = {
  value: Scalars['Language'];
};

export enum LeaderboardWindow {
  AllTime = 'ALL_TIME',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export type Lesson = {
  __typename?: 'Lesson';
  aiLessonGeneration?: Maybe<AiLessonGeneration>;
  courseVersion: CourseVersion;
  id: Scalars['Int'];
  index: Scalars['Float'];
  name: Scalars['String'];
  prompts: Array<Prompt>;
  uuid: Scalars['UUID'];
};

export type LessonInstance = {
  __typename?: 'LessonInstance';
  completedAt?: Maybe<Scalars['DateTime']>;
  courseInstance: CourseInstance;
  id: Scalars['UUID'];
  lesson: Lesson;
  percentComplete: Scalars['Float'];
  promptInstances: Array<PromptInstance>;
  startedAt?: Maybe<Scalars['DateTime']>;
  user: Employee;
};

export type LessonObjectInput = {
  name: Scalars['String'];
  prompts: Array<PromptObjectInput>;
  uuid: Scalars['UUID'];
};

export type LibraryItem = {
  __typename?: 'LibraryItem';
  accessAutomation?: Maybe<Automation>;
  accessCount: Scalars['Int'];
  aiGenerationFeedback?: Maybe<AiGenerationFeedback>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Employee>;
  assignedCompletedCount: Scalars['Int'];
  assignedCount: Scalars['Int'];
  assignmentAutomation?: Maybe<Automation>;
  completedCount: Scalars['Int'];
  contentTagMemberships: Array<ContentTagMembership>;
  course?: Maybe<Course>;
  coverImage?: Maybe<CoverImage>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Employee>;
  description: TranslationSet;
  dueDate?: Maybe<Scalars['Date']>;
  dueDateDays?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<Employee>;
  name: TranslationSet;
  onCompletionAssignmentRules: Array<OnCompletionAssignmentRule>;
  openedAt?: Maybe<Scalars['DateTime']>;
  org?: Maybe<Org>;
  orgPremiumContentConfig?: Maybe<OrgPremiumContentConfig>;
  orgSharpConfig?: Maybe<OrgSharpConfig>;
  path?: Maybe<Path>;
  publishState?: Maybe<PublishState>;
  qrCode?: Maybe<ContentQrCode>;
  sharingPermissions: ContentSharingPermission;
  skill?: Maybe<Skill>;
  trainingResource?: Maybe<TrainingResource>;
  type: LibraryItemType;
};

export enum LibraryItemContentType {
  Course = 'COURSE',
  Path = 'PATH',
  Skill = 'SKILL',
  TrainingResource = 'TRAINING_RESOURCE'
}

export enum LibraryItemPublishedStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  NeedsSetup = 'NEEDS_SETUP',
  Published = 'PUBLISHED',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES'
}

export enum LibraryItemType {
  Course = 'COURSE',
  Path = 'PATH',
  PremiumContent = 'PREMIUM_CONTENT',
  Sharp = 'SHARP',
  Skill = 'SKILL',
  TrainingResource = 'TRAINING_RESOURCE'
}

export type LibraryItemTypeInput = {
  value: LibraryItemType;
};

export type LibraryItemTypeListInput = {
  value: Array<LibraryItemType>;
};

export type LibraryItems = PaginatedList & {
  __typename?: 'LibraryItems';
  objects: Array<LibraryItem>;
  totalCount: Scalars['Int'];
};

export type LibraryItemsMutationResult = MutationResult & {
  __typename?: 'LibraryItemsMutationResult';
  error?: Maybe<Error>;
  objects?: Maybe<LibraryItems>;
  success: Scalars['Boolean'];
};

export enum LicenseType {
  PerLocation = 'PER_LOCATION',
  PerUser = 'PER_USER'
}

export type LinkUserToHrisEmployeeByDateOfBirthInput = {
  day: Scalars['Int'];
  month: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  createdBy?: Maybe<Employee>;
  employees: Array<Employee>;
  id: Scalars['Int'];
  lastUpdatedBy?: Maybe<Employee>;
  locationAutomations: Array<Automation>;
  locationGroupMemberships: Array<LocationGroupMembership>;
  name: Scalars['String'];
  openedAt?: Maybe<Scalars['DateTime']>;
  org: Org;
  signupCode?: Maybe<SignupCode>;
  userCount: Scalars['Int'];
};


export type LocationUserCountArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type LocationDataPoint = {
  __typename?: 'LocationDataPoint';
  data: Scalars['Int'];
  locationName: Scalars['String'];
};

export type LocationFilters = {
  __typename?: 'LocationFilters';
  locationGroups?: Maybe<Array<LocationGroup>>;
  locations?: Maybe<Array<Location>>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  automations: Array<Automation>;
  brandDetails?: Maybe<BrandDetails>;
  childLocationGroups: Array<LocationGroup>;
  id: Scalars['UUID'];
  locationCount: Scalars['Int'];
  locationMemberships: Array<LocationGroupMembership>;
  name: Scalars['String'];
  openedAt?: Maybe<Scalars['DateTime']>;
  parentLocationGroup?: Maybe<LocationGroup>;
  type: LocationGroupType;
  userCount: Scalars['Int'];
};


export type LocationGroupUserCountArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type LocationGroupInput = {
  brandDetails?: InputMaybe<BrandDetailsInput>;
  childLocationGroupIds?: Array<Scalars['UUID']>;
  locationIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  parentLocationGroupId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<LocationGroupType>;
};

export type LocationGroupMembership = {
  __typename?: 'LocationGroupMembership';
  id: Scalars['UUID'];
  location: Location;
  locationGroup: LocationGroup;
};

export type LocationGroupMutationResult = MutationResult & {
  __typename?: 'LocationGroupMutationResult';
  error?: Maybe<Error>;
  locationGroup?: Maybe<LocationGroup>;
  success: Scalars['Boolean'];
};

export enum LocationGroupType {
  Brand = 'BRAND',
  Franchise = 'FRANCHISE',
  Region = 'REGION'
}

export type LocationGroups = PaginatedList & {
  __typename?: 'LocationGroups';
  objects: Array<LocationGroup>;
  totalCount: Scalars['Int'];
};

export type LocationGroupsInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  locationGroupsChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  locationGroupsDirectChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
  types?: InputMaybe<Array<LocationGroupType>>;
};

export type LocationLeaderboard = PaginatedList & {
  __typename?: 'LocationLeaderboard';
  objects: Array<LocationLeaderboardItem>;
  totalCount: Scalars['Int'];
};

export type LocationLeaderboardInput = {
  sort?: InputMaybe<SortInput>;
  window?: InputMaybe<LeaderboardWindow>;
};

export type LocationLeaderboardItem = {
  __typename?: 'LocationLeaderboardItem';
  assignmentCompletionPercentage?: Maybe<Scalars['Float']>;
  assignmentsCompleted: Scalars['Int'];
  averageAccuracy?: Maybe<Scalars['Float']>;
  id: Scalars['UUID'];
  locationId: Scalars['Int'];
  locationName: Scalars['String'];
  pastDueAssignments: Scalars['Int'];
  rank: Scalars['Int'];
};

export type LocationMutationResult = MutationResult & {
  __typename?: 'LocationMutationResult';
  error?: Maybe<Error>;
  location?: Maybe<Location>;
  success: Scalars['Boolean'];
};

export type Locations = PaginatedList & {
  __typename?: 'Locations';
  objects: Array<Location>;
  totalCount: Scalars['Int'];
};

export type LocationsInput = {
  hasHrisLink?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  locationGroupsChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  locationGroupsDirectChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type LocationsMutationResult = MutationResult & {
  __typename?: 'LocationsMutationResult';
  error?: Maybe<Error>;
  locations?: Maybe<Array<Location>>;
  success: Scalars['Boolean'];
};

export enum McOption {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export type MimeType = {
  __typename?: 'MIMEType';
  ext: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ManagerLeaderboard = PaginatedList & {
  __typename?: 'ManagerLeaderboard';
  objects: Array<ManagerLeaderboardItem>;
  totalCount: Scalars['Int'];
};

export type ManagerLeaderboardInput = {
  sort?: InputMaybe<SortInput>;
  window?: InputMaybe<ManagerLeaderboardWindow>;
};

export type ManagerLeaderboardItem = {
  __typename?: 'ManagerLeaderboardItem';
  assignmentsCreated: Scalars['Int'];
  id: Scalars['UUID'];
  managerFirstName: Scalars['String'];
  managerId: Scalars['Int'];
  managerLastName: Scalars['String'];
  managerLocations?: Maybe<Array<Scalars['String']>>;
  managerName: Scalars['String'];
  managerRoles?: Maybe<Array<Scalars['String']>>;
  rank: Scalars['Int'];
  skillsVerified: Scalars['Int'];
  teamAssignmentCompletionPercentage?: Maybe<Scalars['Float']>;
  teamAssignmentsCompleted: Scalars['Int'];
  teamAverageAccuracy?: Maybe<Scalars['Float']>;
};

export enum ManagerLeaderboardWindow {
  AllTime = 'ALL_TIME',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export type Me = {
  __typename?: 'Me';
  availableOrgs: Orgs;
  emailPreferences: EmailPreferences;
  featureFlags: Array<FeatureFlag>;
  isStaff: Scalars['Boolean'];
  user: Employee;
};


export type MeAvailableOrgsArgs = {
  input?: InputMaybe<AvailableOrgsInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  description?: Maybe<TranslationSet>;
  hideVideoControls: Scalars['Boolean'];
  id: Scalars['Int'];
  imageUrls?: Maybe<ImageUrls>;
  loopVideo: Scalars['Boolean'];
  mediaUrls: TranslationSet;
  name?: Maybe<TranslationSet>;
  org: Org;
  subtitleTracks: Array<SubtitleTrack>;
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  trainingResource?: Maybe<TrainingResource>;
  unoptimizedUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MediaInput = {
  description?: InputMaybe<TranslationSetInput>;
  mediaUrls: TranslationSetInput;
  name?: InputMaybe<TranslationSetInput>;
};

export type MediaMutationResult = MutationResult & {
  __typename?: 'MediaMutationResult';
  error?: Maybe<Error>;
  media?: Maybe<Media>;
  success: Scalars['Boolean'];
};

export type MediaObjectInput = {
  description?: InputMaybe<TranslationSetObjectInput>;
  mediaUrls: TranslationSetObjectInput;
  name?: InputMaybe<TranslationSetObjectInput>;
};

export type MediaSuggestion = {
  __typename?: 'MediaSuggestion';
  id: Scalars['UUID'];
  media: Media;
  prompt: Prompt;
};

export enum MergeLinkedAccountStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  RelinkNeeded = 'RELINK_NEEDED'
}

export type MergeUsersInput = {
  fromUserId: Scalars['Int'];
  intoUserId: Scalars['Int'];
};

export type MessageBlast = {
  __typename?: 'MessageBlast';
  createdAt: Scalars['DateTime'];
  draft: Scalars['Boolean'];
  fromOpus: Scalars['Boolean'];
  fromOrg: Scalars['Boolean'];
  id: Scalars['Int'];
  mediaUrls?: Maybe<TranslationSet>;
  messageBlastMedias?: Maybe<Array<MessageBlastMedia>>;
  messageText: TranslationSet;
  notificationSchedule?: Maybe<BlastNotificationSchedule>;
  readCount?: Maybe<Scalars['Int']>;
  recipientCount: Scalars['Int'];
  recipientGroups: Array<BlastRecipientGroup>;
  recipients: Array<BlastRecipient>;
  sendAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  sentBy: Employee;
  sentRecords: Array<BlastSentRecord>;
  subject?: Maybe<TranslationSet>;
  userSelection: UserSelection;
};

export type MessageBlastInput = {
  draft?: InputMaybe<Scalars['Boolean']>;
  fromOrg?: InputMaybe<Scalars['Boolean']>;
  mediaUrls?: InputMaybe<TranslationSetInput>;
  messageBlastMedias?: InputMaybe<Array<MessageBlastMediaInput>>;
  messageText: TranslationSetInput;
  recipientGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  recipientIds?: InputMaybe<Array<Scalars['Int']>>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  sendUntil?: InputMaybe<Scalars['DateTime']>;
  subject?: InputMaybe<TranslationSetInput>;
  userSelection?: InputMaybe<UserSelectionInput>;
};

export type MessageBlastMedia = {
  __typename?: 'MessageBlastMedia';
  id: Scalars['Int'];
  media: Media;
  pinned: Scalars['Boolean'];
};

export type MessageBlastMediaInput = {
  id: Scalars['Int'];
  pinned: Scalars['Boolean'];
};

export type MessageBlastMutationResult = MutationResult & {
  __typename?: 'MessageBlastMutationResult';
  error?: Maybe<Error>;
  messageBlast?: Maybe<MessageBlast>;
  success: Scalars['Boolean'];
};

export type MessageBlasts = PaginatedList & {
  __typename?: 'MessageBlasts';
  objects: Array<MessageBlast>;
  totalCount: Scalars['Int'];
};

export type MessageBlastsInput = {
  draft?: InputMaybe<BooleanValue>;
  ids?: InputMaybe<IntArrayInput>;
  sentAfter?: InputMaybe<DateTimeValue>;
};

export enum MessageReaction {
  Heart = 'HEART',
  Question = 'QUESTION',
  Smile = 'SMILE',
  ThumbsDown = 'THUMBS_DOWN',
  ThumbsUp = 'THUMBS_UP'
}

export type MessageReadsGridInput = {
  locationIds: IntArrayInput;
  messageBlastIds: IntArrayInput;
};

export enum MobilePlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type MoveLessonActionInput = {
  lessonUuid: Scalars['UUID'];
  position: Scalars['Int'];
};

export type MovePathContentMembershipActionInput = {
  fractionPosition: Scalars['Float'];
  pathContentMembershipUuid: Scalars['UUID'];
};

export type MovePromptActionInput = {
  lessonUuid: Scalars['UUID'];
  position: Scalars['Int'];
  promptUuid: Scalars['UUID'];
};

export type MoveResponseOptionActionInput = {
  position: Scalars['Int'];
  promptUuid: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type MoveStepActionInput = {
  id: Scalars['UUID'];
  position: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AIAddEmojisToText: StringMutationResult;
  AISuggestReadingLevelChangesToText: StringMutationResult;
  AISuggestSpellingAndGrammarChangesToText: StringMutationResult;
  accessContentViaQRCode: AccessContentMutationResult;
  addContentToLibrary: CatalogMutationResult;
  addCourseToLibrary: CatalogMutationResult;
  addCoursesToLibrary: CatalogMutationResult;
  addUsersToLocations: LocationsMutationResult;
  addUsersToRoles: RolesMutationResult;
  applyBuilderActions: ApplyBuilderActionsMutationResult;
  applyCourseProgress: GenericMutationResult;
  archiveContent: BaseMutationResult;
  archiveLibraryItems: LibraryItemsMutationResult;
  archivePath: PathMutationResult;
  archiveSkill: SkillMutationResult;
  archiveTrainingResource: TrainingResourceMutationResult;
  assignLibraryItems: BaseMutationResult;
  attributeUnsplashImageSelection: GenericMutationResult;
  bulkCreateTrainingResources: TrainingResourcesMutationResult;
  bulkUpdateLibraryItemAutomations: AutomationMutationResult;
  completeTraineeSignup: BaseMutationResult;
  copyCourse: CourseMutationResult;
  copyPath: PathMutationResult;
  copySkill: SkillMutationResult;
  createAICourse: CourseMutationResult;
  createAIFeedback: BaseMutationResult;
  createAIModule: PathMutationResult;
  createBlastReadRecord: MessageBlastMutationResult;
  createCheckoutSession: StringMutationResult;
  createClockInConfirmation: EmployeeMutationResult;
  createComment: CommentThreadMutationResult;
  createComplianceAttemptImports: BaseMutationResult;
  createContentTagMemberships: ContentTagMembershipMutationResult;
  createCourse: CourseMutationResult;
  createCourseFeedback: CourseInstanceMutationResult;
  createDashboardFeedback: GenericMutationResult;
  createExpoPushToken: GenericMutationResult;
  createFeedback: GenericMutationResult;
  createHrisCsvUpload: HrisCsvUploadMutationResult;
  createHrisEmployeeLink: EmployeeMutationResult;
  createHrisLocationLink: GenericMutationResult;
  createHrisTeamLink: GenericMutationResult;
  createLocation: LocationMutationResult;
  createLocationGroup: LocationGroupMutationResult;
  createMedia: MediaMutationResult;
  createMessageBlast: MessageBlastMutationResult;
  createOneOffCheckInDelegations: BaseMutationResult;
  createOngoingCheckInDelegations: BaseMutationResult;
  createOrg: OrgMutationResult;
  createOrgSharpConfig: OrgSharpConfigMutationResult;
  createPath: PathMutationResult;
  createPromptCommentThread: CommentThreadMutationResult;
  createReportView: ReportViewMutationResult;
  createRole: RoleMutationResult;
  createRoleGroup: RoleGroupMutationResult;
  createScheduledReportEmail: BaseMutationResult;
  createSkill: SkillMutationResult;
  createStillEmployedConfirmation: BaseMutationResult;
  createTag: TagMutationResult;
  createTrainingResource: TrainingResourceMutationResult;
  createTrainingResourceV2: TrainingResourceMutationResult;
  createTrainingSession: EmployeeMutationResult;
  createTrainingSessionEvent: GenericMutationResult;
  createUser: UserMutationResult;
  createUserSharingPermissions: UserSharingPermissionMutationResult;
  deleteComment: CommentThreadMutationResult;
  deleteCommentThread: CommentThreadMutationResult;
  deleteComplianceAttempts: BaseMutationResult;
  deleteContentTagMemberships: ContentTagMembershipMutationResult;
  deleteExpoPushTokens: GenerateLinkTokenMutationResult;
  deleteLocation: LocationMutationResult;
  deleteLocationGroups: BaseMutationResult;
  deleteLocations: BaseMutationResult;
  deleteMessageBlast: MessageBlastMutationResult;
  deleteMessageBlastNotificationSchedule: MessageBlastMutationResult;
  deleteOnCompletionAssignmentRules: BaseMutationResult;
  deleteOneOffCheckInDelegations: BaseMutationResult;
  deleteOngoingCheckInDelegations: BaseMutationResult;
  deleteOrgPremiumContentConfigs: BaseMutationResult;
  deletePath: PathMutationResult;
  deleteReport: BaseMutationResult;
  deleteReportView: BaseMutationResult;
  deleteRole: RoleMutationResult;
  deleteRoleGroups: BaseMutationResult;
  deleteRoles: BaseMutationResult;
  deleteScheduledReportEmail: BaseMutationResult;
  deleteTag: TagMutationResult;
  deleteTags: TagMutationResult;
  /** @deprecated Field no longer supported */
  diffAssignmentRulesChange: DiffAssignmentRulesMutationResult;
  diffAutomationRulesChange: DiffAutomationRulesMutationResult;
  editComment: CommentThreadMutationResult;
  editPromptWithAI: AiRequestMutationResult;
  employeeUpdateEmployee: UpdateEmployeeResult;
  generateBuilderActionsFromSuggestion: GenerateBuilderActionsFromSuggestionMutationResult;
  generateCoursePromptsResponsesCSV: StringMutationResult;
  generateMergeAccountToken: BaseMutationResult;
  generateMergeLinkToken: GenerateLinkTokenMutationResult;
  getAIFeedbackOnGradedFreeResponseAttempt: Scalars['Boolean'];
  getReportFieldFrequencies: ReportMutationResult;
  giveAccessToLibraryItems: BaseMutationResult;
  gradeCourseVersion: GradeCourseVersionMutationResult;
  initializeBilling: OrgMutationResult;
  initializeCourseInstance: CourseInstanceMutationResult;
  linkUserToHrisEmployeeByDateOfBirth: HrisEmployeeMutationResult;
  logout: BaseMutationResult;
  markAssignmentsComplete: AssignmentsMutationResult;
  markInProductNotificationsAsRead: InProductNotificationsMutationResult;
  markPushNotificationClicked: GenericMutationResult;
  mergeUsers: EmployeeMutationResult;
  openedCourse: GenericMutationResult;
  openedLocation: GenericMutationResult;
  openedLocationGroup: GenericMutationResult;
  openedPath: GenericMutationResult;
  openedRole: GenericMutationResult;
  openedRoleGroup: GenericMutationResult;
  openedSkill: GenericMutationResult;
  openedTrainingResource: TrainingResourceMutationResult;
  openedTrainingResourceInstance: TrainingResourceInstanceMutationResult;
  openedUser: GenericMutationResult;
  pauseTraining: PauseTrainingResult;
  publishCourse: CourseMutationResult;
  publishPath: PathMutationResult;
  publishPathV2: PathMutationResult;
  publishSkill: SkillMutationResult;
  purchaseCredits: OrgMutationResult;
  reactivateEmployee: EmployeeMutationResult;
  reactivateEmployees: EmployeesMutationResult;
  removeActiveClient: BaseMutationResult;
  removeAssignments: BaseMutationResult;
  removeEmployee: RemoveEmployeeResult;
  removeEmployees: BaseMutationResult;
  removeLibraryAccess: BaseMutationResult;
  removeUserSharingPermission: UserSharingPermissionMutationResult;
  removeUsersFromLocations: LocationsMutationResult;
  removeUsersFromRoles: RolesMutationResult;
  replaceOnCompletionAssignmentRuleSets: ReplaceOnCompletionAssignmentRuleSetsMutationResult;
  resendBlastNotification: MessageBlastMutationResult;
  resetDemoOrg: BaseMutationResult;
  resolveAIRequest: AiRequestMutationResult;
  revertCourseToPriorVersion: CourseMutationResult;
  runReport: ReportMutationResult;
  saveReport: SavedReportMutationResult;
  scrapeAIBrandContextFoundingStory: StringMutationResult;
  scrapeAIBrandContextMissionStatement: StringMutationResult;
  scrapeAIBrandContextSells: StringMutationResult;
  /** @deprecated Field no longer supported */
  searchUnsplashImages: SearchUnsplashMutationResult;
  sendCoursePreviewToPhone: GenericMutationResult;
  setActiveClient: ActiveClientMutationResult;
  /** @deprecated Field no longer supported */
  setEmployeeGroups: EmployeeMutationResult;
  setEmployeeLocations: EmployeeMutationResult;
  setEmployeeRoles: EmployeeMutationResult;
  suggestAIBlocks: SuggestAiBlocksMutationResult;
  textToSpeech: StringMutationResult;
  trackEvent?: Maybe<Scalars['Boolean']>;
  translateText: TranslatedTextMutationResult;
  unpauseTraining: EmployeesMutationResult;
  updateAIOrgContext: AiOrgContextMutationResult;
  updateAdminEmailPreferences: UserMutationResult;
  updateAutomation: AutomationMutationResult;
  /** @deprecated Field no longer supported */
  updateBillingEmail: OrgMutationResult;
  updateBlastNotificationSchedule: MessageBlastMutationResult;
  updateBlastSentRecord: BaseMutationResult;
  updateCertificateEndorser: OrgMutationResult;
  updateClockInConfirmationSettings: OrgMutationResult;
  updateContentSharingPermission?: Maybe<ContentSharingPermissionMutationResult>;
  updateCourse: CourseMutationResult;
  updateEmployeeFeatureFlags: BaseMutationResult;
  updateGlossarySet: OrgMutationResult;
  updateHrisCsvUpload: HrisCsvUploadMutationResult;
  updateLocation: LocationMutationResult;
  updateLocationGroup: LocationGroupMutationResult;
  updateMessageBlast: MessageBlastMutationResult;
  updateMyOrg: BaseMutationResult;
  updateOrbCustomer: OrgMutationResult;
  updateOrg: OrgMutationResult;
  updateOrgBrandColor: OrgMutationResult;
  updateOrgHrisConfig: HrisConfigMutationResult;
  updateOrgLogo: OrgMutationResult;
  updateOrgPremiumContentConfig: BaseMutationResult;
  updateOrgSharpConfig: OrgSharpConfigMutationResult;
  updatePathSettings: PathMutationResult;
  updateReportView: ReportViewMutationResult;
  updateRole: RoleMutationResult;
  updateRoleGroup: RoleGroupMutationResult;
  updateRoleLevels: RolesMutationResult;
  updateSkillName: SkillMutationResult;
  updateSubtitles: MediaMutationResult;
  updateTag: TagMutationResult;
  updateTags: TagMutationResult;
  updateTrainingResource: TrainingResourceMutationResult;
  updateTranslationSet: TranslationSetMutationResult;
  updateUser: UserMutationResult;
  updateUserActionPermissionOverrides: BaseMutationResult;
  /** @deprecated Field no longer supported */
  updateUserActionPermissions: BaseMutationResult;
  updateUserSharingPermission: UserSharingPermissionMutationResult;
  uploadFile: FileUploadMutationResult;
  uploadFileList: FileListUploadMutationResult;
  uploadSCORMPackages: ScormPackageUploadMutationResult;
  verifySkill: SkillInstanceMutationResult;
};


export type MutationAiAddEmojisToTextArgs = {
  text: Scalars['String'];
};


export type MutationAiSuggestReadingLevelChangesToTextArgs = {
  text: Scalars['String'];
};


export type MutationAiSuggestSpellingAndGrammarChangesToTextArgs = {
  text: Scalars['String'];
};


export type MutationAccessContentViaQrCodeArgs = {
  input?: InputMaybe<AccessContentViaQrCodeInput>;
};


export type MutationAddContentToLibraryArgs = {
  input: AddContentToLibraryInput;
};


export type MutationAddCourseToLibraryArgs = {
  catalogCourseId: Scalars['Int'];
};


export type MutationAddCoursesToLibraryArgs = {
  catalogCourseIds: Array<Scalars['Int']>;
};


export type MutationAddUsersToLocationsArgs = {
  input: AddUsersToLocationsInput;
};


export type MutationAddUsersToRolesArgs = {
  input: AddUsersToRolesInput;
};


export type MutationApplyBuilderActionsArgs = {
  actions: Array<BuilderActionInput>;
};


export type MutationApplyCourseProgressArgs = {
  promptAttemptInput: CourseProgressPromptAttemptInput;
};


export type MutationArchiveContentArgs = {
  inputs: Array<ContentInput>;
};


export type MutationArchiveLibraryItemsArgs = {
  inputs: Array<Scalars['UUID']>;
};


export type MutationArchivePathArgs = {
  archive: Scalars['Boolean'];
  pathId: Scalars['Int'];
};


export type MutationArchiveSkillArgs = {
  archive: Scalars['Boolean'];
  skillId: Scalars['Int'];
};


export type MutationArchiveTrainingResourceArgs = {
  archive: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationAssignLibraryItemsArgs = {
  input?: InputMaybe<AssignLibraryItemsInput>;
};


export type MutationAttributeUnsplashImageSelectionArgs = {
  downloadUrl?: InputMaybe<Scalars['String']>;
};


export type MutationBulkCreateTrainingResourcesArgs = {
  inputs: Array<BulkCreateTrainingResourceInput>;
};


export type MutationBulkUpdateLibraryItemAutomationsArgs = {
  input: BulkUpdateLibraryItemAutomationsInput;
};


export type MutationCompleteTraineeSignupArgs = {
  input: TraineeSignupInput;
};


export type MutationCopyCourseArgs = {
  id: Scalars['Int'];
};


export type MutationCopyPathArgs = {
  duplicateChildContent?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};


export type MutationCopySkillArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAiCourseArgs = {
  input: AiCourseInput;
};


export type MutationCreateAiFeedbackArgs = {
  input: CreateAiFeedbackInput;
};


export type MutationCreateAiModuleArgs = {
  input: AiModuleInput;
};


export type MutationCreateBlastReadRecordArgs = {
  blastId: Scalars['Int'];
};


export type MutationCreateCheckoutSessionArgs = {
  billingAccountId: Scalars['UUID'];
};


export type MutationCreateCommentArgs = {
  comment: CommentInput;
  threadId: Scalars['String'];
};


export type MutationCreateComplianceAttemptImportsArgs = {
  input: ComplianceAttemptImportsInput;
};


export type MutationCreateContentTagMembershipsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<ContentTagMembershipInput>>>;
};


export type MutationCreateCourseArgs = {
  name: Scalars['String'];
};


export type MutationCreateCourseFeedbackArgs = {
  courseInstanceId: Scalars['Int'];
  input: CourseFeedbackInput;
};


export type MutationCreateDashboardFeedbackArgs = {
  input: DashboardFeedbackInput;
};


export type MutationCreateExpoPushTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  input: FeedbackInput;
};


export type MutationCreateHrisCsvUploadArgs = {
  csvUrl: Scalars['String'];
};


export type MutationCreateHrisEmployeeLinkArgs = {
  hrisEmployeeId: Scalars['Int'];
  traineeId: Scalars['Int'];
};


export type MutationCreateHrisLocationLinkArgs = {
  groupId: Scalars['Int'];
  hrisLocationId: Scalars['Int'];
};


export type MutationCreateHrisTeamLinkArgs = {
  groupId: Scalars['Int'];
  hrisTeamId: Scalars['Int'];
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateLocationGroupArgs = {
  input: LocationGroupInput;
};


export type MutationCreateMediaArgs = {
  input: MediaInput;
};


export type MutationCreateMessageBlastArgs = {
  input: MessageBlastInput;
};


export type MutationCreateOneOffCheckInDelegationsArgs = {
  facilitatorIds: Array<Scalars['Int']>;
  skillInstanceId: Scalars['UUID'];
};


export type MutationCreateOngoingCheckInDelegationsArgs = {
  facilitatorIds: Array<Scalars['Int']>;
  skillId: Scalars['Int'];
};


export type MutationCreateOrgArgs = {
  input?: InputMaybe<OrgInput>;
};


export type MutationCreatePathArgs = {
  input: PathInput;
};


export type MutationCreatePromptCommentThreadArgs = {
  comment: CommentInput;
  promptUuid: Scalars['String'];
};


export type MutationCreateReportViewArgs = {
  input: ReportViewInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateRoleGroupArgs = {
  input: RoleGroupInput;
};


export type MutationCreateScheduledReportEmailArgs = {
  input: ScheduledReportInput;
};


export type MutationCreateSkillArgs = {
  input: SkillInput;
};


export type MutationCreateStillEmployedConfirmationArgs = {
  input?: InputMaybe<StillEmployedConfirmationInput>;
};


export type MutationCreateTagArgs = {
  id: Scalars['String'];
  input: TagInput;
};


export type MutationCreateTrainingResourceArgs = {
  input: CreateTrainingResourceInput;
};


export type MutationCreateTrainingResourceV2Args = {
  input: CreateTrainingResourceInput;
};


export type MutationCreateTrainingSessionEventArgs = {
  input: TrainingSessionEventInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserSharingPermissionsArgs = {
  input: CreateUserSharingPermissionsInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationDeleteCommentThreadArgs = {
  threadId: Scalars['String'];
};


export type MutationDeleteComplianceAttemptsArgs = {
  input: DeleteComplianceAttemptsInput;
};


export type MutationDeleteContentTagMembershipsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<ContentTagMembershipInput>>>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLocationGroupsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteLocationsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteMessageBlastArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMessageBlastNotificationScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOnCompletionAssignmentRulesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteOneOffCheckInDelegationsArgs = {
  facilitatorIds: Array<Scalars['Int']>;
  skillInstanceId: Scalars['UUID'];
};


export type MutationDeleteOngoingCheckInDelegationsArgs = {
  facilitatorIds: Array<Scalars['Int']>;
  skillId: Scalars['Int'];
};


export type MutationDeleteOrgPremiumContentConfigsArgs = {
  input: DeleteOrgPremiumContentConfigsInput;
};


export type MutationDeletePathArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReportArgs = {
  reportId: Scalars['String'];
};


export type MutationDeleteReportViewArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoleGroupsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteRolesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteScheduledReportEmailArgs = {
  reportId: Scalars['UUID'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDiffAssignmentRulesChangeArgs = {
  input: DiffAssignmentRulesChangeInput;
};


export type MutationDiffAutomationRulesChangeArgs = {
  input: DiffAutomationRulesChangeInput;
};


export type MutationEditCommentArgs = {
  comment?: InputMaybe<CommentInput>;
  commentId: Scalars['String'];
};


export type MutationEditPromptWithAiArgs = {
  input: EditPromptWithAiInput;
};


export type MutationEmployeeUpdateEmployeeArgs = {
  input: UpdateEmployee;
};


export type MutationGenerateBuilderActionsFromSuggestionArgs = {
  id: Scalars['UUID'];
};


export type MutationGenerateCoursePromptsResponsesCsvArgs = {
  input?: InputMaybe<PromptResponsesCsvInput>;
};


export type MutationGenerateMergeAccountTokenArgs = {
  hrisIntegrationId?: InputMaybe<Scalars['String']>;
  publicToken: Scalars['String'];
};


export type MutationGenerateMergeLinkTokenArgs = {
  integrationId?: InputMaybe<Scalars['UUID']>;
};


export type MutationGetAiFeedbackOnGradedFreeResponseAttemptArgs = {
  input: GradedFreeResponseInput;
};


export type MutationGetReportFieldFrequenciesArgs = {
  fieldId: Scalars['UUID'];
  input: ReportInput;
};


export type MutationGiveAccessToLibraryItemsArgs = {
  input?: InputMaybe<GiveAccessToLibraryItemsInput>;
};


export type MutationGradeCourseVersionArgs = {
  input: GradeCourseVersionInput;
};


export type MutationInitializeBillingArgs = {
  input: InitializeBillingInput;
};


export type MutationInitializeCourseInstanceArgs = {
  id: Scalars['Int'];
};


export type MutationLinkUserToHrisEmployeeByDateOfBirthArgs = {
  input: LinkUserToHrisEmployeeByDateOfBirthInput;
};


export type MutationMarkAssignmentsCompleteArgs = {
  assignmentIds: Array<Scalars['String']>;
  reason: Scalars['String'];
};


export type MutationMarkInProductNotificationsAsReadArgs = {
  inProductNotificationIds: Array<Scalars['String']>;
};


export type MutationMarkPushNotificationClickedArgs = {
  pushNotificationId: Scalars['Int'];
};


export type MutationMergeUsersArgs = {
  input?: InputMaybe<MergeUsersInput>;
};


export type MutationOpenedCourseArgs = {
  id: Scalars['Int'];
};


export type MutationOpenedLocationArgs = {
  id: Scalars['Int'];
};


export type MutationOpenedLocationGroupArgs = {
  id: Scalars['String'];
};


export type MutationOpenedPathArgs = {
  id: Scalars['Int'];
};


export type MutationOpenedRoleArgs = {
  id: Scalars['Int'];
};


export type MutationOpenedRoleGroupArgs = {
  id: Scalars['String'];
};


export type MutationOpenedSkillArgs = {
  id: Scalars['Int'];
};


export type MutationOpenedTrainingResourceArgs = {
  id: Scalars['String'];
};


export type MutationOpenedTrainingResourceInstanceArgs = {
  id: Scalars['String'];
};


export type MutationOpenedUserArgs = {
  id: Scalars['Int'];
};


export type MutationPauseTrainingArgs = {
  employeeId: Scalars['Int'];
};


export type MutationPublishCourseArgs = {
  courseId: Scalars['Int'];
  input?: InputMaybe<PublishCourseInput>;
};


export type MutationPublishPathArgs = {
  pathId?: InputMaybe<Scalars['Int']>;
};


export type MutationPublishPathV2Args = {
  pathId?: InputMaybe<Scalars['Int']>;
};


export type MutationPublishSkillArgs = {
  skillId: Scalars['Int'];
};


export type MutationPurchaseCreditsArgs = {
  input: PurchaseCreditsInput;
};


export type MutationReactivateEmployeeArgs = {
  employeeId: Scalars['Int'];
};


export type MutationReactivateEmployeesArgs = {
  employeeIds: Array<Scalars['Int']>;
};


export type MutationRemoveActiveClientArgs = {
  clientId: Scalars['String'];
};


export type MutationRemoveAssignmentsArgs = {
  input?: InputMaybe<RemoveAssignmentsInput>;
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveEmployeesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationRemoveLibraryAccessArgs = {
  input?: InputMaybe<RemoveLibraryAccessInput>;
};


export type MutationRemoveUserSharingPermissionArgs = {
  input: RemoveUserSharingPermissionInput;
};


export type MutationRemoveUsersFromLocationsArgs = {
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  userIds: Array<Scalars['Int']>;
};


export type MutationRemoveUsersFromRolesArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  userIds: Array<Scalars['Int']>;
};


export type MutationReplaceOnCompletionAssignmentRuleSetsArgs = {
  input: ReplaceOnCompletionAssignmentRuleSetsInput;
};


export type MutationResendBlastNotificationArgs = {
  id: Scalars['Int'];
  input?: InputMaybe<BlastNotificationScheduleInput>;
};


export type MutationResolveAiRequestArgs = {
  input: ResolveAiRequestInput;
};


export type MutationRevertCourseToPriorVersionArgs = {
  courseId: Scalars['Int'];
  courseVersionId: Scalars['Int'];
};


export type MutationRunReportArgs = {
  input: ReportInput;
};


export type MutationSaveReportArgs = {
  input?: InputMaybe<SaveReportInput>;
};


export type MutationScrapeAiBrandContextFoundingStoryArgs = {
  brandDetailsId: Scalars['UUID'];
};


export type MutationScrapeAiBrandContextMissionStatementArgs = {
  brandDetailsId: Scalars['UUID'];
};


export type MutationScrapeAiBrandContextSellsArgs = {
  brandDetailsId: Scalars['UUID'];
};


export type MutationSearchUnsplashImagesArgs = {
  orientation?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  searchText: Scalars['String'];
};


export type MutationSendCoursePreviewToPhoneArgs = {
  courseId: Scalars['Int'];
};


export type MutationSetActiveClientArgs = {
  input: SetActiveClientInput;
};


export type MutationSetEmployeeGroupsArgs = {
  employeeId: Scalars['Int'];
  groupIds: Array<Scalars['Int']>;
  groupType?: InputMaybe<GroupType>;
};


export type MutationSetEmployeeLocationsArgs = {
  employeeId: Scalars['Int'];
  locationIds: Array<Scalars['Int']>;
};


export type MutationSetEmployeeRolesArgs = {
  employeeId: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
};


export type MutationSuggestAiBlocksArgs = {
  input: SuggestAiBlocksInput;
};


export type MutationTextToSpeechArgs = {
  input: TextToSpeechInput;
};


export type MutationTrackEventArgs = {
  app: Scalars['String'];
  label: Scalars['String'];
  payload?: InputMaybe<Scalars['JSON']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationTranslateTextArgs = {
  dontUseGlossary?: InputMaybe<Scalars['Boolean']>;
  targetLanguage: Scalars['Language'];
  text: Array<Scalars['String']>;
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUnpauseTrainingArgs = {
  employeeIds: Array<Scalars['Int']>;
};


export type MutationUpdateAiOrgContextArgs = {
  updateAIOrgContextInput: UpdateAiOrgContextInput;
};


export type MutationUpdateAdminEmailPreferencesArgs = {
  enabled: Scalars['Boolean'];
};


export type MutationUpdateAutomationArgs = {
  id: Scalars['Int'];
  input: AutomationInput;
};


export type MutationUpdateBillingEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateBlastNotificationScheduleArgs = {
  id: Scalars['Int'];
  input?: InputMaybe<BlastNotificationScheduleInput>;
};


export type MutationUpdateBlastSentRecordArgs = {
  input: BlastSentRecordInput;
};


export type MutationUpdateCertificateEndorserArgs = {
  name: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateClockInConfirmationSettingsArgs = {
  isEnabled: Scalars['Boolean'];
  setting: ClockInConfirmationAction;
};


export type MutationUpdateContentSharingPermissionArgs = {
  input: UpdateContentSharingPermissionInput;
};


export type MutationUpdateCourseArgs = {
  courseId: Scalars['Int'];
  input: UpdateCourseInput;
};


export type MutationUpdateEmployeeFeatureFlagsArgs = {
  input: UpdateEmployeeFeatureFlagsInput;
};


export type MutationUpdateGlossarySetArgs = {
  input: GlossarySetInput;
};


export type MutationUpdateHrisCsvUploadArgs = {
  input: UpdateHrisCsvUploadInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['Int'];
  input: UpdateLocationInput;
};


export type MutationUpdateLocationGroupArgs = {
  id: Scalars['String'];
  input: LocationGroupInput;
};


export type MutationUpdateMessageBlastArgs = {
  id: Scalars['Int'];
  input: MessageBlastInput;
};


export type MutationUpdateMyOrgArgs = {
  orgId: Scalars['Int'];
};


export type MutationUpdateOrbCustomerArgs = {
  input: UpdateOrbCustomerInput;
};


export type MutationUpdateOrgArgs = {
  input: OrgInput;
  orgId: Scalars['Int'];
};


export type MutationUpdateOrgBrandColorArgs = {
  brandColor: Scalars['String'];
};


export type MutationUpdateOrgHrisConfigArgs = {
  input: OrgHrisConfigInput;
};


export type MutationUpdateOrgLogoArgs = {
  logoUrl: Scalars['String'];
};


export type MutationUpdateOrgPremiumContentConfigArgs = {
  input: UpdateOrgPremiumContentConfigInput;
};


export type MutationUpdateOrgSharpConfigArgs = {
  input: UpdateOrgSharpConfigInput;
};


export type MutationUpdatePathSettingsArgs = {
  input: PathSettingsInput;
  pathId: Scalars['Int'];
};


export type MutationUpdateReportViewArgs = {
  id: Scalars['UUID'];
  input: ReportViewInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['Int'];
  input: UpdateRoleInput;
};


export type MutationUpdateRoleGroupArgs = {
  id: Scalars['String'];
  input: RoleGroupInput;
};


export type MutationUpdateRoleLevelsArgs = {
  input: UpdateRoleLevelsInput;
};


export type MutationUpdateSkillNameArgs = {
  name: Scalars['String'];
  skillId: Scalars['Int'];
};


export type MutationUpdateSubtitlesArgs = {
  input: UpdateSubtitlesInput;
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  input: TagInput;
};


export type MutationUpdateTagsArgs = {
  input: UpdateTagsInput;
};


export type MutationUpdateTrainingResourceArgs = {
  input: UpdateTrainingResourceInput;
  trainingResourceId: Scalars['String'];
};


export type MutationUpdateTranslationSetArgs = {
  input: TranslationSetInput;
  uuid: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  input: UpdateUserInput;
};


export type MutationUpdateUserActionPermissionOverridesArgs = {
  input: UserActionPermissionsInput;
  userId: Scalars['Int'];
};


export type MutationUpdateUserActionPermissionsArgs = {
  input: UserActionPermissionsInput;
};


export type MutationUpdateUserSharingPermissionArgs = {
  input: UpdateUserSharingPermissionInput;
};


export type MutationUploadFileArgs = {
  mediaFile: Scalars['Upload'];
};


export type MutationUploadFileListArgs = {
  mediaFiles: Array<Scalars['Upload']>;
};


export type MutationUploadScormPackagesArgs = {
  packages: Array<Scalars['Upload']>;
};


export type MutationVerifySkillArgs = {
  input: VerifySkillInput;
  skillInstanceId: Scalars['UUID'];
};

export type MutationResult = {
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type MyCheckinsFiltersInput = {
  skillIds?: InputMaybe<IntArrayInput>;
};

export type MyCheckinsInput = {
  filters?: InputMaybe<MyCheckinsFiltersInput>;
  search?: InputMaybe<StringValue>;
};

export enum NoneOptionalOrRequired {
  None = 'NONE',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  notificationsEnabled: Scalars['Boolean'];
};

export enum NotificationType {
  CommentMention = 'COMMENT_MENTION',
  CommentReply = 'COMMENT_REPLY',
  LowCreditBalance = 'LOW_CREDIT_BALANCE',
  NewComment = 'NEW_COMMENT',
  NewCommentPermission = 'NEW_COMMENT_PERMISSION',
  NewEditPermission = 'NEW_EDIT_PERMISSION',
  NewSharingPermission = 'NEW_SHARING_PERMISSION',
  NewViewPermission = 'NEW_VIEW_PERMISSION',
  SharpReassignment = 'SHARP_REASSIGNMENT'
}

export type OnCompletionAssignmentRule = {
  __typename?: 'OnCompletionAssignmentRule';
  id: Scalars['UUID'];
  libraryItem: LibraryItem;
  nextLibraryItem: LibraryItem;
  /** @deprecated Field no longer supported */
  roleMemberships: Array<OnCompletionAssignmentRuleRoleMembership>;
  unlockDays?: Maybe<Scalars['Int']>;
  userSelection?: Maybe<UserSelection>;
};

export type OnCompletionAssignmentRuleInput = {
  libraryItemId: Scalars['UUID'];
  nextLibraryItemId: Scalars['UUID'];
  roleIds?: InputMaybe<IntArrayInput>;
  unlockDays?: InputMaybe<Scalars['Int']>;
  userSelection?: InputMaybe<UserSelectionInput>;
};

export type OnCompletionAssignmentRuleMutationResult = MutationResult & {
  __typename?: 'OnCompletionAssignmentRuleMutationResult';
  error?: Maybe<Error>;
  onCompletionAssignmentRule?: Maybe<OnCompletionAssignmentRule>;
  success: Scalars['Boolean'];
};

export type OnCompletionAssignmentRuleRoleMembership = {
  __typename?: 'OnCompletionAssignmentRuleRoleMembership';
  id: Scalars['UUID'];
  role: Role;
};

export type OnCompletionAssignmentRuleSet = {
  __typename?: 'OnCompletionAssignmentRuleSet';
  endingModules: Array<LibraryItem>;
  moduleCount: Scalars['Int'];
  rulesTree: Array<OnCompletionAssignmentRule>;
  startingModules: Array<LibraryItem>;
};

export type OnCompletionAssignmentRuleTree = {
  __typename?: 'OnCompletionAssignmentRuleTree';
  rules: Array<OnCompletionAssignmentRule>;
};

export type OnCompletionAssignmentRuleTreeInput = {
  memberLibraryItemId: Scalars['UUID'];
};

export type OneOffCheckInDelegation = {
  __typename?: 'OneOffCheckInDelegation';
  createdBy: Employee;
  delegatedTo: Employee;
  id: Scalars['UUID'];
  skillInstance: SkillInstance;
};

export type OngoingCheckInDelegation = {
  __typename?: 'OngoingCheckInDelegation';
  createdBy: Employee;
  delegatedTo: Employee;
  id: Scalars['UUID'];
  skill: Skill;
};

export enum Operator {
  Contains = 'CONTAINS',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  In = 'IN',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
  Nin = 'NIN'
}

export type OptionalBooleanValue = {
  value?: InputMaybe<Scalars['Boolean']>;
};

export type OptionalDateTimeValue = {
  value?: InputMaybe<Scalars['DateTime']>;
};

export type OptionalIntValue = {
  value?: InputMaybe<Scalars['Int']>;
};

export type OptionalStringInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type OptionalStringValue = {
  value?: InputMaybe<Scalars['String']>;
};

export type Org = {
  __typename?: 'Org';
  /** @deprecated Field no longer supported */
  brandColor?: Maybe<Scalars['String']>;
  brandDetails?: Maybe<BrandDetails>;
  certificateEndorser?: Maybe<CertificateEndorser>;
  clockInConfirmationSettings: ClockInConfirmationSettings;
  createdAt: Scalars['DateTime'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field no longer supported */
  eslTheme?: Maybe<EslTheme>;
  glossarySet?: Maybe<GlossarySet>;
  hasAllLanguages: Scalars['Boolean'];
  hasAppliedHrisCsvUpload: Scalars['Boolean'];
  hasMergeIntegration: Scalars['Boolean'];
  hrisConfig: HrisConfig;
  hrisIntegrations: Array<HrisIntegration>;
  hrisProvider?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isComplianceOnly?: Maybe<Scalars['Boolean']>;
  isDemoOrg: Scalars['Boolean'];
  languagesInUse: Array<Scalars['Language']>;
  locationGroupTypeConfigs: Array<OrgLocationGroupTypeConfig>;
  /** @deprecated Field no longer supported */
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paysManually: Scalars['Boolean'];
  seatsExpected?: Maybe<Scalars['Int']>;
  sharpConfig?: Maybe<OrgSharpConfig>;
  startedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field no longer supported */
  supportedLanguages: Array<Scalars['Language']>;
  timezone: Timezone;
  verifyOverWhatsapp?: Maybe<Scalars['Boolean']>;
};

export type OrgHrisConfigInput = {
  activatedAt?: InputMaybe<DateTimeInput>;
  dataVerifiedAt?: InputMaybe<DateTimeInput>;
  finishedConnectingAt?: InputMaybe<DateTimeInput>;
  finishedLoadingAt?: InputMaybe<DateTimeInput>;
};

export type OrgInput = {
  deactivatedAt?: InputMaybe<Scalars['DateTime']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  seatsExpected: Scalars['Int'];
  startedAt?: InputMaybe<Scalars['DateTime']>;
  timezone: Scalars['String'];
};

export type OrgLocationGroupTypeConfig = {
  __typename?: 'OrgLocationGroupTypeConfig';
  id: Scalars['UUID'];
  type: LocationGroupType;
};

export type OrgMutationResult = MutationResult & {
  __typename?: 'OrgMutationResult';
  error?: Maybe<Error>;
  org?: Maybe<Org>;
  success: Scalars['Boolean'];
};

export type OrgPremiumContentConfig = {
  __typename?: 'OrgPremiumContentConfig';
  archivedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated use premiumContentConfig */
  catalogConfig: PremiumContentConfig;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Employee>;
  creditCost: Scalars['Int'];
  dueDateDays: Scalars['Int'];
  id: Scalars['Int'];
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<Employee>;
  libraryItem: LibraryItem;
  org: Org;
  premiumContentConfig: PremiumContentConfig;
  setupStep: PremiumContentSetupStep;
};

export type OrgPremiumContentConfigInput = {
  catalogPathIds?: InputMaybe<Array<Scalars['String']>>;
  complianceTypes?: InputMaybe<Array<ComplianceType>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type OrgPremiumContentConfigs = PaginatedList & {
  __typename?: 'OrgPremiumContentConfigs';
  objects: Array<OrgPremiumContentConfig>;
  totalCount: Scalars['Int'];
};

export type OrgSharpConfig = {
  __typename?: 'OrgSharpConfig';
  assignedCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Employee>;
  dueDateDays: Scalars['Int'];
  hotlinePhoneNumber?: Maybe<Scalars['String']>;
  hrEmail?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastEditedAt: Scalars['DateTime'];
  lastEditedBy?: Maybe<Employee>;
  org: Org;
  realWorldComplaintFormLocation?: Maybe<TranslationSet>;
  sharpPolicy?: Maybe<Media>;
};

export type OrgSharpConfigMutationResult = MutationResult & {
  __typename?: 'OrgSharpConfigMutationResult';
  error?: Maybe<Error>;
  orgSharpConfig?: Maybe<OrgSharpConfig>;
  success: Scalars['Boolean'];
};

export type Orgs = PaginatedList & {
  __typename?: 'Orgs';
  objects: Array<Org>;
  totalCount: Scalars['Int'];
};

export enum OutlineGenerationType {
  Direct = 'DIRECT',
  Rewrite = 'REWRITE'
}

export type PaginatedList = {
  objects?: Maybe<Array<Maybe<PaginatedListObjects>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedListObjects = Assignment | Automation | BlastSentRecord | ComplianceRecord | CourseFeedback | Employee | HrisEmployee | HrisLocation | HrisTeam | InProductNotification | LibraryItem | Location | LocationGroup | LocationLeaderboardItem | ManagerLeaderboardItem | MessageBlast | Org | OrgPremiumContentConfig | PremiumContentConfig | PromptInstance | Role | RoleGroup | Skill | TraineeLeaderboardItem | VerificationStepInstance;

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Path = {
  __typename?: 'Path';
  aiGenerateModuleRequests: Array<AiGenerateModuleRequest>;
  assignedCount: Scalars['Int'];
  averageCourseAccuracy?: Maybe<Scalars['Float']>;
  averageCourseFeedbackRating?: Maybe<Scalars['Float']>;
  averageTimeToCompleteDays?: Maybe<Scalars['Float']>;
  averageTimeToCompleteSeconds?: Maybe<Scalars['Float']>;
  catalogPaths: Array<CatalogPath>;
  completeInOrder?: Maybe<Scalars['Boolean']>;
  draftVersion: PathVersion;
  id: Scalars['Int'];
  lastBuilderActionId?: Maybe<Scalars['Int']>;
  libraryItem: LibraryItem;
  publishedVersion?: Maybe<PathVersion>;
  versions: Array<PathVersion>;
};

export type PathBuilderActionInput = {
  addPathContentMembershipAction?: InputMaybe<AddPathContentMembershipActionInput>;
  movePathContentMembershipAction?: InputMaybe<MovePathContentMembershipActionInput>;
  pathId: Scalars['Int'];
  removePathContentMembershipAction?: InputMaybe<RemovePathContentMembershipActionInput>;
  setCourseOutlineBodyTextAction?: InputMaybe<SetCourseOutlineBodyTextActionInput>;
  setCourseOutlineTitleTextAction?: InputMaybe<SetCourseOutlineTitleTextActionInput>;
  setPathContentMembershipRequireFullVideoPlaybackAction?: InputMaybe<SetPathContentMembershipRequireFullVideoPlaybackActionInput>;
  setPathCoverImageAction?: InputMaybe<SetPathCoverImageActionInput>;
  setPathDescriptionAction?: InputMaybe<SetPathDescriptionActionInput>;
  setPathNameAction?: InputMaybe<SetPathNameActionInput>;
  setPathSettingsAction?: InputMaybe<SetPathSettingsActionInput>;
  setPathVersionSettingsAction?: InputMaybe<SetPathVersionSettingsActionInput>;
  setSectionBreakLockBetweenSectionDaysAction?: InputMaybe<SetSectionBreakLockBetweenSectionDaysActionInput>;
  setSectionBreakTitleAction?: InputMaybe<SetSectionBreakTitleActionInput>;
  startCourseOutlineGenerationAction?: InputMaybe<StartCourseOutlineGenerationActionInput>;
};

export type PathCompleteInOrderValue = {
  value: CompletePathInOrder;
};

export type PathContentMembership = {
  __typename?: 'PathContentMembership';
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars['Int']>;
  courseOutline?: Maybe<CourseOutline>;
  fractionPosition: Scalars['Float'];
  id: Scalars['Int'];
  index: Scalars['Float'];
  pathVersion?: Maybe<PathVersion>;
  requireFullVideoPlayback: Scalars['Boolean'];
  sectionBreak?: Maybe<SectionBreak>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['Int']>;
  trainingResource?: Maybe<TrainingResource>;
  trainingResourceId?: Maybe<Scalars['UUID']>;
  uuid: Scalars['UUID'];
};

export type PathContentMembershipInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  sectionBreak?: InputMaybe<SectionBreakInput>;
  skillId?: InputMaybe<Scalars['Int']>;
  trainingResourceId?: InputMaybe<Scalars['UUID']>;
};

export type PathContentMembershipInstance = {
  __typename?: 'PathContentMembershipInstance';
  courseInstance?: Maybe<CourseInstance>;
  id: Scalars['UUID'];
  index?: Maybe<Scalars['Int']>;
  pathInstance: PathInstance;
  readyForTrainingAt?: Maybe<Scalars['DateTime']>;
  requireFullVideoPlayback: Scalars['Boolean'];
  sectionBreakInstance?: Maybe<SectionBreakInstance>;
  skillInstance?: Maybe<SkillInstance>;
  trainingResourceInstance?: Maybe<TrainingResourceInstance>;
};

export type PathInput = {
  courseMemberships?: InputMaybe<Array<PathMembershipCourseInput>>;
  name: Scalars['String'];
  skillMemberships?: InputMaybe<Array<PathMembershipSkillInput>>;
};

export type PathInstance = {
  __typename?: 'PathInstance';
  assignedByList: Array<Scalars['String']>;
  assignment: Assignment;
  certificateUrl?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  complianceAttempts: Array<ComplianceAttempt>;
  createdAt: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['Date']>;
  firstAssignedAt?: Maybe<Scalars['DateTime']>;
  firstAssignedByStr?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastActivityAt?: Maybe<Scalars['DateTime']>;
  markedCompletedBy?: Maybe<Employee>;
  markedCompletedReason?: Maybe<Scalars['String']>;
  path: Path;
  pathContentMembershipInstances: Array<PathContentMembershipInstance>;
  pathVersion?: Maybe<PathVersion>;
  percentComplete: Scalars['Float'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['AttemptStatus'];
  user: Employee;
};

export type PathInstancesMutationResult = MutationResult & {
  __typename?: 'PathInstancesMutationResult';
  error?: Maybe<Error>;
  pathInstances?: Maybe<Array<PathInstance>>;
  success: Scalars['Boolean'];
};

export type PathMembershipCourseInput = {
  courseId: Scalars['Int'];
  index: Scalars['Int'];
};

export type PathMembershipSkillInput = {
  index: Scalars['Int'];
  skillId: Scalars['Int'];
};

export type PathMutationResult = MutationResult & {
  __typename?: 'PathMutationResult';
  error?: Maybe<Error>;
  path?: Maybe<Path>;
  success: Scalars['Boolean'];
};

export type PathSettingsInput = {
  allAdmins?: InputMaybe<SharingPermission>;
  allManagers?: InputMaybe<SharingPermission>;
  completeInOrder?: InputMaybe<BooleanValue>;
  description?: InputMaybe<TranslationSetInputValue>;
  dueDate?: InputMaybe<DueDateInputValue>;
  qrCodeSettings?: InputMaybe<QrCodeSettingsInput>;
};

export type PathVersion = {
  __typename?: 'PathVersion';
  completeInOrder: CompletePathInOrder;
  id: Scalars['UUID'];
  metadata: VersionMetadata;
  path: Path;
  pathContentMemberships: Array<PathContentMembership>;
};

export type PauseTrainingResult = MutationResult & {
  __typename?: 'PauseTrainingResult';
  /** @deprecated Field no longer supported */
  employee?: Maybe<Employee>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<Employee>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<CreditCard>;
  id: Scalars['UUID'];
  nextAction?: Maybe<Scalars['String']>;
  usBankAccount?: Maybe<UsBankAccount>;
};

export enum PexelsOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square'
}

export type PexelsPhoto = {
  __typename?: 'PexelsPhoto';
  alt: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  photographer: Scalars['String'];
  photographerUrl: Scalars['String'];
  src: PexelsSrc;
  width: Scalars['Int'];
};

export type PexelsSrc = {
  __typename?: 'PexelsSrc';
  landscape: Scalars['String'];
  large: Scalars['String'];
  large2x: Scalars['String'];
  medium: Scalars['String'];
  original: Scalars['String'];
  portrait: Scalars['String'];
  small: Scalars['String'];
  tiny: Scalars['String'];
};

export type PexelsVideo = {
  __typename?: 'PexelsVideo';
  height: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['String'];
  videoFiles: Array<PexelsVideoFile>;
  videoPictures: Array<PexelsVideoPicture>;
  videographer: Scalars['String'];
  videographerUrl: Scalars['String'];
  width: Scalars['Int'];
};

export type PexelsVideoFile = {
  __typename?: 'PexelsVideoFile';
  fileType: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  link: Scalars['String'];
  quality?: Maybe<PexelsVideoQuality>;
  width: Scalars['Int'];
};

export type PexelsVideoPicture = {
  __typename?: 'PexelsVideoPicture';
  id: Scalars['Int'];
  picture: Scalars['String'];
};

export enum PexelsVideoQuality {
  Fhd = 'fhd',
  Hd = 'hd',
  Sd = 'sd',
  Uhd = 'uhd'
}

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['UUID'];
  licenseType: LicenseType;
  planType: PlanType;
};

export enum PlanType {
  Custom = 'CUSTOM',
  Growth = 'GROWTH',
  Starter = 'STARTER'
}

export type PremiumContentConfig = {
  __typename?: 'PremiumContentConfig';
  assignedUserType?: Maybe<AssignedUserType>;
  catalogPath?: Maybe<CatalogPath>;
  complianceDurationYears: Scalars['Int'];
  complianceType?: Maybe<ComplianceType>;
  creditCost: Scalars['Int'];
  id: Scalars['Int'];
  usState?: Maybe<Scalars['USState']>;
};

export type PremiumContentConfigInput = {
  complianceTypes?: InputMaybe<Array<ComplianceType>>;
};

export type PremiumContentConfigs = PaginatedList & {
  __typename?: 'PremiumContentConfigs';
  objects: Array<PremiumContentConfig>;
  totalCount: Scalars['Int'];
};

export enum PremiumContentSetupStep {
  AssignmentSettings = 'ASSIGNMENT_SETTINGS',
  BuyCredits = 'BUY_CREDITS',
  Completed = 'COMPLETED',
  Review = 'REVIEW',
  StartSetup = 'START_SETUP'
}

export type PremiumContentSetupStepValue = {
  value: PremiumContentSetupStep;
};

export type ProductEngagementInput = {
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type Prompt = {
  __typename?: 'Prompt';
  accuracy?: Maybe<Scalars['Float']>;
  aiGenerationInProgress: Scalars['Boolean'];
  allowFreeResponseTypos?: Maybe<Scalars['Boolean']>;
  assignMedia: Scalars['Boolean'];
  correctAudioResponseAnswer?: Maybe<Scalars['String']>;
  correctFreeResponseAnswer?: Maybe<TranslationSet>;
  correctTfAnswer?: Maybe<Scalars['Boolean']>;
  drill: Lesson;
  hideMediaSuggestions: Scalars['Boolean'];
  id: Scalars['Int'];
  index: Scalars['Float'];
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['Int']>;
  mediaSuggestions?: Maybe<Array<MediaSuggestion>>;
  numResponses: Scalars['Int'];
  requireFullVideoPlayback: Scalars['Boolean'];
  responseOptions: Array<ResponseOption>;
  selectAllThatApply: Scalars['Boolean'];
  text?: Maybe<TranslationSet>;
  type: PromptType;
  uuid: Scalars['UUID'];
};

export type PromptAttempt = {
  __typename?: 'PromptAttempt';
  id: Scalars['Int'];
  response: Scalars['String'];
  responseOptionInstances: Array<ResponseOptionInstance>;
  submittedAt: Scalars['DateTime'];
  trueFalseResponse?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
};

export type PromptAttemptInput = {
  response: Scalars['String'];
  submittedAt: Scalars['DateTime'];
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type PromptInput = {
  id: Scalars['Int'];
};

export type PromptInstance = {
  __typename?: 'PromptInstance';
  answeredCorrectly?: Maybe<Scalars['Boolean']>;
  attempts: Array<PromptAttempt>;
  completedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lessonInstance: LessonInstance;
  numFailures: Scalars['Int'];
  prompt: Prompt;
  startedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
};

export type PromptInstanceInput = {
  answeredCorrectly?: InputMaybe<Scalars['Boolean']>;
  attempts: Array<PromptAttemptInput>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  numFailures: Scalars['Int'];
  prompt?: InputMaybe<PromptInput>;
  promptId?: InputMaybe<Scalars['Int']>;
  promptSlug?: InputMaybe<Scalars['String']>;
  startedAt?: InputMaybe<Scalars['DateTime']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};

export type PromptInstances = PaginatedList & {
  __typename?: 'PromptInstances';
  objects: Array<PromptInstance>;
  totalCount: Scalars['Int'];
};

export type PromptInstancesFiltersInput = {
  peopleFilters?: InputMaybe<GetPeopleInput>;
  promptUuids?: InputMaybe<UuidArrayInput>;
};

export type PromptInstancesInput = {
  filters: PromptInstancesFiltersInput;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type PromptObjectInput = {
  aiGenerationInProgress?: InputMaybe<Scalars['Boolean']>;
  allowFreeResponseTypos?: InputMaybe<Scalars['Boolean']>;
  assignMedia: Scalars['Boolean'];
  correctFreeResponseAnswer?: InputMaybe<TranslationSetObjectInput>;
  correctTfAnswer?: InputMaybe<Scalars['Boolean']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  requireFullVideoPlayback?: InputMaybe<Scalars['Boolean']>;
  responseOptions: Array<ResponseOptionObjectInput>;
  selectAllThatApply: Scalars['Boolean'];
  text?: InputMaybe<TranslationSetObjectInput>;
  type: PromptType;
  uuid: Scalars['UUID'];
};

export type PromptResponsesCsvInput = {
  courseId: Scalars['Int'];
  userIds?: InputMaybe<Array<Scalars['Int']>>;
};

export enum PromptType {
  AudioResponse = 'AUDIO_RESPONSE',
  GradedFreeResponse = 'GRADED_FREE_RESPONSE',
  ImageChoice = 'IMAGE_CHOICE',
  Message = 'MESSAGE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NongradedFreeResponse = 'NONGRADED_FREE_RESPONSE',
  TitleScreen = 'TITLE_SCREEN',
  TrueFalse = 'TRUE_FALSE'
}

export type PublishCourseInput = {
  allAdmins?: InputMaybe<SharingPermission>;
  allManagers?: InputMaybe<SharingPermission>;
  description?: InputMaybe<TranslationSetInputValue>;
  dueDate?: InputMaybe<DueDateInput>;
  enableCertificate?: InputMaybe<OptionalBooleanValue>;
  enablePromptRetries?: InputMaybe<OptionalBooleanValue>;
  passingAccuracyThreshold?: InputMaybe<OptionalIntValue>;
};

export enum PublishState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES'
}

export type PurchaseCreditsInput = {
  amount?: InputMaybe<Scalars['Int']>;
  billingAccountId?: InputMaybe<Scalars['String']>;
  creditPurchaseOptionIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QrCodeSettingsInput = {
  includeLogo?: InputMaybe<Scalars['Boolean']>;
  includeTitle?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  AdminActiveClients: Array<ActiveClient>;
  AdminCatalogCategories: Array<CatalogCategory>;
  AdminCommentThreads: Array<CommentThread>;
  AdminContentPermissions?: Maybe<ContentPermissions>;
  AdminCourse?: Maybe<Course>;
  AdminCourseBuildingInProgress?: Maybe<Course>;
  AdminCourseFeedback: Array<CourseFeedback>;
  AdminCourseInstance?: Maybe<CourseInstance>;
  AdminCourseLibrary: Array<Course>;
  AdminCourseVersion?: Maybe<CourseVersion>;
  AdminEmployee?: Maybe<Employee>;
  AdminEmployeeEngagement: EmployeeEngagement;
  AdminEmployees: Employees;
  AdminEmployeesWithIncompleteAssignments: Employees;
  AdminEmployeesWithPastDueAssignments: Employees;
  AdminHrisEmployees: HrisEmployees;
  AdminInProductNotifications: Array<InProductNotification>;
  AdminLibrary: LibraryItems;
  AdminMedia?: Maybe<Media>;
  AdminMessageBlast?: Maybe<MessageBlast>;
  AdminMessageBlasts: Array<MessageBlast>;
  AdminOrgSharpConfig?: Maybe<OrgSharpConfig>;
  AdminPath?: Maybe<Path>;
  /** @deprecated Use PathInstance */
  AdminPathInstance?: Maybe<PathInstance>;
  AdminPathVersion?: Maybe<PathVersion>;
  AdminProductAdoption: Array<AdoptionDay>;
  AdminSkill?: Maybe<Skill>;
  AdminSkillVersion?: Maybe<SkillVersion>;
  /** @deprecated Field no longer supported */
  AdminSkills: Array<Skill>;
  /** @deprecated use Tags */
  AdminTags: Array<Tag>;
  /** @deprecated Field no longer supported */
  AdminTrainingResource?: Maybe<TrainingResource>;
  AdminTrainingResources: Array<TrainingResource>;
  AdminWeeklyRecap: WeeklyRecap;
  Assignment?: Maybe<Assignment>;
  AssignmentCompletion: Array<AssignmentCompletionDay>;
  AssignmentStatusCounts: Array<AssignmentStatusCount>;
  Assignments: Assignments;
  Automations: Array<Automation>;
  AutomationsV2: Automations;
  AverageTimeToCompletes: Array<AverageTimeToComplete>;
  AvgTrainingFeedback: Array<AvgTrainingFeedbackWeek>;
  BillingAccounts: Array<BillingAccount>;
  BuilderActions: Array<BuilderAction>;
  CatalogPath?: Maybe<CatalogPath>;
  CheckinDelegationsCreatedByMe: Assignments;
  CompletedCheckinsFacilitatedByMe: Assignments;
  CompletionReportsV2: Scalars['JSON'];
  ComplianceRecords: ComplianceRecords;
  ComplianceReports: Array<ComplianceReport>;
  CourseFeedbacks: CourseFeedbacks;
  CreditPurchaseOptions: Array<CreditPurchaseOption>;
  EmployeeBlastSentRecord?: Maybe<BlastSentRecord>;
  EmployeeBlastSentRecords: Array<BlastSentRecord>;
  EmployeeBlastSentRecordsPaginated: BlastSentRecords;
  EmployeeCourseInstance?: Maybe<CourseInstance>;
  EmployeeTrainingResources: Array<TrainingResource>;
  FeatureFlags: Array<FeatureFlag>;
  GetAIRequests: Array<AiRequest>;
  GetCSMOwnerForOrg: CustomerSuccessManager;
  GetHrisCsvUpload?: Maybe<HrisCsvUpload>;
  GetSavedReports?: Maybe<Array<CustomReport>>;
  GetSupportedImagesMIMETypes: Array<MimeType>;
  GetUsersForUserSelection: Array<Employee>;
  GuestFeedbackAiWorkflow?: Maybe<GuestFeedbackAiWorkflow>;
  HrisActivationData: HrisActivationData;
  HrisEmployees: HrisEmployees;
  HrisLocations: HrisLocations;
  HrisTeams: HrisTeams;
  InProductNotificationUnreadCount: Scalars['Int'];
  InProductNotifications: InProductNotifications;
  Invoices: Array<Invoice>;
  LibraryItem?: Maybe<LibraryItem>;
  Location?: Maybe<Location>;
  LocationGroup?: Maybe<LocationGroup>;
  LocationGroups: LocationGroups;
  LocationLeaderboard: LocationLeaderboard;
  Locations: Locations;
  ManagerLeaderboard: Array<ManagerLeaderboardItem>;
  ManagerLeaderboardPaginated: ManagerLeaderboard;
  Me?: Maybe<Me>;
  MessageBlasts: MessageBlasts;
  MessageReadsGrid: Scalars['JSON'];
  MinimumSupportedAppVersion?: Maybe<Scalars['String']>;
  MyIncompleteCheckinsToFacilitate: Assignments;
  MyPendingCheckinsToFacilitate: Assignments;
  MyTeamIncompleteAssignments: Assignments;
  MyTodoAssignments: Assignments;
  NumUsersEligibleForAutomation: Scalars['Int'];
  OnCompletionAssignmentRuleSets: Array<OnCompletionAssignmentRuleSet>;
  OnCompletionAssignmentRuleTree: OnCompletionAssignmentRuleTree;
  OpenGraphData: Scalars['JSON'];
  OrgPremiumContentConfigs: OrgPremiumContentConfigs;
  PathInstance?: Maybe<PathInstance>;
  People: Employees;
  PremiumContentConfigs: PremiumContentConfigs;
  PromptInstances?: Maybe<PromptInstances>;
  ReassignmentCohortDetails: ReassignmentCohortDetails;
  RecentActivity: Array<RecentActivityItem>;
  RecentlyAssignedLibraryItems: Array<RecentlyAssignedLibraryItem>;
  ReportTopics: Array<ReportTopic>;
  ReportViews: Array<ReportView>;
  ResponseOptionReportingInstances: Array<ResponseOptionReportingInstance>;
  Role?: Maybe<Role>;
  RoleGroup?: Maybe<RoleGroup>;
  RoleGroups: RoleGroups;
  Roles: Roles;
  SearchPexelsPhotos?: Maybe<Array<PexelsPhoto>>;
  SearchPexelsVideos?: Maybe<Array<PexelsVideo>>;
  SearchUnsplashImages?: Maybe<Array<UnsplashImage>>;
  SignupCode?: Maybe<SignupCode>;
  SkillInstance?: Maybe<SkillInstance>;
  Skills: Skills;
  Tags: Array<Tag>;
  TraineeLeaderboard: TraineeLeaderboard;
  TrainingResource?: Maybe<TrainingResource>;
  TrainingResourceViews: Array<TrainingResourceViews>;
  TrainingSessions: Array<TrainingSession>;
  TranslationSet?: Maybe<TranslationSet>;
  UserActionPermissions: UserActionPermissions;
  UserActivity: Array<UserActivityWeek>;
  VerificationStepInstances: VerificationStepInstances;
};


export type QueryAdminActiveClientsArgs = {
  input: ActiveClientsInput;
};


export type QueryAdminCommentThreadsArgs = {
  courseId?: InputMaybe<Scalars['Int']>;
  courseVersionId?: InputMaybe<Scalars['Int']>;
  courseVersionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryAdminContentPermissionsArgs = {
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminCourseArgs = {
  id: Scalars['Int'];
};


export type QueryAdminCourseFeedbackArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAdminCourseInstanceArgs = {
  id: Scalars['Int'];
};


export type QueryAdminCourseLibraryArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  courseIds?: InputMaybe<Array<Scalars['Int']>>;
  hasOpened?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  publishState?: InputMaybe<PublishState>;
  publishStates?: InputMaybe<Array<InputMaybe<PublishState>>>;
  userCreatedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAdminCourseVersionArgs = {
  id: Scalars['Int'];
};


export type QueryAdminEmployeeArgs = {
  id: Scalars['Int'];
};


export type QueryAdminEmployeeEngagementArgs = {
  input?: InputMaybe<ProductEngagementInput>;
};


export type QueryAdminEmployeesArgs = {
  activeSince?: InputMaybe<Scalars['DateTime']>;
  deactivatedOnly?: InputMaybe<Scalars['Boolean']>;
  doingEsl?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Date']>;
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  hasHrisEmployee?: InputMaybe<Scalars['Boolean']>;
  hasUsedApp?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  offset?: InputMaybe<Scalars['Int']>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  userTypes?: InputMaybe<Array<UserType>>;
};


export type QueryAdminEmployeesWithIncompleteAssignmentsArgs = {
  input?: InputMaybe<AdminEmployeesInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAdminEmployeesWithPastDueAssignmentsArgs = {
  input?: InputMaybe<AdminEmployeesInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAdminHrisEmployeesArgs = {
  activated?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminLibraryArgs = {
  input: AdminLibraryInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAdminMediaArgs = {
  id: Scalars['Int'];
};


export type QueryAdminMessageBlastArgs = {
  id: Scalars['Int'];
};


export type QueryAdminMessageBlastsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAdminPathArgs = {
  id: Scalars['Int'];
};


export type QueryAdminPathInstanceArgs = {
  id: Scalars['String'];
};


export type QueryAdminPathVersionArgs = {
  id: Scalars['String'];
};


export type QueryAdminSkillArgs = {
  id: Scalars['Int'];
};


export type QueryAdminSkillVersionArgs = {
  id: Scalars['UUID'];
};


export type QueryAdminSkillsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  hasOpened?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryAdminTrainingResourceArgs = {
  id: Scalars['String'];
};


export type QueryAdminTrainingResourcesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  hasOpened?: InputMaybe<Scalars['Boolean']>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAdminWeeklyRecapArgs = {
  input?: InputMaybe<WeeklyRecapInput>;
};


export type QueryAssignmentArgs = {
  id: Scalars['String'];
};


export type QueryAssignmentCompletionArgs = {
  input?: InputMaybe<AssignmentCompletionInput>;
  libraryItemId?: InputMaybe<Scalars['UUID']>;
};


export type QueryAssignmentStatusCountsArgs = {
  input: AssignmentsInput;
};


export type QueryAssignmentsArgs = {
  input: AssignmentsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAutomationsArgs = {
  allLocations?: InputMaybe<Scalars['Boolean']>;
  allUserType?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAutomationsV2Args = {
  input: AutomationsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryAverageTimeToCompletesArgs = {
  input: AverageTimetoCompleteInput;
};


export type QueryAvgTrainingFeedbackArgs = {
  input?: InputMaybe<AvgTrainingFeedbackInput>;
};


export type QueryBillingAccountsArgs = {
  input: GetBillingAccountsInput;
};


export type QueryBuilderActionsArgs = {
  inputs: Array<GetBuilderActionsInput>;
};


export type QueryCatalogPathArgs = {
  id: Scalars['String'];
};


export type QueryCheckinDelegationsCreatedByMeArgs = {
  input?: InputMaybe<MyCheckinsInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCompletedCheckinsFacilitatedByMeArgs = {
  input?: InputMaybe<MyCheckinsInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryCompletionReportsV2Args = {
  input: CompletionReportsInput;
};


export type QueryComplianceRecordsArgs = {
  input: ComplianceRecordsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryComplianceReportsArgs = {
  input: ComplianceReportsInput;
};


export type QueryCourseFeedbacksArgs = {
  input: CourseFeedbacksInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryEmployeeBlastSentRecordArgs = {
  id: Scalars['Int'];
};


export type QueryEmployeeBlastSentRecordsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryEmployeeBlastSentRecordsPaginatedArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryEmployeeCourseInstanceArgs = {
  id: Scalars['Int'];
};


export type QueryGetAiRequestsArgs = {
  input: GetAiRequestsInput;
};


export type QueryGetHrisCsvUploadArgs = {
  id: Scalars['String'];
};


export type QueryGetUsersForUserSelectionArgs = {
  input?: InputMaybe<UserSelectionInput>;
};


export type QueryHrisActivationDataArgs = {
  hrisCsvUploadId?: InputMaybe<Scalars['String']>;
};


export type QueryHrisEmployeesArgs = {
  input: HrisEmployeesInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryHrisLocationsArgs = {
  input: HrisLocationsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryHrisTeamsArgs = {
  input: HrisTeamsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryInProductNotificationsArgs = {
  input?: InputMaybe<InProductNotificationsInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryLibraryItemArgs = {
  id: Scalars['UUID'];
};


export type QueryLocationArgs = {
  id: Scalars['Int'];
};


export type QueryLocationGroupArgs = {
  id: Scalars['String'];
};


export type QueryLocationGroupsArgs = {
  input?: InputMaybe<LocationGroupsInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryLocationLeaderboardArgs = {
  input: LocationLeaderboardInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryLocationsArgs = {
  input?: InputMaybe<LocationsInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryManagerLeaderboardArgs = {
  input: ManagerLeaderboardInput;
};


export type QueryManagerLeaderboardPaginatedArgs = {
  input: ManagerLeaderboardInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryMessageBlastsArgs = {
  input?: InputMaybe<MessageBlastsInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryMessageReadsGridArgs = {
  input: MessageReadsGridInput;
};


export type QueryMyIncompleteCheckinsToFacilitateArgs = {
  input?: InputMaybe<MyCheckinsInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryMyPendingCheckinsToFacilitateArgs = {
  input?: InputMaybe<MyCheckinsInput>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryNumUsersEligibleForAutomationArgs = {
  automationId: Scalars['Int'];
};


export type QueryOnCompletionAssignmentRuleTreeArgs = {
  input: OnCompletionAssignmentRuleTreeInput;
};


export type QueryOpenGraphDataArgs = {
  url: Scalars['String'];
};


export type QueryOrgPremiumContentConfigsArgs = {
  input: OrgPremiumContentConfigInput;
};


export type QueryPathInstanceArgs = {
  id: Scalars['String'];
};


export type QueryPeopleArgs = {
  input: GetPeopleInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryPremiumContentConfigsArgs = {
  input: PremiumContentConfigInput;
};


export type QueryPromptInstancesArgs = {
  input: PromptInstancesInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryReassignmentCohortDetailsArgs = {
  automationId: Scalars['Int'];
};


export type QueryRecentlyAssignedLibraryItemsArgs = {
  input: RecentlyAssignedLibraryItemsInput;
};


export type QueryReportViewsArgs = {
  input: GetReportViewsInput;
};


export type QueryResponseOptionReportingInstancesArgs = {
  input: ResponseOptionReportingInstanceInput;
};


export type QueryRoleArgs = {
  id: Scalars['Int'];
};


export type QueryRoleGroupArgs = {
  id: Scalars['String'];
};


export type QueryRoleGroupsArgs = {
  input?: InputMaybe<RoleGroupsInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryRolesArgs = {
  input?: InputMaybe<RolesInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySearchPexelsPhotosArgs = {
  orientation?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
  searchText: Scalars['String'];
};


export type QuerySearchPexelsVideosArgs = {
  orientation?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
  searchText: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  orientation?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationInput>;
  searchText: Scalars['String'];
};


export type QuerySignupCodeArgs = {
  code: Scalars['String'];
};


export type QuerySkillInstanceArgs = {
  id: Scalars['String'];
};


export type QuerySkillsArgs = {
  input: SkillsInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryTagsArgs = {
  input?: InputMaybe<TagsInput>;
};


export type QueryTraineeLeaderboardArgs = {
  input: TraineeLeaderboardInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryTrainingResourceArgs = {
  id: Scalars['String'];
};


export type QueryTrainingResourceViewsArgs = {
  locationGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  roleGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  trainingResourceId: Scalars['UUID'];
};


export type QueryTrainingSessionsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};


export type QueryTranslationSetArgs = {
  uuid: Scalars['String'];
};


export type QueryUserActionPermissionsArgs = {
  userId: Scalars['Int'];
};


export type QueryUserActivityArgs = {
  input?: InputMaybe<UserActivityInput>;
  orgId?: InputMaybe<Scalars['Int']>;
};


export type QueryVerificationStepInstancesArgs = {
  input: VerificationStepInstancesInput;
  pagination?: InputMaybe<PaginationInput>;
};

export type ReassignmentCohortDetails = {
  __typename?: 'ReassignmentCohortDetails';
  reassignmentDate: Scalars['DateTime'];
  users: Array<Employee>;
};

export type RecentActivityItem = {
  __typename?: 'RecentActivityItem';
  datetime: Scalars['DateTime'];
  libraryItem: LibraryItem;
  type: RecentActivityItemType;
  user: Employee;
};

export enum RecentActivityItemType {
  Completed = 'COMPLETED',
  Started = 'STARTED'
}

export type RecentlyAssignedLibraryItem = {
  __typename?: 'RecentlyAssignedLibraryItem';
  id: Scalars['UUID'];
  libraryItem: LibraryItem;
  numAssignedSince: Scalars['Int'];
  numAssignedTotal: Scalars['Int'];
  numCompletedTotal: Scalars['Int'];
};

export type RecentlyAssignedLibraryItemsInput = {
  since: DateTimeValue;
};

export type RemoveAssignmentsInput = {
  assignmentIds: Array<Scalars['UUID']>;
};

export type RemoveEmployeeResult = MutationResult & {
  __typename?: 'RemoveEmployeeResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type RemoveLessonActionInput = {
  lessonUuid: Scalars['UUID'];
};

export type RemoveLibraryAccessInput = {
  assignmentIds: Array<Scalars['UUID']>;
};

export type RemoveMcOptionActionInput = {
  option: McOption;
  promptUuid: Scalars['UUID'];
};

export type RemovePathContentMembershipActionInput = {
  pathContentMembershipUuid: Scalars['UUID'];
};

export type RemovePromptActionInput = {
  promptUuid: Scalars['UUID'];
};

export type RemoveResponseOptionActionInput = {
  promptUuid: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type RemoveStepActionInput = {
  id: Scalars['UUID'];
};

export type RemoveTranslationSetOverridesActionInput = {
  language: Scalars['Language'];
  translationSetUuids: Array<RemoveTranslationSetOverridesActionTranslationSetUuidInput>;
};

export type RemoveTranslationSetOverridesActionTranslationSetUuidInput = {
  translationSetUuid: Scalars['UUID'];
};

export type RemoveUserSharingPermissionInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type ReplaceOnCompletionAssignmentRuleSetsInput = {
  rules: Array<OnCompletionAssignmentRuleInput>;
};

export type ReplaceOnCompletionAssignmentRuleSetsMutationResult = MutationResult & {
  __typename?: 'ReplaceOnCompletionAssignmentRuleSetsMutationResult';
  error?: Maybe<Error>;
  ruleSets: Array<OnCompletionAssignmentRuleSet>;
  success: Scalars['Boolean'];
};

export type ReportField = {
  __typename?: 'ReportField';
  displayName: Scalars['String'];
  id: Scalars['UUID'];
  index: Scalars['Int'];
  type: FieldType;
};

export type ReportFilter = {
  __typename?: 'ReportFilter';
  id: Scalars['UUID'];
  operator: Operator;
  reportField: ReportField;
  value: ReportFilterValue;
};

export type ReportFilterInput = {
  fieldId: Scalars['UUID'];
  id: Scalars['UUID'];
  operator: Operator;
  value: ReportFilterValueInput;
};

export type ReportFilterLocationInput = {
  locationGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type ReportFilterRoleInput = {
  roleIds: Array<Scalars['Int']>;
};

export type ReportFilterValue = {
  __typename?: 'ReportFilterValue';
  listInput?: Maybe<Array<Scalars['String']>>;
  locationFilters?: Maybe<LocationFilters>;
  roleFilters?: Maybe<RoleFilters>;
  stringInput?: Maybe<Scalars['String']>;
};

export type ReportFilterValueInput = {
  listInput?: InputMaybe<Array<Scalars['String']>>;
  locationInput?: InputMaybe<ReportFilterLocationInput>;
  roleInput?: InputMaybe<ReportFilterRoleInput>;
  stringInput?: InputMaybe<Scalars['String']>;
};

export type ReportInput = {
  fieldIds: Array<Scalars['UUID']>;
  filters: Array<ReportFilterInput>;
  sortAscending?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['UUID']>;
  topicId: Scalars['UUID'];
};

export type ReportMutationResult = {
  __typename?: 'ReportMutationResult';
  objects: Array<Scalars['JSON']>;
  totalCount: Scalars['Int'];
};

export type ReportTopic = {
  __typename?: 'ReportTopic';
  displayName: Scalars['String'];
  fields: Array<ReportField>;
  id: Scalars['UUID'];
};

export type ReportView = {
  __typename?: 'ReportView';
  id: Scalars['UUID'];
  name: Scalars['String'];
  params: Scalars['JSON'];
  reportViewType: ReportViewType;
};

export type ReportViewInput = {
  name: Scalars['String'];
  params: Scalars['JSON'];
  reportViewType: ReportViewType;
};

export type ReportViewMutationResult = MutationResult & {
  __typename?: 'ReportViewMutationResult';
  error?: Maybe<Error>;
  reportView?: Maybe<ReportView>;
  success: Scalars['Boolean'];
};

export enum ReportViewType {
  LocationCompletionGrid = 'LOCATION_COMPLETION_GRID',
  UserCompletionGrid = 'USER_COMPLETION_GRID'
}

export type ReportViewTypeValueInput = {
  value: ReportViewType;
};

export type ResolveAiRequestInput = {
  accepted: Scalars['Boolean'];
  aiRequestId: Scalars['UUID'];
  regenerateMessage?: InputMaybe<Scalars['String']>;
};

export type ResponseOption = {
  __typename?: 'ResponseOption';
  answer: TranslationSet;
  id: Scalars['UUID'];
  index: Scalars['Float'];
  isCorrect: Scalars['Boolean'];
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['Int']>;
  prompt: Prompt;
};

export type ResponseOptionInstance = {
  __typename?: 'ResponseOptionInstance';
  id: Scalars['UUID'];
  responseOption: ResponseOption;
  responseOptionId: Scalars['UUID'];
};

export type ResponseOptionObjectInput = {
  answer: TranslationSetObjectInput;
  id: Scalars['UUID'];
  isCorrect: Scalars['Boolean'];
};

export type ResponseOptionReportingInstance = {
  __typename?: 'ResponseOptionReportingInstance';
  id: Scalars['String'];
  isCorrectOption: Scalars['Boolean'];
  label: Scalars['String'];
  media?: Maybe<Media>;
  percentOfTraineesSelectedOption: Scalars['Float'];
};

export type ResponseOptionReportingInstanceInput = {
  promptUuid?: InputMaybe<Scalars['UUID']>;
  verificationStepId?: InputMaybe<Scalars['UUID']>;
};

export type Role = {
  __typename?: 'Role';
  createdBy?: Maybe<Employee>;
  directReports: Array<Role>;
  employees: Array<Employee>;
  id: Scalars['Int'];
  lastUpdatedBy?: Maybe<Employee>;
  name: Scalars['String'];
  openedAt?: Maybe<Scalars['DateTime']>;
  org: Org;
  reportsTo: Array<Role>;
  roleActionPermissions?: Maybe<RoleActionPermissions>;
  roleAutomations: Array<Automation>;
  roleGroupMemberships: Array<RoleGroupMembership>;
  roleLevel?: Maybe<Scalars['Int']>;
  userCount: Scalars['Int'];
};


export type RoleUserCountArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type RoleActionPermissions = {
  __typename?: 'RoleActionPermissions';
  actionPermissions: ActionPermissionSet;
  id: Scalars['UUID'];
  requireClockInConfirmation: Scalars['Boolean'];
  role: Role;
  roleUserType: UserType;
};

export type RoleFilters = {
  __typename?: 'RoleFilters';
  roles: Array<Role>;
};

export type RoleGroup = {
  __typename?: 'RoleGroup';
  automations: Array<Automation>;
  childRoleGroups: Array<RoleGroup>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  openedAt?: Maybe<Scalars['DateTime']>;
  parentRoleGroup?: Maybe<RoleGroup>;
  roleCount: Scalars['Int'];
  roleMemberships: Array<RoleGroupMembership>;
  userCount: Scalars['Int'];
};

export type RoleGroupInput = {
  childRoleGroupIds?: Array<Scalars['UUID']>;
  name: Scalars['String'];
  parentRoleGroupId?: InputMaybe<Scalars['UUID']>;
  roleIds: Array<Scalars['Int']>;
};

export type RoleGroupMembership = {
  __typename?: 'RoleGroupMembership';
  id: Scalars['UUID'];
  role: Role;
  roleGroup: RoleGroup;
};

export type RoleGroupMutationResult = MutationResult & {
  __typename?: 'RoleGroupMutationResult';
  error?: Maybe<Error>;
  roleGroup?: Maybe<RoleGroup>;
  success: Scalars['Boolean'];
};

export type RoleGroups = PaginatedList & {
  __typename?: 'RoleGroups';
  objects: Array<RoleGroup>;
  totalCount: Scalars['Int'];
};

export type RoleGroupsInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  roleGroupsChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  roleGroupsDirectChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type RoleLevelInput = {
  level: Scalars['Int'];
  roleId: Scalars['Int'];
};

export type RoleMutationResult = MutationResult & {
  __typename?: 'RoleMutationResult';
  error?: Maybe<Error>;
  role?: Maybe<Role>;
  success: Scalars['Boolean'];
};

export type Roles = PaginatedList & {
  __typename?: 'Roles';
  objects: Array<Role>;
  totalCount: Scalars['Int'];
};

export type RolesInput = {
  hasHrisLink?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  openedStartDate?: InputMaybe<Scalars['DateTime']>;
  roleGroupsChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  roleGroupsDirectChildOf?: InputMaybe<Array<Scalars['UUID']>>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type RolesMutationResult = MutationResult & {
  __typename?: 'RolesMutationResult';
  error?: Maybe<Error>;
  roles?: Maybe<Array<Role>>;
  success: Scalars['Boolean'];
};

export type ScormPackageUploadMutationResult = MutationResult & {
  __typename?: 'SCORMPackageUploadMutationResult';
  error?: Maybe<Error>;
  scormUploads?: Maybe<Array<ScormUpload>>;
  success: Scalars['Boolean'];
};

export type ScormUpload = {
  __typename?: 'SCORMUpload';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Employee>;
  id: Scalars['UUID'];
  pathVersion?: Maybe<PathVersion>;
};

export type SaveReportInput = {
  id: Scalars['UUID'];
  reportInput: ReportInput;
  title: Scalars['String'];
};

export type SavedReportMutationResult = MutationResult & {
  __typename?: 'SavedReportMutationResult';
  error?: Maybe<Error>;
  savedReport?: Maybe<CustomReport>;
  success: Scalars['Boolean'];
};

export type ScheduledReport = {
  __typename?: 'ScheduledReport';
  recipients: Array<ScheduledReportRecipient>;
  sendAt: Scalars['Time'];
  sendOn: Scalars['String'];
};

export type ScheduledReportInput = {
  recipientIds: Array<InputMaybe<Scalars['Int']>>;
  reportId: Scalars['UUID'];
  sendAt: Scalars['Time'];
  sendOn: DayOfTheWeek;
};

export type ScheduledReportRecipient = {
  __typename?: 'ScheduledReportRecipient';
  user: Employee;
};

export type SearchUnsplashMutationResult = MutationResult & {
  __typename?: 'SearchUnsplashMutationResult';
  error?: Maybe<Error>;
  images: Array<UnsplashImage>;
  success: Scalars['Boolean'];
};

export type SectionBreak = {
  __typename?: 'SectionBreak';
  id: Scalars['Int'];
  lockBetweenSectionDays: Scalars['Int'];
  title: TranslationSet;
  uuid: Scalars['UUID'];
};

export type SectionBreakInput = {
  title: TranslationSetObjectInput;
  uuid: Scalars['UUID'];
};

export type SectionBreakInstance = {
  __typename?: 'SectionBreakInstance';
  id: Scalars['UUID'];
  sectionBreak: SectionBreak;
};

export type SectionGrade = {
  __typename?: 'SectionGrade';
  actionItems: Array<GradeActionItem>;
  courseVersionGrade: CourseVersionGrade;
  feedback: Scalars['String'];
  id: Scalars['UUID'];
  maxScore: Scalars['Int'];
  name: Scalars['String'];
  score: Scalars['Int'];
  subsections: Array<SubsectionGrade>;
};

export type SetActiveClientInput = {
  clientId: Scalars['UUID'];
  courseVersionId?: InputMaybe<Scalars['Int']>;
  pathContentMembershipUuid?: InputMaybe<Scalars['UUID']>;
  pathVersionId?: InputMaybe<Scalars['UUID']>;
  promptUuid?: InputMaybe<Scalars['UUID']>;
  skillVersionId?: InputMaybe<Scalars['UUID']>;
  verificationStepId?: InputMaybe<Scalars['UUID']>;
};

export type SetAllowGradedFreeResponseTyposActionInput = {
  allowTypos: Scalars['Boolean'];
  promptUuid: Scalars['UUID'];
};

export type SetCourseCoverImageActionInput = {
  coverImage: CoverImageInput;
};

export type SetCourseDescriptionActionInput = {
  description: TranslationSetObjectInput;
};

export type SetCourseNameActionInput = {
  name: TranslationSetObjectInput;
};

export type SetCourseOutlineBodyTextActionInput = {
  body: Scalars['String'];
  courseOutlineUuid: Scalars['UUID'];
};

export type SetCourseOutlineTitleTextActionInput = {
  courseOutlineUuid: Scalars['UUID'];
  title: Scalars['String'];
};

export type SetCourseSettingsActionInput = {
  adminAccess: SharingPermission;
  dueDate?: InputMaybe<Scalars['Date']>;
  dueDateDays?: InputMaybe<Scalars['Int']>;
  enableCertificate: Scalars['Boolean'];
  enablePromptRetries: Scalars['Boolean'];
  managerAccess: SharingPermission;
  passingAccuracyThreshold?: InputMaybe<Scalars['Int']>;
};

export type SetGradedFreeResponseCorrectAnswerActionInput = {
  lang?: InputMaybe<Scalars['Language']>;
  promptUuid: Scalars['UUID'];
  text?: InputMaybe<Scalars['String']>;
  translationSet: TranslationSetObjectInput;
};

export type SetLessonNameActionInput = {
  lessonUuid: Scalars['UUID'];
  name: Scalars['String'];
};

export type SetMcCorrectOptionActionInput = {
  correctMcOption: McOption;
  promptUuid: Scalars['UUID'];
};

export type SetMcOptionActionInput = {
  lang?: InputMaybe<Scalars['Language']>;
  option: McOption;
  promptUuid: Scalars['UUID'];
  text?: InputMaybe<Scalars['String']>;
  translationSet?: InputMaybe<TranslationSetObjectInput>;
};

export type SetPathContentMembershipRequireFullVideoPlaybackActionInput = {
  pathContentMembershipUuid: Scalars['UUID'];
  requireFullVideoPlayback?: InputMaybe<Scalars['Boolean']>;
};

export type SetPathCoverImageActionInput = {
  coverImage: CoverImageInput;
};

export type SetPathDescriptionActionInput = {
  description: TranslationSetObjectInput;
};

export type SetPathNameActionInput = {
  name: TranslationSetObjectInput;
};

export type SetPathSettingsActionInput = {
  adminAccess: SharingPermission;
  completeInOrder?: InputMaybe<Scalars['Boolean']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  dueDateDays?: InputMaybe<Scalars['Int']>;
  managerAccess: SharingPermission;
};

export type SetPathVersionSettingsActionInput = {
  completeInOrder: CompletePathInOrder;
};

export type SetPromptAssignMediaActionInput = {
  assignMedia: Scalars['Boolean'];
  promptUuid: Scalars['UUID'];
};

export type SetPromptMediaActionInput = {
  mediaId?: InputMaybe<Scalars['Int']>;
  promptUuid: Scalars['UUID'];
};

export type SetPromptRequireFullVideoPlaybackActionInput = {
  promptUuid: Scalars['UUID'];
  requireFullVideoPlayback: Scalars['Boolean'];
};

export type SetPromptSelectAllThatApplyActionInput = {
  promptUuid: Scalars['UUID'];
  selectAllThatApply: Scalars['Boolean'];
};

export type SetPromptTextActionInput = {
  lang?: InputMaybe<Scalars['Language']>;
  promptUuid: Scalars['UUID'];
  text?: InputMaybe<Scalars['String']>;
  translationSet: TranslationSetObjectInput;
};

export type SetPromptTypeActionInput = {
  promptUuid: Scalars['UUID'];
  type: PromptType;
};

export type SetResponseOptionAnswerActionInput = {
  answer: TranslationSetObjectInput;
  promptUuid: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type SetResponseOptionIsCorrectActionInput = {
  isCorrect: Scalars['Boolean'];
  promptUuid: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type SetResponseOptionMediaActionInput = {
  mediaId?: InputMaybe<Scalars['Int']>;
  promptUuid: Scalars['UUID'];
  responseOptionId: Scalars['UUID'];
};

export type SetSectionBreakLockBetweenSectionDaysActionInput = {
  lockBetweenSectionDays: Scalars['Int'];
  sectionBreakUuid: Scalars['UUID'];
};

export type SetSectionBreakTitleActionInput = {
  sectionBreakUuid: Scalars['UUID'];
  title: TranslationSetObjectInput;
};

export type SetSkillCoverImageActionInput = {
  coverImage?: InputMaybe<CoverImageInput>;
};

export type SetSkillDescriptionActionInput = {
  description: TranslationSetObjectInput;
};

export type SetSkillNameActionInput = {
  name: TranslationSetObjectInput;
};

export type SetSkillSettingsActionInput = {
  admin_access: SharingPermission;
  manager_access: SharingPermission;
};

export type SetStepMediaActionInput = {
  id: Scalars['UUID'];
  media: MediaInput;
};

export type SetStepNoteRequiredActionInput = {
  id: Scalars['UUID'];
  noteRequired: NoneOptionalOrRequired;
};

export type SetStepPhotoRequiredActionInput = {
  id: Scalars['UUID'];
  photoRequired: NoneOptionalOrRequired;
};

export type SetStepTextActionInput = {
  id: Scalars['UUID'];
  text: TranslationSetObjectInput;
};

export type SetStepTypeActionInput = {
  id: Scalars['UUID'];
  type: VerificationStepType;
};

export type SetTrueFalseCorrectOptionActionInput = {
  correctTfOption: Scalars['Boolean'];
  promptUuid: Scalars['UUID'];
};

export enum SharingPermission {
  Comment = 'COMMENT',
  Edit = 'EDIT',
  None = 'NONE',
  ViewInLibrary = 'VIEW_IN_LIBRARY'
}

export type SharpCatalogExperience = {
  __typename?: 'SharpCatalogExperience';
  coverImage?: Maybe<CoverImage>;
  creditCost: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['UUID'];
};

export type SignupCode = {
  __typename?: 'SignupCode';
  code: Scalars['String'];
  group: Location;
  id: Scalars['Int'];
  signupLink: Scalars['String'];
  signupQrcodeLink: Scalars['String'];
};

export type Skill = {
  __typename?: 'Skill';
  assignedCount: Scalars['Int'];
  draftVersion?: Maybe<SkillVersion>;
  id: Scalars['Int'];
  lastBuilderActionId?: Maybe<Scalars['Int']>;
  libraryItem: LibraryItem;
  /** @deprecated Field no longer supported */
  ongoingCheckInDelegations: Array<OngoingCheckInDelegation>;
  pathContentMemberships: Array<PathContentMembership>;
  /** @deprecated Field no longer supported */
  pathMemberships: Array<Path>;
  publishedVersion?: Maybe<SkillVersion>;
  verificationCount: Scalars['Int'];
  versions: Array<SkillVersion>;
};

export type SkillBuilderActionInput = {
  addStepAction?: InputMaybe<AddStepActionInput>;
  moveStepAction?: InputMaybe<MoveStepActionInput>;
  removeStepAction?: InputMaybe<RemoveStepActionInput>;
  setSkillCoverImageAction?: InputMaybe<SetSkillCoverImageActionInput>;
  setSkillDescriptionAction?: InputMaybe<SetSkillDescriptionActionInput>;
  setSkillNameAction?: InputMaybe<SetSkillNameActionInput>;
  setSkillSettingsAction?: InputMaybe<SetSkillSettingsActionInput>;
  setStepNoteRequiredAction?: InputMaybe<SetStepNoteRequiredActionInput>;
  setStepPhotoRequiredAction?: InputMaybe<SetStepPhotoRequiredActionInput>;
  setStepTextAction?: InputMaybe<SetStepTextActionInput>;
  setStepTypeAction?: InputMaybe<SetStepTypeActionInput>;
  skillId: Scalars['Int'];
};

export type SkillInput = {
  description?: InputMaybe<TranslationSetInput>;
  name: TranslationSetInput;
};

export type SkillInstance = {
  __typename?: 'SkillInstance';
  assignedByList: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Field no longer supported */
  employee: Employee;
  firstAssignedAt?: Maybe<Scalars['DateTime']>;
  firstAssignedByStr?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isAssignedThroughModule: Scalars['Boolean'];
  oneOffCheckInDelegations: Array<OneOffCheckInDelegation>;
  pathContentMembershipInstances: Array<PathContentMembershipInstance>;
  skill: Skill;
  skillVersion?: Maybe<SkillVersion>;
  user: Employee;
  verifiableAt?: Maybe<Scalars['DateTime']>;
  verificationStepInstances: Array<VerificationStepInstance>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedBy?: Maybe<Employee>;
};

export type SkillInstanceMutationResult = MutationResult & {
  __typename?: 'SkillInstanceMutationResult';
  error?: Maybe<Error>;
  skillInstance?: Maybe<SkillInstance>;
  success: Scalars['Boolean'];
};

export type SkillInstancesFiltersInput = {
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  ratings?: InputMaybe<IntArrayInput>;
  userIds?: InputMaybe<IntArrayInput>;
  verifiable?: InputMaybe<BooleanValue>;
  verified?: InputMaybe<BooleanValue>;
};

export type SkillMutationResult = MutationResult & {
  __typename?: 'SkillMutationResult';
  error?: Maybe<Error>;
  skill?: Maybe<Skill>;
  success: Scalars['Boolean'];
};

export type SkillVersion = {
  __typename?: 'SkillVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  metadata: VersionMetadata;
  skill: Skill;
  verificationSteps: Array<VerificationStep>;
};

export type Skills = PaginatedList & {
  __typename?: 'Skills';
  objects: Array<Skill>;
  totalCount: Scalars['Int'];
};

export type SkillsFiltersInput = {
  archived?: InputMaybe<BooleanValue>;
  hasOpened?: InputMaybe<BooleanValue>;
  hasVerifiableSkills?: InputMaybe<BooleanValue>;
  ids?: InputMaybe<IntArrayInput>;
  openedStartDate?: InputMaybe<DateTimeValue>;
};

export type SkillsInput = {
  filters?: InputMaybe<SkillsFiltersInput>;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type SortInput = {
  column: Scalars['String'];
  descending: Scalars['Boolean'];
  nullsLast?: InputMaybe<Scalars['Boolean']>;
};

export type StartCourseOutlineGenerationActionInput = {
  courseOutlineUuid: Scalars['UUID'];
  generationType?: InputMaybe<OutlineGenerationType>;
  interactivity: Scalars['Int'];
  toneFormality: Scalars['Int'];
  toneSeriousness: Scalars['Int'];
};

export type StillEmployedConfirmationInput = {
  userIds: Array<Scalars['Int']>;
};

export type StringArrayInput = {
  value: Array<Scalars['String']>;
};

export type StringInput = {
  value: Scalars['String'];
};

export type StringMutationResult = MutationResult & {
  __typename?: 'StringMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type StringValue = {
  value: Scalars['String'];
};

export type SubscriptionInput = {
  planId: Scalars['UUID'];
};

export type SubsectionGrade = {
  __typename?: 'SubsectionGrade';
  feedback: Scalars['String'];
  id: Scalars['UUID'];
  maxScore: Scalars['Int'];
  name: Scalars['String'];
  score: Scalars['Int'];
  section: SectionGrade;
};

export type SubtitleSegment = {
  __typename?: 'SubtitleSegment';
  endTime: Scalars['Int'];
  id: Scalars['UUID'];
  index: Scalars['Int'];
  startTime: Scalars['Int'];
  text: Scalars['String'];
};

export type SubtitleSegmentInput = {
  id: Scalars['UUID'];
  text: Scalars['String'];
};

export type SubtitleTrack = {
  __typename?: 'SubtitleTrack';
  id: Scalars['UUID'];
  language: Scalars['Language'];
  subtitleSegments: Array<SubtitleSegment>;
  webvttLink?: Maybe<Scalars['String']>;
};

export type SuggestAiBlocksInput = {
  promptUuids: Array<Scalars['String']>;
  type: SuggestAiBlocksType;
};

export type SuggestAiBlocksMutationResult = MutationResult & {
  __typename?: 'SuggestAIBlocksMutationResult';
  aiPrompts?: Maybe<Array<AiPrompt>>;
  error?: Maybe<Error>;
  /** @deprecated Field no longer supported */
  prompts?: Maybe<Array<Prompt>>;
  success: Scalars['Boolean'];
};

export enum SuggestAiBlocksType {
  Any = 'ANY',
  Questions = 'QUESTIONS'
}

export type Tag = {
  __typename?: 'Tag';
  availableInApp: Scalars['Boolean'];
  checkInsCount?: Maybe<Scalars['Int']>;
  contentTagMemberships: Array<ContentTagMembership>;
  coursesCount?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  isVisibleToTrainees?: Maybe<Scalars['Boolean']>;
  libraryItemCount: Scalars['Int'];
  modulesCount?: Maybe<Scalars['Int']>;
  nameTranslations: TranslationSet;
  pinned?: Maybe<Scalars['Boolean']>;
  resourcesCount?: Maybe<Scalars['Int']>;
};

export type TagInput = {
  availableInApp: Scalars['Boolean'];
  nameTranslations: TranslationSetInput;
  pinned?: InputMaybe<BooleanValue>;
};

export type TagMutationResult = MutationResult & {
  __typename?: 'TagMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  tag?: Maybe<Tag>;
};

export type TagsInput = {
  libraryItemFilters?: InputMaybe<AdminLibraryFiltersInput>;
  overlappingTagIds?: InputMaybe<UuidArrayInput>;
};

export type TextToSpeechInput = {
  text: Scalars['String'];
};

export enum Timezone {
  UsArizona = 'US_ARIZONA',
  UsCentral = 'US_CENTRAL',
  UsEastern = 'US_EASTERN',
  UsMountain = 'US_MOUNTAIN',
  UsPacific = 'US_PACIFIC'
}

export type Topic = {
  __typename?: 'Topic';
  color?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  displayName: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['Int'];
};

export type TraineeLeaderboard = PaginatedList & {
  __typename?: 'TraineeLeaderboard';
  objects: Array<TraineeLeaderboardItem>;
  totalCount: Scalars['Int'];
};

export type TraineeLeaderboardInput = {
  sort?: InputMaybe<SortInput>;
  window?: InputMaybe<LeaderboardWindow>;
};

export type TraineeLeaderboardItem = {
  __typename?: 'TraineeLeaderboardItem';
  assignmentCompletionPercentage?: Maybe<Scalars['Float']>;
  assignmentsCompleted: Scalars['Int'];
  averageAccuracy?: Maybe<Scalars['Float']>;
  id: Scalars['UUID'];
  pastDueAssignments: Scalars['Int'];
  rank: Scalars['Int'];
  userFirstName: Scalars['String'];
  userId: Scalars['Int'];
  userLastName: Scalars['String'];
  userLocations?: Maybe<Array<Scalars['String']>>;
  userName: Scalars['String'];
  userRoles?: Maybe<Array<Scalars['String']>>;
  verifiedSkills: Scalars['Int'];
};

export type TraineeSignupInput = {
  email?: InputMaybe<StringValue>;
  firstName?: InputMaybe<StringValue>;
  language: LanguageValue;
  lastName?: InputMaybe<StringValue>;
  name?: InputMaybe<StringValue>;
  password?: InputMaybe<StringValue>;
  phoneNumber?: InputMaybe<StringValue>;
  signupCode: StringValue;
};

export type TrainingResource = {
  __typename?: 'TrainingResource';
  assignedCount: Scalars['Int'];
  assignedIndividuals: Array<Employee>;
  assignedThroughCourses: Array<Course>;
  id: Scalars['UUID'];
  libraryItem: LibraryItem;
  media: Media;
  pathContentMemberships: Array<PathContentMembership>;
};

export type TrainingResourceInput = {
  mediaId: Scalars['Int'];
};

export type TrainingResourceInstance = {
  __typename?: 'TrainingResourceInstance';
  assignedByList: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  firstAssignedAt?: Maybe<Scalars['DateTime']>;
  firstAssignedByStr?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isAssignedThroughModule: Scalars['Boolean'];
  openedAt?: Maybe<Scalars['DateTime']>;
  pathContentMembershipInstances: Array<PathContentMembershipInstance>;
  trainingResource: TrainingResource;
  user: Employee;
};

export type TrainingResourceInstanceMutationResult = MutationResult & {
  __typename?: 'TrainingResourceInstanceMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  trainingResourceInstance?: Maybe<TrainingResourceInstance>;
};

export type TrainingResourceMutationResult = MutationResult & {
  __typename?: 'TrainingResourceMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  trainingResource?: Maybe<TrainingResource>;
};

export type TrainingResourceViews = {
  __typename?: 'TrainingResourceViews';
  count: Scalars['Int'];
  day: Scalars['Date'];
  locationIds?: Maybe<Array<Scalars['Int']>>;
  roleIds?: Maybe<Array<Scalars['Int']>>;
  trainingResourceId: Scalars['UUID'];
};

export type TrainingResourcesMutationResult = MutationResult & {
  __typename?: 'TrainingResourcesMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  trainingResources?: Maybe<Array<TrainingResource>>;
};

export type TrainingSession = {
  __typename?: 'TrainingSession';
  courseId?: Maybe<Scalars['Int']>;
  courseName?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  messageBlastId?: Maybe<Scalars['Int']>;
  messageBlastSenderName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  trainingResourceId?: Maybe<Scalars['UUID']>;
  trainingResourceName?: Maybe<Scalars['String']>;
};

export type TrainingSessionEventInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  messageBlastId?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  trainingResourceId?: InputMaybe<Scalars['UUID']>;
};

export enum TrainingStatus {
  InvitePending = 'INVITE_PENDING',
  NotStarted = 'NOT_STARTED',
  NoCourse = 'NO_COURSE',
  OnTrack = 'ON_TRACK',
  OptedOut = 'OPTED_OUT',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  StoppedResponding = 'STOPPED_RESPONDING'
}

export type TranslatedTextMutationResult = MutationResult & {
  __typename?: 'TranslatedTextMutationResult';
  error: Error;
  language: Scalars['Language'];
  success: Scalars['Boolean'];
  text: Array<Scalars['String']>;
};

export type TranslationOverride = {
  __typename?: 'TranslationOverride';
  id: Scalars['UUID'];
  language: Scalars['Language'];
  sourceText: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationOverrideInput = {
  language: Scalars['Language'];
  sourceText: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationOverrideObjectInput = {
  id: Scalars['UUID'];
  language: Scalars['Language'];
  sourceText: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationSet = {
  __typename?: 'TranslationSet';
  en: Scalars['String'];
  myTranslation: Scalars['String'];
  translationOverrides: Array<TranslationOverride>;
  translationsDisabled?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
};

export type TranslationSetInput = {
  en: Scalars['String'];
  translationOverrides?: InputMaybe<Array<TranslationOverrideInput>>;
  translationsDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationSetInputValue = {
  value: TranslationSetInput;
};

export type TranslationSetMutationResult = MutationResult & {
  __typename?: 'TranslationSetMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  translationSet?: Maybe<TranslationSet>;
};

export type TranslationSetObjectInput = {
  en: Scalars['String'];
  translationOverrides: Array<TranslationOverrideObjectInput>;
  translationsDisabled?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['UUID'];
};

export type UnsplashImage = {
  __typename?: 'UnsplashImage';
  altDescription?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  downloadLocation: Scalars['String'];
};

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['UUID'];
  links: UnsplashUserLinks;
  name: Scalars['String'];
};

export type UnsplashUserLinks = {
  __typename?: 'UnsplashUserLinks';
  html: Scalars['String'];
  portfolio: Scalars['String'];
};

export type UpdateAiOrgContextInput = {
  businessType?: InputMaybe<StringValue>;
  foundingStory?: InputMaybe<StringValue>;
  id: Scalars['UUID'];
  isApproved?: InputMaybe<BooleanValue>;
  missionStatement?: InputMaybe<StringValue>;
  sells?: InputMaybe<StringValue>;
};

export type UpdateContentSharingPermissionInput = {
  allAdmins?: InputMaybe<SharingPermission>;
  allManagers?: InputMaybe<SharingPermission>;
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  skillId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCourseInput = {
  archived?: InputMaybe<BooleanValue>;
  description?: InputMaybe<TranslationSetInputValue>;
  dueDate?: InputMaybe<DueDateInputValue>;
  enableCertificate?: InputMaybe<BooleanValue>;
  enablePromptRetries?: InputMaybe<BooleanValue>;
  name?: InputMaybe<StringValue>;
  passingAccuracyThreshold?: InputMaybe<OptionalIntValue>;
  qrCodeSettings?: InputMaybe<QrCodeSettingsInput>;
};

export type UpdateCoverImageMutationResult = MutationResult & {
  __typename?: 'UpdateCoverImageMutationResult';
  coverImage?: Maybe<CoverImage>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
};

export type UpdateEmployee = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  language: Scalars['Language'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeFeatureFlagsInput = {
  featureFlags: Array<FeatureFlagInput>;
};

export type UpdateEmployeeResult = MutationResult & {
  __typename?: 'UpdateEmployeeResult';
  /** @deprecated Field no longer supported */
  employee?: Maybe<Employee>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<Employee>;
};

export type UpdateHrisCsvUploadInput = {
  appliedAt?: InputMaybe<DateTimeInput>;
  dataVerifiedAt?: InputMaybe<DateTimeInput>;
  id: Scalars['UUID'];
  idField?: InputMaybe<StringValue>;
  locationField?: InputMaybe<OptionalStringValue>;
  nameField?: InputMaybe<StringValue>;
  phoneField?: InputMaybe<StringValue>;
  selectedColumnsAt?: InputMaybe<DateTimeInput>;
  teamField?: InputMaybe<OptionalStringValue>;
  terminationDateField?: InputMaybe<StringValue>;
};

export type UpdateLibraryItemCourseInput = {
  enableCertificate?: InputMaybe<BooleanValue>;
  enablePromptRetries?: InputMaybe<BooleanValue>;
  passingAccuracyThreshold?: InputMaybe<OptionalIntValue>;
};

export type UpdateLibraryItemPathInput = {
  allAdmins?: InputMaybe<SharingPermission>;
  allManagers?: InputMaybe<SharingPermission>;
  completeInOrder?: InputMaybe<BooleanValue>;
};

export type UpdateLibraryItemTrainingResourceInput = {
  mediaUrls?: InputMaybe<TranslationSetObjectInput>;
};

export type UpdateLocationInput = {
  addressInput?: InputMaybe<AddressInput>;
  locationGroupIds?: InputMaybe<UuidArrayInput>;
  name: Scalars['String'];
};

export type UpdateMediaInputV2 = {
  description?: InputMaybe<TranslationSetObjectInput>;
  mediaUrls?: InputMaybe<TranslationSetObjectInput>;
  name?: InputMaybe<TranslationSetObjectInput>;
};

export type UpdateOnCompletionAssignmentRuleInput = {
  roleIds?: InputMaybe<IntArrayInput>;
  unlockDays?: InputMaybe<IntValue>;
};

export type UpdateOrbCustomerInput = {
  additionalEmails?: InputMaybe<Array<Scalars['String']>>;
  address?: InputMaybe<AddressInput>;
  autoCollection?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateOrgPremiumContentConfigInput = {
  dueDateDays?: InputMaybe<IntValue>;
  id: Scalars['Int'];
  setupStep?: InputMaybe<PremiumContentSetupStepValue>;
};

export type UpdateOrgSharpConfigInput = {
  dueDateDays?: InputMaybe<IntValue>;
  hotlinePhoneNumber?: InputMaybe<StringValue>;
  hrEmail?: InputMaybe<StringValue>;
  realWorldComplaintFormLocation?: InputMaybe<TranslationSetInputValue>;
  sharpPolicyId?: InputMaybe<IntValue>;
};

export type UpdateRoleInput = {
  directReportRoleIds?: InputMaybe<IntArrayInput>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<ActionPermissionSetInput>;
  requireClockInConfirmation?: InputMaybe<Scalars['Boolean']>;
  roleGroupIds?: InputMaybe<UuidArrayInput>;
  roleUserType?: InputMaybe<UserType>;
};

export type UpdateRoleLevelsInput = {
  roleLevels: Array<RoleLevelInput>;
};

export type UpdateSubtitlesInput = {
  language: Scalars['Language'];
  mediaId: Scalars['Int'];
  subtitleSegments: Array<SubtitleSegmentInput>;
};

export type UpdateTagsInput = {
  availableInApp: Scalars['Boolean'];
  ids: Array<Scalars['UUID']>;
};

export type UpdateTrainingResourceInput = {
  mediaInput?: InputMaybe<UpdateMediaInputV2>;
  qrCodeSettings?: InputMaybe<QrCodeSettingsInput>;
};

export type UpdateTranslationSetActionInput = {
  lang?: InputMaybe<Scalars['Language']>;
  text?: InputMaybe<Scalars['String']>;
  translationSet?: InputMaybe<TranslationSetObjectInput>;
  translationSetUuid: Scalars['UUID'];
};

export type UpdateUser = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  language: Scalars['Language'];
  locationIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['Int']>;
  userType: UserType;
};

export type UpdateUserInput = {
  email?: InputMaybe<OptionalStringInput>;
  firstName?: InputMaybe<StringInput>;
  jobTitle?: InputMaybe<StringInput>;
  language?: InputMaybe<LanguageInput>;
  lastName?: InputMaybe<StringInput>;
  locationIds?: InputMaybe<IntArrayInput>;
  name?: InputMaybe<StringInput>;
  phoneNumber?: InputMaybe<StringInput>;
  roleIds?: InputMaybe<IntArrayInput>;
  userType?: InputMaybe<UserTypeInput>;
};

export type UpdateUserSharingPermissionInput = {
  courseId?: InputMaybe<Scalars['Int']>;
  pathId?: InputMaybe<Scalars['Int']>;
  permission: SharingPermission;
  skillId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export enum UrlType {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Gif = 'GIF',
  Image = 'IMAGE',
  Other = 'OTHER',
  Video = 'VIDEO'
}

export type UsBankAccount = {
  __typename?: 'UsBankAccount';
  bankName: Scalars['String'];
  last4: Scalars['String'];
};

export type UserActionPermissionOverrides = {
  __typename?: 'UserActionPermissionOverrides';
  actionPermissions: ActionPermissionSet;
  id: Scalars['UUID'];
  lastEditedBy?: Maybe<Employee>;
  updatedAt: Scalars['DateTime'];
  user: Employee;
};

export type UserActionPermissionValue = {
  __typename?: 'UserActionPermissionValue';
  hasPermission: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type UserActionPermissions = {
  __typename?: 'UserActionPermissions';
  assignContent: UserActionPermissionValue;
  changeOrgSettings: UserActionPermissionValue;
  createContent: UserActionPermissionValue;
  deactivateTrainees: UserActionPermissionValue;
  editTrainees: UserActionPermissionValue;
  inviteOthers: UserActionPermissionValue;
  manageBilling: UserActionPermissionValue;
  manageLibrary: UserActionPermissionValue;
  manageOrgIntegrations: UserActionPermissionValue;
  managePremiumContent: UserActionPermissionValue;
  markAsComplete: UserActionPermissionValue;
  promoteTrainees: UserActionPermissionValue;
  sendMessages: UserActionPermissionValue;
  unassignContent: UserActionPermissionValue;
  updateAutomations: UserActionPermissionValue;
  verifySkills: UserActionPermissionValue;
  viewReporting: UserActionPermissionValue;
};

export type UserActionPermissionsInput = {
  assignContent?: InputMaybe<OptionalBooleanValue>;
  changeOrgSettings?: InputMaybe<OptionalBooleanValue>;
  createContent?: InputMaybe<OptionalBooleanValue>;
  deactivateTrainees?: InputMaybe<OptionalBooleanValue>;
  editTrainees?: InputMaybe<OptionalBooleanValue>;
  inviteOthers?: InputMaybe<OptionalBooleanValue>;
  manageBilling?: InputMaybe<OptionalBooleanValue>;
  manageLibrary?: InputMaybe<OptionalBooleanValue>;
  manageOrgIntegrations?: InputMaybe<OptionalBooleanValue>;
  managePremiumContent?: InputMaybe<OptionalBooleanValue>;
  markAsComplete?: InputMaybe<OptionalBooleanValue>;
  promoteTrainees?: InputMaybe<OptionalBooleanValue>;
  sendMessages?: InputMaybe<OptionalBooleanValue>;
  unassignContent?: InputMaybe<OptionalBooleanValue>;
  updateAutomations?: InputMaybe<OptionalBooleanValue>;
  verifySkills?: InputMaybe<OptionalBooleanValue>;
  viewReporting?: InputMaybe<OptionalBooleanValue>;
};

export type UserActivityInput = {
  since?: InputMaybe<DateTimeValue>;
};

export type UserActivityWeek = {
  __typename?: 'UserActivityWeek';
  activeUsers?: Maybe<Scalars['Int']>;
  label: Scalars['String'];
  totalUsers?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Date']>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  language: Scalars['Language'];
  lastName?: InputMaybe<Scalars['String']>;
  locationIds: Array<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleIds: Array<Scalars['Int']>;
  userType: UserType;
};

export type UserMutationResult = MutationResult & {
  __typename?: 'UserMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<Employee>;
};

export type UserSelection = {
  __typename?: 'UserSelection';
  allAdmins: Scalars['Boolean'];
  allLocations: Scalars['Boolean'];
  allManagers: Scalars['Boolean'];
  allTrainees: Scalars['Boolean'];
  daysAfterHire?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  locationGroupMemberships: Array<UserSelectionLocationGroup>;
  locationMemberships: Array<UserSelectionLocation>;
  roleGroupMemberships: Array<UserSelectionRoleGroup>;
  roleMemberships: Array<UserSelectionRole>;
  userMemberships: Array<UserSelectionUser>;
};

export type UserSelectionFiltersInput = {
  allAdmins?: InputMaybe<Scalars['Boolean']>;
  allManagers?: InputMaybe<Scalars['Boolean']>;
  allTrainees?: InputMaybe<Scalars['Boolean']>;
  locationGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  roleGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type UserSelectionInput = {
  allAdmins?: InputMaybe<Scalars['Boolean']>;
  allLocations: Scalars['Boolean'];
  allManagers: Scalars['Boolean'];
  allTrainees: Scalars['Boolean'];
  daysAfterHire?: InputMaybe<Scalars['Int']>;
  locationGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  roleGroupIds?: InputMaybe<Array<Scalars['UUID']>>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type UserSelectionLocation = {
  __typename?: 'UserSelectionLocation';
  id: Scalars['Int'];
  location: Location;
};

export type UserSelectionLocationGroup = {
  __typename?: 'UserSelectionLocationGroup';
  id: Scalars['UUID'];
  locationGroup: LocationGroup;
};

export type UserSelectionRole = {
  __typename?: 'UserSelectionRole';
  id: Scalars['Int'];
  role: Role;
};

export type UserSelectionRoleGroup = {
  __typename?: 'UserSelectionRoleGroup';
  id: Scalars['UUID'];
  roleGroup: RoleGroup;
};

export type UserSelectionUser = {
  __typename?: 'UserSelectionUser';
  id: Scalars['Int'];
  user: Employee;
};

export type UserSharingPermission = {
  __typename?: 'UserSharingPermission';
  id: Scalars['UUID'];
  permission: SharingPermission;
  user: Employee;
};

export type UserSharingPermissionMutationResult = MutationResult & {
  __typename?: 'UserSharingPermissionMutationResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  userSharingPermission?: Maybe<UserSharingPermission>;
  userSharingPermissions?: Maybe<Array<UserSharingPermission>>;
};

export enum UserType {
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type UserTypeInput = {
  value: UserType;
};

export type UserTypeListInput = {
  value: Array<UserType>;
};

export type UuidArrayInput = {
  value: Array<Scalars['UUID']>;
};

export type VerificationMedia = {
  __typename?: 'VerificationMedia';
  id: Scalars['UUID'];
  media: Media;
};

export type VerificationStep = {
  __typename?: 'VerificationStep';
  averageRating?: Maybe<Scalars['Float']>;
  familyId: Scalars['UUID'];
  id: Scalars['UUID'];
  index: Scalars['Float'];
  /** @deprecated Field no longer supported */
  isNoteRequired?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  isPhotoRequired?: Maybe<Scalars['Boolean']>;
  noteRequired: NoneOptionalOrRequired;
  numResponses: Scalars['Int'];
  percentAnsweredYes?: Maybe<Scalars['Float']>;
  photoRequired: NoneOptionalOrRequired;
  text?: Maybe<TranslationSet>;
  type: VerificationStepType;
};

export type VerificationStepInput = {
  notes?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  verificationStepId: Scalars['UUID'];
  yesNoAnswer?: InputMaybe<Scalars['Boolean']>;
};

export type VerificationStepInstance = {
  __typename?: 'VerificationStepInstance';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  notes?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  skillInstance: SkillInstance;
  verificationMedias: Array<VerificationMedia>;
  verificationStep: VerificationStep;
  yesNoAnswer?: Maybe<Scalars['Boolean']>;
};

export type VerificationStepInstances = PaginatedList & {
  __typename?: 'VerificationStepInstances';
  objects: Array<VerificationStepInstance>;
  totalCount: Scalars['Int'];
};

export type VerificationStepInstancesFiltersInput = {
  peopleFilters?: InputMaybe<GetPeopleInput>;
  verificationStepIds?: InputMaybe<UuidArrayInput>;
};

export type VerificationStepInstancesInput = {
  filters: VerificationStepInstancesFiltersInput;
  search?: InputMaybe<StringValue>;
  sort?: InputMaybe<SortInput>;
};

export type VerificationStepObjectInput = {
  familyId: Scalars['UUID'];
  id: Scalars['UUID'];
  isNoteRequired?: InputMaybe<Scalars['Boolean']>;
  isPhotoRequired?: InputMaybe<Scalars['Boolean']>;
  noteRequired?: InputMaybe<NoneOptionalOrRequired>;
  photoRequired?: InputMaybe<NoneOptionalOrRequired>;
  text?: InputMaybe<TranslationSetObjectInput>;
  type: VerificationStepType;
};

export enum VerificationStepType {
  Rating = 'RATING',
  YesNo = 'YES_NO'
}

export type VerifySkillInput = {
  skillVersionId: Scalars['UUID'];
  verificationStepsInput: Array<VerificationStepInput>;
};

export type VersionMetadata = {
  __typename?: 'VersionMetadata';
  id: Scalars['UUID'];
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedBy?: Maybe<Employee>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedBy?: Maybe<Employee>;
};

export type WeeklyRecap = {
  __typename?: 'WeeklyRecap';
  /** @deprecated Field no longer supported */
  eslUpLevels?: Maybe<Array<Employee>>;
  numCoursesCreated: Scalars['Int'];
  numCoursesPublished: Scalars['Int'];
  numInvited: Scalars['Int'];
  numTraineesCompletedACourse: Scalars['Int'];
};

export type WeeklyRecapInput = {
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
};

export const GraphqlOperations = {
  Query: {
    AutomationList: 'AutomationList',
    AutomationListCount: 'AutomationListCount',
    AssignmentTimelineModal: 'AssignmentTimelineModal',
    CourseInstanceResponsesModal: 'CourseInstanceResponsesModal',
    CourseFeedbackSummary_CourseFeedbacks: 'CourseFeedbackSummary_CourseFeedbacks',
    CourseFeedbacks: 'CourseFeedbacks',
    AssignmentStatusCounts: 'AssignmentStatusCounts',
    NumUsersEligibleForAutomation: 'NumUsersEligibleForAutomation',
    PremiumPathDetailAssignmentsTable_Assignments: 'PremiumPathDetailAssignmentsTable_Assignments',
    PremiumPathDetailOverview: 'PremiumPathDetailOverview',
    PremiumPathDetailAutomation: 'PremiumPathDetailAutomation',
    PremiumPathDetailHeader: 'PremiumPathDetailHeader',
    PremiumPathDetailCertificatesCount: 'PremiumPathDetailCertificatesCount',
    UpcomingReassignmentCohortDetails: 'UpcomingReassignmentCohortDetails',
    SkillDetailOverview: 'SkillDetailOverview',
    SkillDetailCounts: 'SkillDetailCounts',
    SkillInstanceModal_SkillInstance: 'SkillInstanceModal_SkillInstance',
    ProgressiveTagFilterTags: 'ProgressiveTagFilterTags',
    AssignLibraryItemModal_LibraryItem: 'AssignLibraryItemModal_LibraryItem',
    AssignLibraryItemModal_ExistingAssignments: 'AssignLibraryItemModal_ExistingAssignments',
    NewAutomationCourse: 'NewAutomationCourse',
    NewAutomationTrainingResource: 'NewAutomationTrainingResource',
    NewAutomationPath: 'NewAutomationPath',
    SelectUsersModal_Users: 'SelectUsersModal_Users',
    SelectUsersModal_LocationGroups: 'SelectUsersModal_LocationGroups',
    GetUsersForUserSelection: 'GetUsersForUserSelection',
    GetSelectedUsers: 'GetSelectedUsers',
    PathInstanceScreen: 'PathInstanceScreen',
    CourseAssignmentFilterState: 'CourseAssignmentFilterState',
    PathAssignmentFilterState: 'PathAssignmentFilterState',
    ActiveLanguagesWidget: 'ActiveLanguagesWidget',
    ActiveAndTotalManagers: 'ActiveAndTotalManagers',
    ActivityWidget: 'ActivityWidget',
    AssignmentCompletion__HomeWidget: 'AssignmentCompletion__HomeWidget',
    AvgTrainingFeedbackWidget: 'AvgTrainingFeedbackWidget',
    IncompleteAssignmentsSheet: 'IncompleteAssignmentsSheet',
    IncompleteAssignmentsWidget: 'IncompleteAssignmentsWidget',
    LocationAssignmentLeaderboardWidget: 'LocationAssignmentLeaderboardWidget',
    LocationGroupModal_LocationGroup: 'LocationGroupModal_LocationGroup',
    LocationGroupModal_Locations: 'LocationGroupModal_Locations',
    LocationsTable_Locations: 'LocationsTable_Locations',
    NotInvitedUsersSheetHrisEmployees: 'NotInvitedUsersSheetHrisEmployees',
    NotInvitedUsersWidgetHrisEmployees: 'NotInvitedUsersWidgetHrisEmployees',
    NotInvitedUsersWidgetNotInvitedHrisEmployees: 'NotInvitedUsersWidgetNotInvitedHrisEmployees',
    RecentlyAssignedLibraryItems: 'RecentlyAssignedLibraryItems',
    RecentCourseFeedback: 'RecentCourseFeedback',
    AdminRecentlyEditedPaths: 'AdminRecentlyEditedPaths',
    RoleGroupModal_RoleGroup: 'RoleGroupModal_RoleGroup',
    RoleGroupModal_Roles: 'RoleGroupModal_Roles',
    TotalActiveUsersWidget: 'TotalActiveUsersWidget',
    TotalLocationsCount: 'TotalLocationsCount',
    AdminEmployeesCount: 'AdminEmployeesCount',
    UsersNoRoleSheet: 'UsersNoRoleSheet',
    UsersNoRoleWidgetCount: 'UsersNoRoleWidgetCount',
    InactiveUsersSheet: 'InactiveUsersSheet',
    InactiveUsersCount: 'InactiveUsersCount',
    LocationGroupParentOptions_LocationGroups: 'LocationGroupParentOptions_LocationGroups',
    LocationGroupChildrenOptions_LocationGroups: 'LocationGroupChildrenOptions_LocationGroups',
    RoleGroupParentOptions_RoleGroups: 'RoleGroupParentOptions_RoleGroups',
    RoleGroupChildrenOptions_RoleGroups: 'RoleGroupChildrenOptions_RoleGroups',
    CatalogCoursePreview: 'CatalogCoursePreview',
    BillingBanner: 'BillingBanner',
    Init: 'Init',
    Notifications: 'Notifications',
    GetNotifications: 'GetNotifications',
    OrgSwitcher: 'OrgSwitcher',
    LibraryCounts: 'LibraryCounts',
    AdminLibrary: 'AdminLibrary',
    LibraryItemAccessAutomation: 'LibraryItemAccessAutomation',
    CheckInsPageHeader: 'CheckInsPageHeader',
    CoursesPageHeader: 'CoursesPageHeader',
    ModulesPageHeader: 'ModulesPageHeader',
    ResourcesPageHeader: 'ResourcesPageHeader',
    AssignmentCompletion: 'AssignmentCompletion',
    LibraryItemDetailPage_LibraryItem: 'LibraryItemDetailPage_LibraryItem',
    FileDetailOverview: 'FileDetailOverview',
    FileAssignments: 'FileAssignments',
    FileDetailCounts: 'FileDetailCounts',
    FileDetailHeader: 'FileDetailHeader',
    CourseDetailPageQuery: 'CourseDetailPageQuery',
    CourseDetailOverview: 'CourseDetailOverview',
    CourseDetailHeader: 'CourseDetailHeader',
    CourseVersions: 'CourseVersions',
    MediaPreviewMedia: 'MediaPreviewMedia',
    CourseVersion: 'CourseVersion',
    CourseContentRedirect: 'CourseContentRedirect',
    PublishCourseModal: 'PublishCourseModal',
    getCourseCommentThreads: 'getCourseCommentThreads',
    CommentMentionPeople: 'CommentMentionPeople',
    CourseVersionGrade: 'CourseVersionGrade',
    ActiveClients: 'ActiveClients',
    GetAIRequests: 'GetAIRequests',
    CourseManageTabAssignments: 'CourseManageTabAssignments',
    CourseDetailLibraryAccess: 'CourseDetailLibraryAccess',
    CourseReportingOverview_LibraryItem: 'CourseReportingOverview_LibraryItem',
    CourseReportingOverview_CourseVersion: 'CourseReportingOverview_CourseVersion',
    CourseReportingAverageTimeToComplete_LibraryItem: 'CourseReportingAverageTimeToComplete_LibraryItem',
    CourseReportingOverview_ResponseOptionReportingInstances: 'CourseReportingOverview_ResponseOptionReportingInstances',
    CourseReportingPromptResponsesTable_PromptInstances: 'CourseReportingPromptResponsesTable_PromptInstances',
    CourseReportingVersionSelector_LibraryItem: 'CourseReportingVersionSelector_LibraryItem',
    CourseSharingPermissions: 'CourseSharingPermissions',
    PathSharingPermissions: 'PathSharingPermissions',
    ContentPermissions: 'ContentPermissions',
    LibraryItemContentPermissions: 'LibraryItemContentPermissions',
    BuilderActions: 'BuilderActions',
    ValidateModule_Path: 'ValidateModule_Path',
    BuilderPathVersion: 'BuilderPathVersion',
    RedirectPathBuilder: 'RedirectPathBuilder',
    PollAIGenerateModuleRequests: 'PollAIGenerateModuleRequests',
    CopyPathModal: 'CopyPathModal',
    PathContentLibraryItems: 'PathContentLibraryItems',
    AddPathContentMembership_Course: 'AddPathContentMembership_Course',
    AddPathContentMembership_Courses: 'AddPathContentMembership_Courses',
    SavePathModal: 'SavePathModal',
    getCommentThreadsInPath: 'getCommentThreadsInPath',
    ValidatePath_Path: 'ValidatePath_Path',
    ModuleManageAssignmentsTab_Assignments: 'ModuleManageAssignmentsTab_Assignments',
    ModuleManageLibraryAccessTab_Assignments: 'ModuleManageLibraryAccessTab_Assignments',
    ModuleManageSettingsTab_Path: 'ModuleManageSettingsTab_Path',
    ModuleReportingContent_LibraryItem: 'ModuleReportingContent_LibraryItem',
    ModuleReportingAvgTimeToComplete_LibraryItem: 'ModuleReportingAvgTimeToComplete_LibraryItem',
    ModuleReportingStepsTable_PathVersion: 'ModuleReportingStepsTable_PathVersion',
    ModuleReportingStepsTableCourses_AverageTimeToCompletes: 'ModuleReportingStepsTableCourses_AverageTimeToCompletes',
    ModuleReportingVersionSelector_LibraryItem: 'ModuleReportingVersionSelector_LibraryItem',
    TrainingResourceViews: 'TrainingResourceViews',
    TrainingResourcePDFContainer_TrainingResource: 'TrainingResourcePDFContainer_TrainingResource',
    PublishSkillModal_Skill: 'PublishSkillModal_Skill',
    ShareSkillModal_Skill: 'ShareSkillModal_Skill',
    SkillBuilder_SkillVersion: 'SkillBuilder_SkillVersion',
    AssignmentsTab_Skill: 'AssignmentsTab_Skill',
    AssignmentsTab_Assignments: 'AssignmentsTab_Assignments',
    SkillReportingOverview_SkillVersion: 'SkillReportingOverview_SkillVersion',
    SkillReportingOverview_ResponseOptionReportingInstances: 'SkillReportingOverview_ResponseOptionReportingInstances',
    SkillReportingResponsesTable_VerificationStepInstances: 'SkillReportingResponsesTable_VerificationStepInstances',
    SkillReportingVersionSelector_LibraryItem: 'SkillReportingVersionSelector_LibraryItem',
    LocationsPageHeader: 'LocationsPageHeader',
    GetAdminsAndManagers: 'GetAdminsAndManagers',
    GetEmployees: 'GetEmployees',
    LocationGroupLocationsTable_Locations: 'LocationGroupLocationsTable_Locations',
    LocationGroupPagePeople: 'LocationGroupPagePeople',
    LocationGroupsTable: 'LocationGroupsTable',
    SubRegionsList_LocationGroup: 'SubRegionsList_LocationGroup',
    MarketplacePageCatalogCategories: 'MarketplacePageCatalogCategories',
    OrgPremiumContentConfigs: 'OrgPremiumContentConfigs',
    GetCSMOwnerForOrg: 'GetCSMOwnerForOrg',
    MessageBlasts: 'MessageBlasts',
    ReceivedMessages: 'ReceivedMessages',
    getReceivedMessage: 'getReceivedMessage',
    getMessageBlast: 'getMessageBlast',
    GetTraineesWithoutHrisEmployee: 'GetTraineesWithoutHrisEmployee',
    GetRolesWithoutHrisTeam: 'GetRolesWithoutHrisTeam',
    locationModal_Location: 'locationModal_Location',
    LocationModal_LocationGroup: 'LocationModal_LocationGroup',
    LocationModal_BillingAccounts: 'LocationModal_BillingAccounts',
    RoleModalBody_Roles: 'RoleModalBody_Roles',
    UserActionPermissionOverrides: 'UserActionPermissionOverrides',
    AddMediaModalTrainingResources: 'AddMediaModalTrainingResources',
    AddMediaResourcesTrainingResources: 'AddMediaResourcesTrainingResources',
    Search_StockPhotos: 'Search_StockPhotos',
    Search_StockVideos: 'Search_StockVideos',
    AIGenerateUploadTrainingResourceLibraryItems: 'AIGenerateUploadTrainingResourceLibraryItems',
    AIUploadMediaGetSupportedImagesMIMETypes: 'AIUploadMediaGetSupportedImagesMIMETypes',
    AssignContentToUserModal: 'AssignContentToUserModal',
    InviteQRCodeModal: 'InviteQRCodeModal',
    SearchBlastRecipients: 'SearchBlastRecipients',
    OrgForUpdate: 'OrgForUpdate',
    TranslationSet: 'TranslationSet',
    MyTrainingPageMyTodoAssignmentsCount: 'MyTrainingPageMyTodoAssignmentsCount',
    MyTrainingPageMyTodoAssignments: 'MyTrainingPageMyTodoAssignments',
    MyTrainingSkillInstancePreview: 'MyTrainingSkillInstancePreview',
    MyTraining_TrainingResourceInstance: 'MyTraining_TrainingResourceInstance',
    MyTrainingPathInstanceScreen: 'MyTrainingPathInstanceScreen',
    UserClockInStatus: 'UserClockInStatus',
    CourseInstanceScreen: 'CourseInstanceScreen',
    CourseContentCourse: 'CourseContentCourse',
    MyProfilePageUser: 'MyProfilePageUser',
    PlacholderSidebarLibraryItems: 'PlacholderSidebarLibraryItems',
    OnCompletionAssignmentRuleSets: 'OnCompletionAssignmentRuleSets',
    ReportTopics: 'ReportTopics',
    GetSavedReports: 'GetSavedReports',
    LibraryItemFilter: 'LibraryItemFilter',
    LibraryItemHoverCardContent: 'LibraryItemHoverCardContent',
    LocationGroupHoverCard: 'LocationGroupHoverCard',
    LocationHoverCard: 'LocationHoverCard',
    GetReportViews: 'GetReportViews',
    UserHoverCard: 'UserHoverCard',
    CompletionGridLibraryItems: 'CompletionGridLibraryItems',
    CompletionGridLibraryItemsWithPathMemberships: 'CompletionGridLibraryItemsWithPathMemberships',
    CompletionReports: 'CompletionReports',
    MessageBlastHoverCard: 'MessageBlastHoverCard',
    MessageReadsGrid: 'MessageReadsGrid',
    MessageReadsGridMessages: 'MessageReadsGridMessages',
    UserCompletionGrid__Assignments: 'UserCompletionGrid__Assignments',
    UserCompletionGrid__Users: 'UserCompletionGrid__Users',
    ComplianceAttemptImportRow_People: 'ComplianceAttemptImportRow_People',
    ComplianceRecords: 'ComplianceRecords',
    ComplianceSummary: 'ComplianceSummary',
    ComplianceReports: 'ComplianceReports',
    CompletionReportingLocationGroups: 'CompletionReportingLocationGroups',
    UserCount: 'UserCount',
    CompletionReportingLocations: 'CompletionReportingLocations',
    ReportingOrgPremiumConfigs: 'ReportingOrgPremiumConfigs',
    AverageRating: 'AverageRating',
    ReportingCourseFeedbacks: 'ReportingCourseFeedbacks',
    LocationLeaderboard: 'LocationLeaderboard',
    ManagerLeaderboard: 'ManagerLeaderboard',
    TraineeLeaderboard: 'TraineeLeaderboard',
    DepartmentsPageContentRoleGroups: 'DepartmentsPageContentRoleGroups',
    DepartmentsPageHeader: 'DepartmentsPageHeader',
    RoleDetail: 'RoleDetail',
    AllRoleAutomations: 'AllRoleAutomations',
    RoleGroupDetailTabCounts: 'RoleGroupDetailTabCounts',
    RoleGroupHeader_PeopleCount: 'RoleGroupHeader_PeopleCount',
    RoleGroupHeader_RolesCount: 'RoleGroupHeader_RolesCount',
    RoleGroupHeaderAllLocations_RolesCount: 'RoleGroupHeaderAllLocations_RolesCount',
    RoleGroupRolesTable_Roles: 'RoleGroupRolesTable_Roles',
    SubRoleGroupsList_RoleGroup: 'SubRoleGroupsList_RoleGroup',
    AIOrgContext: 'AIOrgContext',
    ArchivePageHeader: 'ArchivePageHeader',
    GetCreditPurchaseOptions: 'GetCreditPurchaseOptions',
    BillingContainer: 'BillingContainer',
    BillingAccount_Invoices: 'BillingAccount_Invoices',
    BillingViewBillingAccount: 'BillingViewBillingAccount',
    CertificateEndorser: 'CertificateEndorser',
    EmailPreferences: 'EmailPreferences',
    HrisEmployees: 'HrisEmployees',
    HrisLocationFilterOptions: 'HrisLocationFilterOptions',
    HrisTeamFilterOptions: 'HrisTeamFilterOptions',
    HrisListHrisIntegrations: 'HrisListHrisIntegrations',
    HrisLocations: 'HrisLocations',
    HrisTeams: 'HrisTeams',
    RelinkHrisBanner: 'RelinkHrisBanner',
    HrisActivationData: 'HrisActivationData',
    HrisIntegrations: 'HrisIntegrations',
    HrisSetupNextButtonDisabled: 'HrisSetupNextButtonDisabled',
    OrgHrisConfig: 'OrgHrisConfig',
    CheckIfCsvCompletedCreatingObjects: 'CheckIfCsvCompletedCreatingObjects',
    GetHrisCsvUpload: 'GetHrisCsvUpload',
    GlossarySetMe: 'GlossarySetMe',
    AddSharpVersionModal: 'AddSharpVersionModal',
    SharpDetailAssignmentsTable_Assignments: 'SharpDetailAssignmentsTable_Assignments',
    SharpComplianceRecords: 'SharpComplianceRecords',
    TraineeSignupCode: 'TraineeSignupCode',
    GetLocationPoster: 'GetLocationPoster',
    TagDropdownContentTags: 'TagDropdownContentTags',
    TagsPageContentTags: 'TagsPageContentTags',
    TagsPageHeader: 'TagsPageHeader',
    CourseSingleSelectDropdown: 'CourseSingleSelectDropdown',
    OpenGraphData: 'OpenGraphData',
    UserSelectInput: 'UserSelectInput',
    LocationGroupFilters: 'LocationGroupFilters',
    JumpToPeople: 'JumpToPeople',
    JumpToLibraryItems: 'JumpToLibraryItems',
    JumpToLocationGroups: 'JumpToLocationGroups',
    JumpToLocations: 'JumpToLocations',
    JumpToRoleGroups: 'JumpToRoleGroups',
    JumpToRoles: 'JumpToRoles',
    JumpToTags: 'JumpToTags',
    RecentlyViewedEmployees: 'RecentlyViewedEmployees',
    RecentlyViewedLibraryItems: 'RecentlyViewedLibraryItems',
    RecentlyViewedCourses: 'RecentlyViewedCourses',
    RecentlyViewedTrainingResources: 'RecentlyViewedTrainingResources',
    RecentlyViewedLocations: 'RecentlyViewedLocations',
    RecentlyViewedRoles: 'RecentlyViewedRoles',
    RecentlyViewedLocationGroups: 'RecentlyViewedLocationGroups',
    RecentlyViewedRoleGroups: 'RecentlyViewedRoleGroups',
    GetLocationsWithEmployees: 'GetLocationsWithEmployees',
    GetRolesWithEmployees: 'GetRolesWithEmployees',
    GetAllEmployees: 'GetAllEmployees',
    UsersPageContent: 'UsersPageContent',
    UsersPageHeader: 'UsersPageHeader',
    UserDetailAccessAssignments: 'UserDetailAccessAssignments',
    UserDetailAssignments: 'UserDetailAssignments',
    UserDetailCompletedTrainingTabAssignments: 'UserDetailCompletedTrainingTabAssignments',
    UserDetailComplianceCertificatesTabComplianceRecords: 'UserDetailComplianceCertificatesTabComplianceRecords',
    RolesList: 'RolesList',
    GetPeople: 'GetPeople',
    TrainingSessions: 'TrainingSessions',
    UserDetailProfileTabAdminEmployee: 'UserDetailProfileTabAdminEmployee',
    UserDetailVerificationsTabAssignments: 'UserDetailVerificationsTabAssignments',
    UserDetailsAdminEmployee: 'UserDetailsAdminEmployee',
    UserPageContentTabCounts: 'UserPageContentTabCounts',
    UserPageContentEmployee: 'UserPageContentEmployee',
    UserPageHeaderEmployee: 'UserPageHeaderEmployee',
    UserActionPermissions: 'UserActionPermissions',
    FeatureFlags: 'FeatureFlags',
    LocationDetail: 'LocationDetail',
    AllLocationAutomations: 'AllLocationAutomations',
    LocationGroupDetail: 'LocationGroupDetail',
    OrgSharpConfig: 'OrgSharpConfig',
    RoleGroupDetail: 'RoleGroupDetail',
    Tags: 'Tags',
    CatalogPath: 'CatalogPath',
    BillingInitializedBillingAccounts: 'BillingInitializedBillingAccounts',
    AdminCount: 'AdminCount',
    ModuleCount: 'ModuleCount',
    Plan: 'Plan',
    BrandSelector: 'BrandSelector',
    BrandColor: 'BrandColor',
    ClockInConfirmationSettings: 'ClockInConfirmationSettings',
    Hris: 'Hris',
    IsStaff: 'IsStaff',
    GetLocationGroups: 'GetLocationGroups',
    GetLocations: 'GetLocations',
    CreditBalance: 'CreditBalance',
    MeUserId: 'MeUserId',
    AvailableOrgCount: 'AvailableOrgCount',
    GetRoleGroups: 'GetRoleGroups',
    GetRoles: 'GetRoles'
  },
  Mutation: {
    AIAddEmojisToText: 'AIAddEmojisToText',
    AISuggestSpellingAndGrammarChangesToText: 'AISuggestSpellingAndGrammarChangesToText',
    AISuggestReadingLevelChangesToText: 'AISuggestReadingLevelChangesToText',
    GenerateCoursePromptsResponsesCSV: 'GenerateCoursePromptsResponsesCSV',
    UpdateCourse: 'UpdateCourse',
    UpdateAutomation: 'UpdateAutomation',
    DiffAutomationChange: 'DiffAutomationChange',
    updateOrgPremiumContentConfig: 'updateOrgPremiumContentConfig',
    OpenedSkill: 'OpenedSkill',
    DeletePath: 'DeletePath',
    CopyCourse: 'CopyCourse',
    CopySkill: 'CopySkill',
    BulkUpdateLibraryItemAutomations: 'BulkUpdateLibraryItemAutomations',
    DeleteContentInstances: 'DeleteContentInstances',
    ScrapeFoundingStory: 'ScrapeFoundingStory',
    ScrapeSells: 'ScrapeSells',
    ScrapeMissionStatement: 'ScrapeMissionStatement',
    CreateLocationGroup: 'CreateLocationGroup',
    UpdateLocationGroup: 'UpdateLocationGroup',
    CreateRoleGroup: 'CreateRoleGroup',
    UpdateRoleGroup: 'UpdateRoleGroup',
    UpdateInactiveUsersSheet: 'UpdateInactiveUsersSheet',
    translateText: 'translateText',
    TrackEvent: 'TrackEvent',
    Logout: 'Logout',
    resetDemoOrg: 'resetDemoOrg',
    createDashboardFeedback: 'createDashboardFeedback',
    InitializeBilling: 'InitializeBilling',
    MarkNotificationsRead: 'MarkNotificationsRead',
    UpdateMyOrg: 'UpdateMyOrg',
    updateStaffActionPermissions: 'updateStaffActionPermissions',
    UpdateEmployeeFeatureFlags: 'UpdateEmployeeFeatureFlags',
    ArchiveContent: 'ArchiveContent',
    CheckInsPageHeaderCreateSkill: 'CheckInsPageHeaderCreateSkill',
    ArchivePath: 'ArchivePath',
    ArchiveTrainingResource: 'ArchiveTrainingResource',
    ArchiveSkill: 'ArchiveSkill',
    UpdateTrainingResourceQRCode: 'UpdateTrainingResourceQRCode',
    UpdateCourseArchived: 'UpdateCourseArchived',
    RevertToPriorVersion: 'RevertToPriorVersion',
    UpdateSubtitles: 'UpdateSubtitles',
    applyBuilderActions: 'applyBuilderActions',
    SetActiveClient: 'SetActiveClient',
    RemoveActiveClient: 'RemoveActiveClient',
    PublishCourse: 'PublishCourse',
    CreatePromptCommentThread: 'CreatePromptCommentThread',
    ResolvePromptCommentThread: 'ResolvePromptCommentThread',
    AddCommentToThread: 'AddCommentToThread',
    DeleteComment: 'DeleteComment',
    EditComment: 'EditComment',
    CreateAIFeedback: 'CreateAIFeedback',
    GradeCourseVersion: 'GradeCourseVersion',
    GenerateBuilderActionInput: 'GenerateBuilderActionInput',
    SuggestAIBlocks: 'SuggestAIBlocks',
    EditPromptWithAI: 'EditPromptWithAI',
    ResolveAIRequest: 'ResolveAIRequest',
    OpenedCourse: 'OpenedCourse',
    PromptCreateMedia: 'PromptCreateMedia',
    updateContentSharingPermission: 'updateContentSharingPermission',
    createUserSharingPermissions: 'createUserSharingPermissions',
    updateUserSharingPermission: 'updateUserSharingPermission',
    removeUserSharingPermission: 'removeUserSharingPermission',
    sendCoursePreviewToPhone: 'sendCoursePreviewToPhone',
    CopyPath: 'CopyPath',
    updatePathSettingsAndPublish: 'updatePathSettingsAndPublish',
    PathBuilder_CreateSkill: 'PathBuilder_CreateSkill',
    OpenedPath: 'OpenedPath',
    MarkAssignmentsComplete: 'MarkAssignmentsComplete',
    PublishSkillAndUpdateSharingPermissions: 'PublishSkillAndUpdateSharingPermissions',
    OpenedLocation: 'OpenedLocation',
    OpenedLocationGroupPage: 'OpenedLocationGroupPage',
    CreateCourse: 'CreateCourse',
    addContentToLibrary: 'addContentToLibrary',
    CreateOrgSharpConfig: 'CreateOrgSharpConfig',
    MessageBlastModalCreateMedia: 'MessageBlastModalCreateMedia',
    CreateMessageBlast: 'CreateMessageBlast',
    UpdateMessageBlast: 'UpdateMessageBlast',
    deleteMessageBlastNotificationSchedule: 'deleteMessageBlastNotificationSchedule',
    CreateBlastReadRecord: 'CreateBlastReadRecord',
    CreateHrisEmployeeLink: 'CreateHrisEmployeeLink',
    CreateHrisLocationLink: 'CreateHrisLocationLink',
    CreateHrisTeamLink: 'CreateHrisTeamLink',
    RemoveEmployees: 'RemoveEmployees',
    DeleteMessageBlast: 'DeleteMessageBlast',
    updateRole: 'updateRole',
    createLocation: 'createLocation',
    updateLocation: 'updateLocation',
    createRole: 'createRole',
    resendBlastNotification: 'resendBlastNotification',
    updateBlastNotificationSchedule: 'updateBlastNotificationSchedule',
    ReactivateEmployees: 'ReactivateEmployees',
    createTrainingResource: 'createTrainingResource',
    updateTrainingResource: 'updateTrainingResource',
    updateUserActionPermissionOverrides: 'updateUserActionPermissionOverrides',
    AddMediaModalCreateMedia: 'AddMediaModalCreateMedia',
    CreateAICourse: 'CreateAICourse',
    CreateAIModule: 'CreateAIModule',
    BulkCreateTrainingResources: 'BulkCreateTrainingResources',
    CreateLocationInQRCodeModal: 'CreateLocationInQRCodeModal',
    addUsersToLocations: 'addUsersToLocations',
    addUsersToRoles: 'addUsersToRoles',
    removeUsersFromLocations: 'removeUsersFromLocations',
    removeUsersFromRoles: 'removeUsersFromRoles',
    CreateOrg: 'CreateOrg',
    UpdateOrg: 'UpdateOrg',
    updateTranslationSet: 'updateTranslationSet',
    CreateCourseFeedback: 'CreateCourseFeedback',
    getAIFeedbackOnGradedFreeResponseAttempt: 'getAIFeedbackOnGradedFreeResponseAttempt',
    applyCourseProgress: 'applyCourseProgress',
    CreateTrainingSessionEvent: 'CreateTrainingSessionEvent',
    CreateClockInConfirmation: 'CreateClockInConfirmation',
    OpenedMyTrainingResourceInstance: 'OpenedMyTrainingResourceInstance',
    MyProfilePageUpdateEmployee: 'MyProfilePageUpdateEmployee',
    ReplaceOncompletionAssignmentRuleSets: 'ReplaceOncompletionAssignmentRuleSets',
    DeleteOnCompletionAssignmentRules: 'DeleteOnCompletionAssignmentRules',
    DeleteScheduledReportEmail: 'DeleteScheduledReportEmail',
    GetFieldFrequencies: 'GetFieldFrequencies',
    RunReport: 'RunReport',
    SaveReport: 'SaveReport',
    deleteReport: 'deleteReport',
    CreateScheduledReportEmail: 'CreateScheduledReportEmail',
    CreateReportView: 'CreateReportView',
    UpdateReportView: 'UpdateReportView',
    DeleteReportView: 'DeleteReportView',
    CreateComplianceAttemptImports: 'CreateComplianceAttemptImports',
    updateRoleLevels: 'updateRoleLevels',
    OpenedRole: 'OpenedRole',
    OpenedRoleGroup: 'OpenedRoleGroup',
    UpdateAIOrgContext: 'UpdateAIOrgContext',
    PurchaseCredits: 'PurchaseCredits',
    CreateCheckoutSession: 'CreateCheckoutSession',
    updateOrbCustomer: 'updateOrbCustomer',
    updateOrgLogo: 'updateOrgLogo',
    updateCertificateEndorser: 'updateCertificateEndorser',
    updateOrgBrandColor: 'updateOrgBrandColor',
    UpdateClockInConfirmationSettings: 'UpdateClockInConfirmationSettings',
    UpdateAdminEmailPreferences: 'UpdateAdminEmailPreferences',
    CreateHrisCsvUpload: 'CreateHrisCsvUpload',
    GenerateAccountToken: 'GenerateAccountToken',
    GenerateLinkToken: 'GenerateLinkToken',
    UpdateOrgHrisConfig: 'UpdateOrgHrisConfig',
    UpdateHrisCsvUpload: 'UpdateHrisCsvUpload',
    UpdateGlossarySet: 'UpdateGlossarySet',
    CompanyPolicyCreateMedia: 'CompanyPolicyCreateMedia',
    updateOrgSharpConfig: 'updateOrgSharpConfig',
    CompleteTraineeSignup: 'CompleteTraineeSignup',
    UpdateTags: 'UpdateTags',
    DeleteTags: 'DeleteTags',
    searchUnsplashImages: 'searchUnsplashImages',
    attributeUnsplashImageSelection: 'attributeUnsplashImageSelection',
    CreateUser: 'CreateUser',
    DeactivateUser: 'DeactivateUser',
    DisablePushNotifications: 'DisablePushNotifications',
    EnablePushNotifications: 'EnablePushNotifications',
    MergeUsers: 'MergeUsers',
    ReactivateEmployee: 'ReactivateEmployee',
    DeleteLocations: 'DeleteLocations',
    DeleteRoles: 'DeleteRoles',
    DeleteLocationGroups: 'DeleteLocationGroups',
    DeleteRoleGroups: 'DeleteRoleGroups',
    DeleteLocation: 'DeleteLocation',
    DeleteRole: 'DeleteRole',
    UpdateUser: 'UpdateUser',
    createTag: 'createTag',
    updateTag: 'updateTag',
    deleteTag: 'deleteTag',
    createContentTagMemberships: 'createContentTagMemberships',
    deleteContentTagMemberships: 'deleteContentTagMemberships',
    AssignLibraryItems: 'AssignLibraryItems',
    UploadFileList: 'UploadFileList',
    UploadFile: 'UploadFile',
    createPath: 'createPath',
    UpdatePathSettings: 'UpdatePathSettings'
  },
  Fragment: {
    AutomationListAutomation: 'AutomationListAutomation',
    InlineUserSelectionPreviewUserSelection: 'InlineUserSelectionPreviewUserSelection',
    InlineEditTagsLibraryItem: 'InlineEditTagsLibraryItem',
    AssignmentTimelineLibraryItem: 'AssignmentTimelineLibraryItem',
    AssignmentTimelineModalAssignment: 'AssignmentTimelineModalAssignment',
    AssignmentTimelineCourse: 'AssignmentTimelineCourse',
    UserPathStatusPathInstance: 'UserPathStatusPathInstance',
    Responses_Prompt: 'Responses_Prompt',
    Responses_PromptInstance: 'Responses_PromptInstance',
    Responses_LessonInstance: 'Responses_LessonInstance',
    Responses_CourseInstance: 'Responses_CourseInstance',
    CourseFeedbackRow: 'CourseFeedbackRow',
    UserCourseStatusCourseInstance: 'UserCourseStatusCourseInstance',
    AIRequestThin: 'AIRequestThin',
    PremiumPathDetailAutomation: 'PremiumPathDetailAutomation',
    PremiumPathDetailLibraryItem: 'PremiumPathDetailLibraryItem',
    PremiumPathDetailOverview: 'PremiumPathDetailOverview',
    AIGenerateCourseFromOutlineRequest: 'AIGenerateCourseFromOutlineRequest',
    CourseOutline: 'CourseOutline',
    PremiumPathDetailSectionBreak: 'PremiumPathDetailSectionBreak',
    PremiumPathDetailPathContentMembership: 'PremiumPathDetailPathContentMembership',
    PremiumPathDetailFile: 'PremiumPathDetailFile',
    PremiumPathDetailSkill: 'PremiumPathDetailSkill',
    PremiumPathDetailCourse: 'PremiumPathDetailCourse',
    PremiumPathDetailHeader: 'PremiumPathDetailHeader',
    ReassignmentCohortDetails: 'ReassignmentCohortDetails',
    AssignedByContentMembershipPath: 'AssignedByContentMembershipPath',
    AssignedByPathContentMembership: 'AssignedByPathContentMembership',
    AssignedByCourse: 'AssignedByCourse',
    SkillDetailOverview: 'SkillDetailOverview',
    SkillInstanceModal_SkillInstance: 'SkillInstanceModal_SkillInstance',
    SkillInstanceModal_VerificationStep: 'SkillInstanceModal_VerificationStep',
    SkillInstanceModal_VerificationStepInstance: 'SkillInstanceModal_VerificationStepInstance',
    ProgressiveTagFilterTag: 'ProgressiveTagFilterTag',
    UserThin: 'UserThin',
    SelectUsersModal_LocationGroup: 'SelectUsersModal_LocationGroup',
    PathInstanceTrainingResourceInstance: 'PathInstanceTrainingResourceInstance',
    PathInstanceSectionBreakInstance: 'PathInstanceSectionBreakInstance',
    PathInstanceCourseInstance: 'PathInstanceCourseInstance',
    PathInstanceSkillInstance: 'PathInstanceSkillInstance',
    PathInstancePathContentMembershipInstance: 'PathInstancePathContentMembershipInstance',
    PathInstanceScreen_PathInstance: 'PathInstanceScreen_PathInstance',
    PathInstanceScreen_Course: 'PathInstanceScreen_Course',
    PathInstanceScreen_Skill: 'PathInstanceScreen_Skill',
    PathInstanceScreen_LibraryItem: 'PathInstanceScreen_LibraryItem',
    LibraryItemUrl: 'LibraryItemUrl',
    LibraryItemTitle: 'LibraryItemTitle',
    LibraryItemAvatar: 'LibraryItemAvatar',
    CourseAssignmentFilterState: 'CourseAssignmentFilterState',
    PathAssignmentFilterState: 'PathAssignmentFilterState',
    LanguageCount: 'LanguageCount',
    ManagerLeaderboardItem_Widget: 'ManagerLeaderboardItem_Widget',
    IncompleteAssignmentsSheetEmployee: 'IncompleteAssignmentsSheetEmployee',
    LocationAssignmentLeaderboardWidgetItem: 'LocationAssignmentLeaderboardWidgetItem',
    LocationGroupModal_LocationGroup: 'LocationGroupModal_LocationGroup',
    LocationsTable_Location: 'LocationsTable_Location',
    NotInvitedUsersSheetHrisEmployee: 'NotInvitedUsersSheetHrisEmployee',
    RecentlyAssignedLibraryItem__Widget: 'RecentlyAssignedLibraryItem__Widget',
    CourseFeedbackFull: 'CourseFeedbackFull',
    RecentFeedbackCourseInstance: 'RecentFeedbackCourseInstance',
    RoleGroupModal_RoleGroup: 'RoleGroupModal_RoleGroup',
    UsersNoRoleSheetEmployee: 'UsersNoRoleSheetEmployee',
    InactiveUsersSheetEmployee: 'InactiveUsersSheetEmployee',
    Me: 'Me',
    MeOrg: 'MeOrg',
    AvailableOrg: 'AvailableOrg',
    LibraryContainerLibraryItem: 'LibraryContainerLibraryItem',
    LibraryCourse: 'LibraryCourse',
    CourseEditPermissionsCourse: 'CourseEditPermissionsCourse',
    LibraryUserSharingPermission: 'LibraryUserSharingPermission',
    LibraryContentSharingPermission: 'LibraryContentSharingPermission',
    LibraryPath: 'LibraryPath',
    LibrarySkill: 'LibrarySkill',
    LibraryContentTagMembership: 'LibraryContentTagMembership',
    LibraryTrainingResource: 'LibraryTrainingResource',
    LibraryPathPremiumContentConfig: 'LibraryPathPremiumContentConfig',
    LibraryCatalogPath: 'LibraryCatalogPath',
    OrgPremiumContentConfig: 'OrgPremiumContentConfig',
    LibraryItemDetailPage_LibraryItem: 'LibraryItemDetailPage_LibraryItem',
    LibraryItemDetailPage_Course: 'LibraryItemDetailPage_Course',
    LibraryItemDetailPage_Path: 'LibraryItemDetailPage_Path',
    LibraryItemDetailPage_Skill: 'LibraryItemDetailPage_Skill',
    LibraryItemDetailPage_TrainingResource: 'LibraryItemDetailPage_TrainingResource',
    LibraryItemDetailPageCoverImage_CoverImage: 'LibraryItemDetailPageCoverImage_CoverImage',
    TrainingResourceLibraryItemQRCode: 'TrainingResourceLibraryItemQRCode',
    FileDetailOverview: 'FileDetailOverview',
    FileDetailAssignmentOverview: 'FileDetailAssignmentOverview',
    FileAssignment: 'FileAssignment',
    FileAssignmentTrainingResourceInstance: 'FileAssignmentTrainingResourceInstance',
    FileDetailEmployee: 'FileDetailEmployee',
    FileDetailHeader: 'FileDetailHeader',
    CourseDetailOverview: 'CourseDetailOverview',
    CourseLibraryItemQRCode: 'CourseLibraryItemQRCode',
    CourseDetailHeader: 'CourseDetailHeader',
    HistoryCourseVersion: 'HistoryCourseVersion',
    SubtitleSegment: 'SubtitleSegment',
    SubtitleTrack: 'SubtitleTrack',
    MediaPreview: 'MediaPreview',
    RightSidebarCourseSettingsContent_Course: 'RightSidebarCourseSettingsContent_Course',
    CourseContentCourse: 'CourseContentCourse',
    CourseContentCourseVersion: 'CourseContentCourseVersion',
    CourseContentLesson: 'CourseContentLesson',
    CourseContentPrompt: 'CourseContentPrompt',
    ResponseOption: 'ResponseOption',
    ActiveClient: 'ActiveClient',
    LibraryItemDetailCourseSettingsRightPane_Course: 'LibraryItemDetailCourseSettingsRightPane_Course',
    PublishCourseModal: 'PublishCourseModal',
    CommentThread: 'CommentThread',
    Comment: 'Comment',
    CommentMention: 'CommentMention',
    AtMentionEmployee: 'AtMentionEmployee',
    SubsectionGrade: 'SubsectionGrade',
    GradeActionItem: 'GradeActionItem',
    SectionGrade: 'SectionGrade',
    CourseVersionGrade: 'CourseVersionGrade',
    SuggestedPrompt: 'SuggestedPrompt',
    AITranslationSet: 'AITranslationSet',
    AIQuestionResponseOption: 'AIQuestionResponseOption',
    AIPrompt: 'AIPrompt',
    AIRequest: 'AIRequest',
    MediaSuggestion: 'MediaSuggestion',
    AIResponseOption: 'AIResponseOption',
    AIEditPromptRequest: 'AIEditPromptRequest',
    CourseManageTabAssignmentsAssignment: 'CourseManageTabAssignmentsAssignment',
    CourseDetailAccessCourseInstance: 'CourseDetailAccessCourseInstance',
    CourseDetailAccessAssignment: 'CourseDetailAccessAssignment',
    CourseReportingOverview_LibraryItem: 'CourseReportingOverview_LibraryItem',
    CourseReportingOverview_Courseversion: 'CourseReportingOverview_Courseversion',
    ResponseOptionReportingInstance: 'ResponseOptionReportingInstance',
    CourseReportingPromptResponsesTable_PromptInstance: 'CourseReportingPromptResponsesTable_PromptInstance',
    CourseReportingPromptResponsesTable_PromptAttempt: 'CourseReportingPromptResponsesTable_PromptAttempt',
    CourseSharingPermissionsCourse: 'CourseSharingPermissionsCourse',
    PathSharingPermissionsPath: 'PathSharingPermissionsPath',
    LessonUtils_CourseVersion: 'LessonUtils_CourseVersion',
    PromptUtils_CourseVersion: 'PromptUtils_CourseVersion',
    ResponseOptionUtils_CourseVersion: 'ResponseOptionUtils_CourseVersion',
    ContentPermissions: 'ContentPermissions',
    LibraryItemContentPermissions: 'LibraryItemContentPermissions',
    BuilderAction: 'BuilderAction',
    ValidateModule_LibraryItem: 'ValidateModule_LibraryItem',
    ValidateModule_PathVersion: 'ValidateModule_PathVersion',
    SectionBreak: 'SectionBreak',
    PathBuilderLibraryItem: 'PathBuilderLibraryItem',
    PathBuilderContentMembership: 'PathBuilderContentMembership',
    PathBuilderPath: 'PathBuilderPath',
    PathBuilderAIGenerateModuleRequest: 'PathBuilderAIGenerateModuleRequest',
    PathBuilderCourse: 'PathBuilderCourse',
    PathBuilderPathVersion: 'PathBuilderPathVersion',
    PathBuilderSkill: 'PathBuilderSkill',
    PathBuilderFile: 'PathBuilderFile',
    RightSidebarPathSettingsContent_Path: 'RightSidebarPathSettingsContent_Path',
    CopyPathModal: 'CopyPathModal',
    PathContentLibraryItem: 'PathContentLibraryItem',
    PathContentCourse: 'PathContentCourse',
    PublishPathModal: 'PublishPathModal',
    ValidatePath_LibraryItem: 'ValidatePath_LibraryItem',
    ValidatePath_PathVersion: 'ValidatePath_PathVersion',
    PathDetailActivityFields_Path: 'PathDetailActivityFields_Path',
    ModuleManageSettingsTab_Path: 'ModuleManageSettingsTab_Path',
    ModuleReportingStepsTable_PathVersion: 'ModuleReportingStepsTable_PathVersion',
    ModuleReportingStepsTable_PCM: 'ModuleReportingStepsTable_PCM',
    ModuleStepsTable_LibraryItem: 'ModuleStepsTable_LibraryItem',
    ReportingTabTrainingResourceViews: 'ReportingTabTrainingResourceViews',
    TrainingResourcePDFContainer_TrainingResource: 'TrainingResourcePDFContainer_TrainingResource',
    MarkAssignmentCompleteAssignment: 'MarkAssignmentCompleteAssignment',
    PublishSkillModal_Skill: 'PublishSkillModal_Skill',
    ShareSkillModal_Skill: 'ShareSkillModal_Skill',
    SkillBuilder_Skill: 'SkillBuilder_Skill',
    SkillBuilder_SkillVersion: 'SkillBuilder_SkillVersion',
    SkillBuilder_VerificationStep: 'SkillBuilder_VerificationStep',
    AssignmentsTab_Assignment: 'AssignmentsTab_Assignment',
    SkillReportingOverview_SkillVersion: 'SkillReportingOverview_SkillVersion',
    SkillReportingResponsesTable_VerificationStepInstance: 'SkillReportingResponsesTable_VerificationStepInstance',
    LocationGroupLocationsTable_Location: 'LocationGroupLocationsTable_Location',
    LocationGroupsTableLocationGroup: 'LocationGroupsTableLocationGroup',
    SubRegionsList_LocationGroup: 'SubRegionsList_LocationGroup',
    MarketplacePageCatalogCategory: 'MarketplacePageCatalogCategory',
    MarketplacePageCatalogCourseMembership: 'MarketplacePageCatalogCourseMembership',
    MarketplacePageCatalogCourse: 'MarketplacePageCatalogCourse',
    MarketplacePageCatalogPath: 'MarketplacePageCatalogPath',
    MarketplacePagePremiumContentConfig: 'MarketplacePagePremiumContentConfig',
    MarketplacePageCatalogSharpExperience: 'MarketplacePageCatalogSharpExperience',
    CreatedCourse: 'CreatedCourse',
    CourseAddedToLibrary: 'CourseAddedToLibrary',
    PathAddedToLibrary: 'PathAddedToLibrary',
    ReceivedMessage: 'ReceivedMessage',
    ReceivedMessageBlast: 'ReceivedMessageBlast',
    MessageBlast: 'MessageBlast',
    SentMessage_MessageBlastMedia: 'SentMessage_MessageBlastMedia',
    DeleteLocationGroupModal_LocationGroup: 'DeleteLocationGroupModal_LocationGroup',
    DeleteRoleGroupModal_RoleGroup: 'DeleteRoleGroupModal_RoleGroup',
    LocationModal_LocationWithAddress: 'LocationModal_LocationWithAddress',
    LocationModal_Location: 'LocationModal_Location',
    RoleWithPermissions: 'RoleWithPermissions',
    ActionPermissionSet: 'ActionPermissionSet',
    RoleActionPermissions: 'RoleActionPermissions',
    RoleModalBody_Role: 'RoleModalBody_Role',
    UserActionPermissionOverrides: 'UserActionPermissionOverrides',
    AddMediaResources_TrainingResource: 'AddMediaResources_TrainingResource',
    UnsplashImage_StockPhotos: 'UnsplashImage_StockPhotos',
    PexelsPhoto_StockPhotos: 'PexelsPhoto_StockPhotos',
    PexelsVideo_StockVideos: 'PexelsVideo_StockVideos',
    CreateAICourseResult: 'CreateAICourseResult',
    CreateAIModuleResult: 'CreateAIModuleResult',
    TrainingResourceLibraryItem: 'TrainingResourceLibraryItem',
    AssignmentModalCourse: 'AssignmentModalCourse',
    AssignmentModalTrainingResource: 'AssignmentModalTrainingResource',
    AssignContentToUserModalLibraryItem: 'AssignContentToUserModalLibraryItem',
    UploadedTrainingResource: 'UploadedTrainingResource',
    OrgFormState: 'OrgFormState',
    MyTrainingPageToDoAssignment: 'MyTrainingPageToDoAssignment',
    MyTrainingPageToDoLibraryItem: 'MyTrainingPageToDoLibraryItem',
    MyTrainingPageToDoPathInstance: 'MyTrainingPageToDoPathInstance',
    MyTrainingPageToDoCourseInstance: 'MyTrainingPageToDoCourseInstance',
    MyTrainingSkillInstancePreview_VerificationStepInstance: 'MyTrainingSkillInstancePreview_VerificationStepInstance',
    MyTrainingSkillInstancePreview_SkillInstance: 'MyTrainingSkillInstancePreview_SkillInstance',
    MyTrainingSkillInstancePreview_Skill: 'MyTrainingSkillInstancePreview_Skill',
    MyTrainingSkillInstancePreview_VerificationStep: 'MyTrainingSkillInstancePreview_VerificationStep',
    MyTraining_TrainingResource: 'MyTraining_TrainingResource',
    MyTrainingPathInstance: 'MyTrainingPathInstance',
    MyTrainingPathContentMembershipInstance: 'MyTrainingPathContentMembershipInstance',
    MyTrainingCourseInstance: 'MyTrainingCourseInstance',
    MyTrainingSkillInstance: 'MyTrainingSkillInstance',
    MyTrainingSectionBreakInstance: 'MyTrainingSectionBreakInstance',
    MyTrainingSectionBreak: 'MyTrainingSectionBreak',
    MyTrainingTrainingResourceInstance: 'MyTrainingTrainingResourceInstance',
    MyTrainingTrainingResource: 'MyTrainingTrainingResource',
    CourseInstanceScreenCourseInstance: 'CourseInstanceScreenCourseInstance',
    LessonInstance: 'LessonInstance',
    PromptInstance: 'PromptInstance',
    PromptAttempt: 'PromptAttempt',
    Prompt: 'Prompt',
    PromptMedia: 'PromptMedia',
    LessonTraining: 'LessonTraining',
    ResponseOptionTraining: 'ResponseOptionTraining',
    CourseContentCourseTraining: 'CourseContentCourseTraining',
    CourseContentCourseVersionTraining: 'CourseContentCourseVersionTraining',
    MyProfilePageEmployee: 'MyProfilePageEmployee',
    OnCompletionRuleLibraryItem: 'OnCompletionRuleLibraryItem',
    CompletionRuleTreeOnCompletionRule: 'CompletionRuleTreeOnCompletionRule',
    OnCompletionPaths_OnCompletionAssignmentRuleSet: 'OnCompletionPaths_OnCompletionAssignmentRuleSet',
    OnCompletionPathsContent_LibraryItem: 'OnCompletionPathsContent_LibraryItem',
    OnCompletionPathsContent_RulesTree: 'OnCompletionPathsContent_RulesTree',
    ReportField: 'ReportField',
    ReportTopic: 'ReportTopic',
    ReportFilter: 'ReportFilter',
    Field: 'Field',
    EmailSchedule: 'EmailSchedule',
    SavedReport: 'SavedReport',
    RunReport: 'RunReport',
    LibraryItemFilter: 'LibraryItemFilter',
    ReportView: 'ReportView',
    CompletionGridLibraryItem: 'CompletionGridLibraryItem',
    CompletionGridLibraryItemWithPathMemberships: 'CompletionGridLibraryItemWithPathMemberships',
    MessageBlastHoverCard: 'MessageBlastHoverCard',
    MessageGrid__Message: 'MessageGrid__Message',
    UserCompletionGrid_Assignment: 'UserCompletionGrid_Assignment',
    UserCompletionGrid__User: 'UserCompletionGrid__User',
    PremiumPathDetailCertificatesTab_ComplianceRecord: 'PremiumPathDetailCertificatesTab_ComplianceRecord',
    PremiumPathDetailCertifricatesTab_ComplianceAttempt: 'PremiumPathDetailCertifricatesTab_ComplianceAttempt',
    ComplianceSummary: 'ComplianceSummary',
    ComplianceReport: 'ComplianceReport',
    CompletionReport_LocationGroup: 'CompletionReport_LocationGroup',
    ReportingPremiumConfig: 'ReportingPremiumConfig',
    ReportingCourseFeedbackRow: 'ReportingCourseFeedbackRow',
    LocationLeaderboardItem: 'LocationLeaderboardItem',
    ManagerLeaderboardItem: 'ManagerLeaderboardItem',
    TraineeLeaderboardItem: 'TraineeLeaderboardItem',
    DepartmentsPageContentRoleGroup: 'DepartmentsPageContentRoleGroup',
    RoleLevel: 'RoleLevel',
    RoleDetailDirectReport: 'RoleDetailDirectReport',
    RoleDetail: 'RoleDetail',
    RoleDetail_RoleGroup: 'RoleDetail_RoleGroup',
    RoleEmployee: 'RoleEmployee',
    RoleAutomation: 'RoleAutomation',
    RoleDetailUserSelection: 'RoleDetailUserSelection',
    RoleDetailTrainingResource: 'RoleDetailTrainingResource',
    RoleGroupRolesTable_Role: 'RoleGroupRolesTable_Role',
    SubRoleGroupsList_RoleGroup: 'SubRoleGroupsList_RoleGroup',
    CreditPurchaseOption: 'CreditPurchaseOption',
    Invoice: 'Invoice',
    BillingView_BillingAccount: 'BillingView_BillingAccount',
    EmployeeListHrisLocation: 'EmployeeListHrisLocation',
    EmployeeListHrisTeam: 'EmployeeListHrisTeam',
    HrisEmployee: 'HrisEmployee',
    HrisLocationFilterOption: 'HrisLocationFilterOption',
    HrisTeamFilterOption: 'HrisTeamFilterOption',
    HrisListHrisIntegration: 'HrisListHrisIntegration',
    HrisLocation: 'HrisLocation',
    HrisTeam: 'HrisTeam',
    HrisIntegration: 'HrisIntegration',
    HrisConfig: 'HrisConfig',
    HrisCsvUpload: 'HrisCsvUpload',
    GlossarySetOrg: 'GlossarySetOrg',
    AddSharpVersionModal_PremiumContentConfig: 'AddSharpVersionModal_PremiumContentConfig',
    SharpDetailAssignmentsTab_Assignment: 'SharpDetailAssignmentsTab_Assignment',
    SharpDetailAssignmentsTab_ComplianceAttempt: 'SharpDetailAssignmentsTab_ComplianceAttempt',
    IsSharpSetupComplete: 'IsSharpSetupComplete',
    TraineeSignupCode: 'TraineeSignupCode',
    TagDropdownContentTag: 'TagDropdownContentTag',
    TagsPageContentTag: 'TagsPageContentTag',
    UserSelectInputUser: 'UserSelectInputUser',
    JumpToOrgPremiumContentConfig: 'JumpToOrgPremiumContentConfig',
    JumpToEmployee: 'JumpToEmployee',
    JumpToCourse: 'JumpToCourse',
    JumpToPath: 'JumpToPath',
    JumpToTrainingResource: 'JumpToTrainingResource',
    JumpToSkill: 'JumpToSkill',
    JumpToLocation: 'JumpToLocation',
    JumpToRole: 'JumpToRole',
    JumpToLocationGroup: 'JumpToLocationGroup',
    JumpToRoleGroup: 'JumpToRoleGroup',
    MinimalEmployee: 'MinimalEmployee',
    UserDetailAccessAssignmentsAssignment: 'UserDetailAccessAssignmentsAssignment',
    UserDetailAssignmentsAssignment: 'UserDetailAssignmentsAssignment',
    UserDetailAssignmentsPathInstance: 'UserDetailAssignmentsPathInstance',
    UserDetailAssignmentsCourseInstance: 'UserDetailAssignmentsCourseInstance',
    UserDetailComplianceCertificatesTabComplianceRecord: 'UserDetailComplianceCertificatesTabComplianceRecord',
    UserDetailComplianceCertificatesTabComplianceAttempt: 'UserDetailComplianceCertificatesTabComplianceAttempt',
    LocationGroup: 'LocationGroup',
    TrainingSession: 'TrainingSession',
    UserDetailProfileTabEmployee: 'UserDetailProfileTabEmployee',
    UserDetailProfileTabAssignment: 'UserDetailProfileTabAssignment',
    UserDetailVerificationsTabAssignment: 'UserDetailVerificationsTabAssignment',
    UserDetailsAdminEmployee: 'UserDetailsAdminEmployee',
    UserPageContentEmployee: 'UserPageContentEmployee',
    UserPageHeaderEmployee: 'UserPageHeaderEmployee',
    UserActionPermissionValue: 'UserActionPermissionValue',
    UserActionPermissions: 'UserActionPermissions',
    Address: 'Address',
    LocationDetail: 'LocationDetail',
    LocationDetail_LocationGroup: 'LocationDetail_LocationGroup',
    LocationEmployee: 'LocationEmployee',
    AutomationLoc: 'AutomationLoc',
    LocationDetailUserSelection: 'LocationDetailUserSelection',
    LocationDetailTrainingResource: 'LocationDetailTrainingResource',
    LocationGroupDetail: 'LocationGroupDetail',
    AllNestedParents_LocationGroup: 'AllNestedParents_LocationGroup',
    LocationGroupEmployee: 'LocationGroupEmployee',
    AutomationLocGroup: 'AutomationLocGroup',
    LocationGroupDetailTrainingResource: 'LocationGroupDetailTrainingResource',
    LocationGroupUserSelection: 'LocationGroupUserSelection',
    OrgSharpConfig: 'OrgSharpConfig',
    RoleGroupDetail: 'RoleGroupDetail',
    AllNestedParents_RoleGroup: 'AllNestedParents_RoleGroup',
    RoleGroupEmployee: 'RoleGroupEmployee',
    AutomationRoleGroup: 'AutomationRoleGroup',
    RoleGroupDetailTrainingResource: 'RoleGroupDetailTrainingResource',
    Tag: 'Tag',
    TranslationSet: 'TranslationSet',
    MyTranslationSet: 'MyTranslationSet',
    CoverImage: 'CoverImage',
    ImageUrls: 'ImageUrls',
    Media: 'Media',
    EnglishOnlyMedia: 'EnglishOnlyMedia',
    EnglishOnlyTranslationSet: 'EnglishOnlyTranslationSet',
    TranslationOverride: 'TranslationOverride',
    CourseTopic: 'CourseTopic',
    CourseThin: 'CourseThin',
    CourseThinNoJoins: 'CourseThinNoJoins',
    Lesson: 'Lesson',
    Course: 'Course',
    CourseInfo: 'CourseInfo',
    CourseInstance: 'CourseInstance',
    EmployeeListItem: 'EmployeeListItem',
    LocationGroupMember: 'LocationGroupMember',
    EmployeeThin: 'EmployeeThin',
    UserSelection: 'UserSelection',
    BlastSentRecord: 'BlastSentRecord',
    MessageBlastThin: 'MessageBlastThin',
    CourseInstanceComplete: 'CourseInstanceComplete',
    EmployeeEslReporting: 'EmployeeEslReporting',
    UnsplashImage: 'UnsplashImage',
    Automation: 'Automation',
    Path: 'Path',
    SkillThin: 'SkillThin',
    SkillThinnest: 'SkillThinnest',
    Skill: 'Skill',
    TrainingResource: 'TrainingResource',
    PathInstanceThin: 'PathInstanceThin',
    TrainingResourceInstance: 'TrainingResourceInstance',
    Notification: 'Notification',
    ContentTagMembership: 'ContentTagMembership',
    LocationMember: 'LocationMember',
    RoleMember: 'RoleMember',
    AutomationLibraryItem: 'AutomationLibraryItem',
    EmployeeShared: 'EmployeeShared',
    UserSharingPermission: 'UserSharingPermission',
    ContentSharingPermission: 'ContentSharingPermission',
    AiGenerateModuleRequest: 'AiGenerateModuleRequest',
    ContentQRCode: 'ContentQRCode',
    PathAssignmentTable_Assignment: 'PathAssignmentTable_Assignment',
    Plan: 'Plan',
    LocationGroupObjectFragment: 'LocationGroupObjectFragment',
    AIOrgContext: 'AIOrgContext',
    BrandDetails: 'BrandDetails',
    LocationGroupThin: 'LocationGroupThin',
    LocationThin: 'LocationThin',
    RoleGroupThin: 'RoleGroupThin',
    RoleThin: 'RoleThin',
    OrgPremiumContentConfigStatus: 'OrgPremiumContentConfigStatus'
  }
}