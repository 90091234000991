import { useMemo } from "react";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { useBrandColorQuery } from "./useBrandColor.generated";
import { gql } from "@apollo/client";

const useBrandColor = (): string => {
  const { data } = useBrandColorQuery({
    fetchPolicy: "cache-first",
  });
  return useMemo(() => {
    return data?.Me?.user?.org?.brandColor || deprecatedColors.primary;
  }, [data]);
};

gql`
  query BrandColor {
    Me {
      user {
        id
        org {
          id
          brandColor
        }
      }
    }
  }
`;

export default useBrandColor;
